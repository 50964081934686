<div
  class="rounded-lg bg-white px-4 py-3 text-left shadow-xl transition-all focus:outline-none sm:my-2 sm:w-full sm:max-w-lg sm:p-4"
>
  <div
    class="text-base font-normal leading-tight text-gray-900"
    id="modal-title"
  >
    {{ data.title }}
  </div>
  <div class="py-2 text-sm text-gray-500">
    {{ data.message }}
  </div>
  <div>
    <div class="sm:mt-2 sm:flex sm:flex-row-reverse">
      <button
        type="button"
        (click)="onClickOk($event)"
        class="inline-flex w-full justify-center rounded-md bg-red-400 px-7 py-2 text-sm font-normal text-white shadow-sm outline-red-200 ring-1 ring-red-200 hover:bg-red-500 focus:outline-none sm:ml-3 sm:w-auto"
      >
        OK
      </button>
      <button
        type="button"
        (click)="onClickCancel($event)"
        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-gray-200 hover:bg-gray-50 sm:mt-0 sm:w-auto"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
