<li class="border-b py-1.5 px-4 text-lg">
  <div class="flex w-full items-center justify-between py-4 text-left text-sm">
    <span class="overflow-ellipsis whitespace-nowrap">
      {{ item.publish_date | date: "MMM d, y" }}
    </span>
    <span class="flex w-4/5 items-center text-sm font-normal text-gray-500">
      <span class="flex w-full flex-col">
        <span class="flex items-center">
          <span
            class="w-8 shrink-0 py-0.5 text-center text-xs italic tracking-wide text-white antialiased shadow-md sm:w-10 sm:py-0 sm:text-sm"
            [style.backgroundColor]="setDocTypeColor(item.doc_type)"
          >
            {{ item.doc_type }}
          </span>
          <div class="flex items-center px-2">
            <a
              *ngIf="item.content_type === 'web' && item.doc_type !== 'EV'"
              routerLink="/{{ item.doc_type | lowercase }}/{{ item.doc_no }}"
              class="text-md font-medium text-blue-500 hover:text-bluemine-500 md:text-lg"
              [target]="isMobile ? '_self' : '_blank'"
              rel="noopener noreferrer"
            >
              {{ item.doc_type }}-{{ item.doc_no }}-{{ item.revision }}
            </a>
            <a
              *ngIf="item.content_type === 'web' && item.doc_type === 'EV'"
              routerLink="/{{ item.doc_type | lowercase }}/{{ item.doc_no }}"
              class="text-md font-medium text-blue-500 hover:text-bluemine-500 md:text-lg"
              [target]="isMobile ? '_self' : '_blank'"
              rel="noopener noreferrer"
            >
              {{ item.doc_type }}-{{ item.doc_no }}{{ item.revision }}
            </a>
            <a
              *ngIf="item.content_type === 'pdf' && item.doc_type !== 'EV'"
              href="/{{ item.locale }}/pdfs/{{ item.doc_type | lowercase }}/{{
                item.doc_no
              }}/{{ item.doc_type }}-{{ item.doc_no }}.pdf"
              class="text-md font-medium text-blue-500 hover:text-bluemine-500 md:text-lg"
              target="_blank"
              rel="noopener noreferrer"
              (click)="
                sendPageView(
                  item.locale,
                  item.doc_type,
                  item.doc_no,
                  item.pdf_path
                )
              "
            >
              {{ item.doc_type }}-{{ item.doc_no }}-{{ item.revision }}
            </a>
            <a
              *ngIf="item.content_type === 'pdf' && item.doc_type === 'EV'"
              href="/{{ item.locale }}/pdfs/{{ item.doc_type | lowercase }}/{{
                item.doc_no
              }}/{{ item.doc_type }}-{{ item.doc_no }}.pdf"
              class="text-md font-medium text-blue-500 hover:text-bluemine-500 md:text-lg"
              target="_blank"
              rel="noopener noreferrer"
              (click)="
                sendPageView(
                  item.locale,
                  item.doc_type,
                  item.doc_no,
                  item.pdf_path
                )
              "
            >
              {{ item.doc_type }}-{{ item.doc_no }}{{ item.revision }}
            </a>
          </div>
        </span>

        <span [ngSwitch]="item.doc_type">
          <ng-template [ngSwitchCase]="'DM'">
            Dealer's Manual (DM) was added/updated.
          </ng-template>
          <ng-template [ngSwitchCase]="'UM'">
            User's Manual (UM) was added/updated.
          </ng-template>
          <ng-template [ngSwitchCase]="'EV'">
            Exploded View (EV) was added/updated.
          </ng-template>
          <ng-template [ngSwitchCase]="'SI'">
            Service Instructions (SI) was added/updated.
          </ng-template>
          <ng-template [ngSwitchCase]="'SM'">
            Service Manual (SI) was added/updated.
          </ng-template>
          <ng-template [ngSwitchCase]="'QM'">
            Quick Manual (SI) was added/updated.
          </ng-template>
        </span>

        <span class="w-full">{{ item.model }}</span>
      </span>
    </span>
  </div>
</li>
