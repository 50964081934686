import { Component, OnInit, Input } from '@angular/core';

export interface TreeNode {
  name: string;
  children?: TreeNode[];
}

@Component({
  selector: 'app-manual-info-model',
  templateUrl: './manual-info-model.component.html',
  styleUrls: ['./manual-info-model.component.scss'],
})
export class ManualInfoModelComponent implements OnInit {
  @Input() data: any;

  public models: TreeNode[] = [];

  public modelsOfXTR: TreeNode[] = [
    {
      name: 'XTR',
      children: [
        { name: 'BR-M9100' },
        { name: 'BR-M9110' },
        { name: 'BR-M9120' },
        { name: 'BL-M9100' },
        { name: 'BL-M9120' },
        { name: 'RT-MT900' },
      ],
    },
  ];

  public modelsOfXT: TreeNode[] = [
    {
      name: 'DEORE XT',
      children: [
        { name: 'BR-M8100' },
        { name: 'BR-M8110' },
        { name: 'BR-M8120' },
        { name: 'BL-M8100' },
        { name: 'BL-T8100' },
        { name: 'RT-MT800' },
      ],
    },
  ];

  public modelsOfSLX: TreeNode[] = [
    {
      name: 'SLX',
      children: [
        { name: 'BR-M7100' },
        { name: 'BR-M7110' },
        { name: 'BR-M7120' },
        { name: 'BL-M7100' },
      ],
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.data.models.forEach((element: any) => {
      let model: any;
      model = {
        name: element.series,
        children: [],
      };
      element.models.forEach((modelname: any) => {
        model.children.push({
          name: modelname,
        });
      });
      this.models.push(model);
    });
  }
}
