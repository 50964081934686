import { Injectable, LOCALE_ID, Injector } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedirectResolver  {
  public redirectMap: Array<{ source: string; target: string }> = [
    {
      source: '7J4MA/customization',
      target: '7J4MA/CUSTOMIZATION',
    },
  ];

  /**
   * リダイレクト　リゾルバ
   * @param route ActivateRouteSnapshot
   * @param state RouterStateSnapshot
   * @returns boolean
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (state.root.fragment !== null) {
      const webMatchStrings = state.root.fragment
        .replace(/\/$/, '')
        .match(/^\/([a-zA-Z\-]+)\/(iDM|iUM|iEV|iER)\/(.*)/);

      const pdfMatchStrings = state.root.fragment
        .replace(/\/$/, '')
        .match(/^\/([a-zA-Z\-]+)\/(DM|UM|SI|QM|SM|EV)\/(.*)/);

      const otherMatchStrings = state.root.fragment
        .replace(/\/$/, '')
        .match(/^\/([a-zA-Z\-]+)\/(information)\/(.*)/);

      if (!webMatchStrings) {
      } else {
        const matchItem = this.redirectMap.find(
          (item) => item.source === webMatchStrings[3]
        );

        if (webMatchStrings[1] === 'zh-cn') {
          webMatchStrings[1] = 'zh-CN';
        } else if (webMatchStrings[1] === 'zh-tw') {
          webMatchStrings[1] = 'zh-TW';
        }

        switch (webMatchStrings[2]) {
          case 'iDM':
            if (matchItem) {
              window.location.href = `https://${window.location.host}/${webMatchStrings[1]}/dm/${matchItem.target}`;
            } else {
              window.location.href = `https://${window.location.host}/${webMatchStrings[1]}/dm/${webMatchStrings[3]}`;
            }
            break;
          case 'iUM':
            if (matchItem) {
              window.location.href = `https://${window.location.host}/${webMatchStrings[1]}/um/${matchItem.target}`;
            } else {
              window.location.href = `https://${window.location.host}/${webMatchStrings[1]}/um/${webMatchStrings[3]}`;
            }
            break;
          case 'iEV':
            if (matchItem) {
              window.location.href = `https://${window.location.host}/${webMatchStrings[1]}/ev/${matchItem.target}`;
            } else {
              window.location.href = `https://${window.location.host}/${webMatchStrings[1]}/ev/${webMatchStrings[3]}`;
            }
            break;
          case 'iER':
            window.location.href = `https://${window.location.host}/${webMatchStrings[1]}/error`;
            break;
        }
      }

      if (!pdfMatchStrings) {
      } else {
        if (pdfMatchStrings[1] === 'zh-cn') {
          pdfMatchStrings[1] = 'zh-CN';
        } else if (pdfMatchStrings[1] === 'zh-tw') {
          pdfMatchStrings[1] = 'zh-TW';
        }

        switch (pdfMatchStrings[2]) {
          case 'DM':
            window.location.href = `https://${window.location.host}/${pdfMatchStrings[1]}/pdfs/dm/${pdfMatchStrings[3]}/DM-${pdfMatchStrings[3]}.pdf`;
            break;
          case 'UM':
            if (pdfMatchStrings[3] === '1VP0A') {
              pdfMatchStrings[3] = '1VP0B';
            }
            window.location.href = `https://${window.location.host}/${pdfMatchStrings[1]}/pdfs/um/${pdfMatchStrings[3]}/UM-${pdfMatchStrings[3]}.pdf`;
            break;
          case 'SI':
            window.location.href = `https://${window.location.host}/${pdfMatchStrings[1]}/pdfs/si/${pdfMatchStrings[3]}/SI-${pdfMatchStrings[3]}.pdf`;
            break;
          case 'QM':
            window.location.href = `https://${window.location.host}/${pdfMatchStrings[1]}/pdfs/qm/${pdfMatchStrings[3]}/QM-${pdfMatchStrings[3]}.pdf`;
            break;
          case 'SM':
            window.location.href = `https://${window.location.host}/${pdfMatchStrings[1]}/pdfs/sm/${pdfMatchStrings[3]}/SM-${pdfMatchStrings[3]}.pdf`;
            break;
          case 'EV':
            window.location.href = `https://${window.location.host}/${pdfMatchStrings[1]}/pdfs/ev/${pdfMatchStrings[3]}/EV-${pdfMatchStrings[3]}.pdf`;
            break;
        }
      }

      if (!otherMatchStrings) {
      } else {
        switch (otherMatchStrings[2]) {
          case 'information':
            if (otherMatchStrings[1] === 'zh-cn') {
              otherMatchStrings[1] = 'zh-CN';
            }
            if (otherMatchStrings[3] === 'rohs') {
              otherMatchStrings[2] = 'compliance';
            }

            window.location.href = `https://${window.location.host}/${otherMatchStrings[1]}/${otherMatchStrings[2]}/${otherMatchStrings[3]}`;
            break;
        }
      }
    }
    return of(true);
  }
}
