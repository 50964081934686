import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { ApiResponse } from '../shared/apiResponse.interface';

export interface RequestTag {
  name: string;
  category: string;
}

export interface RequestTags {
  names: Array<string>;
  category: string;
  faset_size: number;
  faset_from: number;
}

export interface ResultSet {
  hits: {
    docs: Hit[];
    total: number;
  };
  aggregations: {
    doc_count: number;
    category: TagSet[];
  };
}

export interface SuggestSet {
  buckets: TagSet[];
}

export interface TagSet {
  key: string;
  buckets: Tag[];
}

export interface Tag {
  key: string;
  doc_count?: number;
  icon?: string;
  bicycle_type?: string[];
  selected?: boolean;
  change?: boolean;
}

export interface Hit {
  doc_type: string;
  doc_no: string;
  revision?: string;
  content_type: string;
  publish_date: string;
  pdf_path: string;
  highlight: string;
  hilighted_doc_no: string;
  name: string;
  tags: HitTag[];
  locale?: string;
}

export interface HitTag {
  name: string;
  index: number;
  category: string;
}

export interface BikeType {
  id: string;
  name: string;
}

export interface Parameter {
  keywords: string;
  inputModel: string;
  reqTagsMap: Map<string, RequestTags>;
  docTypes: Array<string>;
  contentTypes: Array<string>;
  sortKey: string;
  sortOrder: string;
  from: number;
  size: number;
}

export const filterTagSetsByBikeType = (
  tagSets: TagSet[],
  bikeTypes: BikeType[]
): TagSet[] => {
  const filteredTagSets: TagSet[] = [];

  tagSets.forEach((tagSet) => {
    const tags = tagSet.buckets.filter((tag) => {
      return bikeTypes.some((bikeType) => {
        return tag.bicycle_type?.includes(bikeType.id);
      });
    });

    if (tags.length) {
      filteredTagSets.push({ key: tagSet.key, buckets: tags });
    }
  });

  return filteredTagSets;
};

@Injectable({
  providedIn: 'root',
})
export class ResultItemService {
  /**
   * 検索の開始位置
   */
  public from = 0;

  /**
   * １ページのサイズ (デフォルト)
   */
  public size = 10;

  /**
   * 入力されたモデル
   */
  private inputModel = '';

  /**
   * 入力された全文検索キーワード
   */
  public keywords = '';

  /**
   * 選択されたドキュメントタイプ
   */
  public selectedDocTypes: string[] = [];

  /**
   * 選択されたメディアタイプ
   */
  public selectedContentTypes: string[] = [];

  /**
   * ソート条件
   */
  public sortKey: string = '';

  /**
   * ソート条件
   */
  public sortOrder: string = '';

  /**
   * 選択されたタグ
   */
  public selectedTags: Array<RequestTag> = [];

  /**
   * 選択されたタグ RequestTagsのMap形式
   */
  private reqTagsMap: Map<string, RequestTags> = new Map();

  /**
   * 選択された自転車の種類
   */
  public bikeTypes: BikeType[] = [
    {
      id: 'ROAD',
      name: 'ROAD',
    },
  ];

  /**
   * 検索結果に含まれるタグセット
   */
  private tagSets: TagSet[] = [];

  /**
   * (Subject) 選択されたタグ
   */
  requestTagsSubject = new ReplaySubject<RequestTag[]>(3);

  /**
   * (Observable) requestTagsChanges
   */
  get requestTagsChanges(): Observable<RequestTag[]> {
    return this.requestTagsSubject.asObservable();
  }

  /**
   * (Subject) 検索結果
   */
  hitsSubject = new ReplaySubject<Hit[]>(1);

  /**
   * (Observable) hitsChanges
   */
  get hitsChanges(): Observable<Hit[]> {
    return this.hitsSubject.asObservable();
  }

  /**
   * (Subject) 検索結果
   */
  hitTotalSubject = new ReplaySubject<number>(1);

  /**
   * (Observable) hitsChanges
   */
  get hitTotalChanges(): Observable<number> {
    return this.hitTotalSubject.asObservable();
  }

  /**
   * (Subject) 自転車の種類
   */
  bikeTypesSubject = new ReplaySubject<BikeType[]>(1);

  /**
   * (Observable) bikeTypesChanges
   */
  get bikeTypesChanges(): Observable<BikeType[]> {
    return this.bikeTypesSubject.asObservable();
  }

  /**
   * (Subject) タグ
   */
  tagSetsSubject = new ReplaySubject<TagSet[]>(1);

  /**
   * (Observable) tagSetsChanges
   */
  get tagSetsChanges(): Observable<TagSet[]> {
    return this.tagSetsSubject.asObservable();
  }

  /**
   * (Subject) サジェスト・モデルタグ
   */
  modelTagSetsSubject = new ReplaySubject<Tag[]>(1);

  /**
   * (Observable) modelTagSetsChanges
   */
  get modelTagSetsChanges(): Observable<Tag[]> {
    return this.modelTagSetsSubject.asObservable();
  }

  /**
   * (Subject) ドキュメントタイプ
   */
  docTypesSubject = new ReplaySubject<string[]>(1);

  /**
   * (Observable) docTypesChanges
   */
  get docTypesChanges(): Observable<string[]> {
    return this.docTypesSubject.asObservable();
  }

  /**
   * (Subject) メディアタイプ
   */
  contentTypesSubject = new ReplaySubject<string[]>(1);

  /**
   * (Observable) docTypesChanges
   */
  get contentTypesChanges(): Observable<string[]> {
    return this.contentTypesSubject.asObservable();
  }

  /**
   * (Subject) ソート条件
   */
  sortSubject = new ReplaySubject<string>(1);

  /**
   * (Observable) sortChanges
   */
  get sortChanges(): Observable<string> {
    return this.sortSubject.asObservable();
  }

  /**
   * コンストラクタ
   * @param http HttpClient
   * @param locale string
   */
  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this._initRequestTags(['series', 'generation', 'component', 'model']);
  }

  /**
   * 検索
   */
  search(filter?: boolean): void {
    let request;

    // 選択タグとモデルをセットする (Mapオブジェクトの配列をMapオブジェクトのvalueの配列に変換する)
    const tags: Array<RequestTags> = [...this.reqTagsMap.values()];

    request = {
      data: {
        size: this.size,
        from: this.from,
        model_keyword: this.inputModel ? this.inputModel : undefined,
        keywords: this.keywords
          ? this.keywords.replace(/("[^"]+")|[\s]+/g, (m, g1) =>
              g1 ? g1 : ','
            )
          : undefined,
        tags,
        doc_types: this.selectedDocTypes.length
          ? this.selectedDocTypes
          : undefined,
        content_types: this.selectedContentTypes.length
          ? this.selectedContentTypes
          : undefined,
        sort_key: this.sortKey ? this.sortKey : undefined,
        sort_order: this.sortOrder ? this.sortOrder : undefined,
      },
    };

    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    this.http
      .post<ApiResponse<ResultSet>>(
        `${environment.apiServer.baseUrl}/docs/search` +
          (environment.apiServer.local ? '.json' : ''),
        request,
        { headers }
      )
      .subscribe((response) => {
        if (response.data.hits) {
          this.hitsSubject.next(response.data.hits.docs);
        } else {
          this.hitsSubject.next([]);
        }
        this.hitTotalSubject.next(response.data.hits.total);

        this.tagSets = [];

        // ファセットの順番を並び替える
        const tagSetOrder = ['series', 'generation', 'component', 'model'];
        tagSetOrder.forEach((category) => {
          const tagSet = response.data.aggregations.category.find(
            (item) => item.key === category
          );
          if (tagSet) this.tagSets.push(tagSet);
        });

        // 選択されているタグを選択状態にする
        this.reqTagsMap.forEach((reqTags: RequestTags, category: string) => {
          reqTags.names.forEach((tagname) => {
            const tagSet = this.tagSets.find((item) => item.key === category);
            if (tagSet) {
              const tag = tagSet.buckets.find(
                (item) => item.key === tagname
                /*
                  item.key === tagname &&
                  item.bicycle_type?.includes(this.bikeTypes[0].id)
                  */
              );
              if (tag) {
                tag.selected = true;
              }
            }
          });
        });

        this.tagSetsSubject.next(
          filter
            ? filterTagSetsByBikeType(this.tagSets, this.bikeTypes)
            : this.tagSets
        );
        this.docTypesSubject.next(this.selectedDocTypes);
        this.contentTypesSubject.next(this.selectedContentTypes);
        this.bikeTypesSubject.next(this.bikeTypes);
      });
  }

  /**
   * サジェスト
   */
  suggest(): void {
    let request;

    // 選択タグとモデルをセットする
    const tags: Array<RequestTags> = [...this.reqTagsMap.values()];

    request = {
      data: {
        keywords: this.keywords
          ? this.keywords.replace(/("[^"]+")|[\s]+/g, (m, g1) =>
              g1 ? g1 : ','
            )
          : undefined,
        model_keyword: this.inputModel,
        tags,
        doc_types: this.selectedDocTypes.length
          ? this.selectedDocTypes
          : undefined,
        content_types: this.selectedContentTypes.length
          ? this.selectedContentTypes
          : undefined,
      },
    };

    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    this.http
      .post<ApiResponse<SuggestSet>>(
        `${environment.apiServer.baseUrl}/docs/suggest` +
          (environment.apiServer.local ? '.json' : ''),
        request,
        { headers }
      )
      .subscribe((response) => {
        this.modelTagSetsSubject.next(response.data.buckets);
      });
  }

  /**
   * 次のファセットをフェッチする
   * @param category
   * @param offset
   * @returns
   */
  fetchFacet(category: string, offset: number): any {
    let request;

    const reqTags: RequestTags | undefined = this.reqTagsMap.get(category);
    if (reqTags !== undefined) reqTags.faset_from = 100 * offset;

    // 選択タグとモデルをセットする
    const tags: Array<RequestTags> = [...this.reqTagsMap.values()];

    request = {
      data: {
        size: this.size,
        from: this.from,
        model_keyword: this.inputModel ? this.inputModel : undefined,
        keywords: this.keywords
          ? this.keywords.replace(/("[^"]+")|[\s]+/g, (m, g1) =>
              g1 ? g1 : ','
            )
          : undefined,
        tags,
        doc_types: this.selectedDocTypes.length
          ? this.selectedDocTypes
          : undefined,
        content_types: this.selectedContentTypes.length
          ? this.selectedContentTypes
          : undefined,
      },
    };

    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    let tagSet;

    return this.http
      .post<ApiResponse<ResultSet>>(
        `${environment.apiServer.baseUrl}/docs/search` +
          (environment.apiServer.local ? '.json' : ''),
        request,
        { headers }
      )
      .pipe(
        map((response) => {
          // ファセットの順番を並び替える
          tagSet = response.data.aggregations.category.find(
            (item) => item.key === category
          )?.buckets;

          return tagSet;
        })
      );
  }

  /**
   *
   * @param category
   */
  _initRequestTags(categorys: Array<string>): void {
    categorys.forEach((category) => {
      const reqTags = {
        category,
        names: [],
        faset_size: 200,
        faset_from: 0,
      };

      this.reqTagsMap.set(category, reqTags);
    });
  }

  /**
   * タグマップに追加する
   * @param tag
   * @returns
   */
  _addTag(tag: RequestTag): boolean {
    let reqTags: RequestTags | undefined = this.reqTagsMap.get(tag.category);

    if (reqTags === undefined) {
      reqTags = {
        category: tag.category,
        names: [],
        faset_size: 200,
        faset_from: 0,
      };
    }

    reqTags.names.push(tag.name);
    this.reqTagsMap.set(tag.category, reqTags);

    return true;
  }

  /**
   * タグマップから取り除く
   * @param tag
   * @returns
   */
  _removeTag(tag: RequestTag): boolean {
    const reqTags: RequestTags | undefined = this.reqTagsMap.get(tag.category);

    if (reqTags === undefined) {
      return false;
    }

    const index = reqTags.names.findIndex((item: any) => item === tag.name);

    if (index > -1) {
      reqTags.names.splice(index, 1);
    }

    this.reqTagsMap.set(tag.category, reqTags);

    return true;
  }

  /**
   * タグを追加する
   */
  addTag(tag: RequestTag): Parameter {
    if (
      !this.selectedTags.find(
        (item) => item.category === tag.category && item.name === tag.name
      )
    ) {
      this.selectedTags.push({
        name: tag.name,
        category: tag.category,
      });

      this.requestTagsSubject.next(this.selectedTags);

      this._addTag(tag);
    }

    return this._getParameter();
  }

  /**
   * タグを取り除く
   * @param tag RequestTag
   * @returns params
   */
  removeTag(tag: RequestTag): Parameter {
    const index = this.selectedTags.findIndex(
      (item) => item.category === tag.category && item.name === tag.name
    );

    if (index > -1) {
      this.selectedTags.splice(index, 1);
    }

    this.requestTagsSubject.next(this.selectedTags);

    this._removeTag(tag);

    return this._getParameter();
  }

  /**
   * タグをセットする
   * @param tags Array<RequestTag>
   * @returns params
   */
  setTags(tags: Array<RequestTag>): Parameter {
    this._initRequestTags(['series', 'generation', 'component', 'model']);

    this.selectedTags = [];

    tags.forEach((tag) => {
      this.selectedTags.push({
        name: tag.name,
        category: tag.category,
      });

      this._addTag(tag);
    });

    this.requestTagsSubject.next(this.selectedTags);

    return this._getParameter();
  }

  /**
   * タグを取り除く
   * @param tag string
   * @returns params
   */
  removeTags(category: string): Parameter {
    this.selectedTags = this.selectedTags.filter(
      (item) => item.category !== category
    );

    this._initRequestTags([category]);

    this.requestTagsSubject.next(this.selectedTags);

    return this._getParameter();
  }

  /**
   * ドキュメントタイプを追加する
   */
  addDocType(docType: string): Parameter {
    this.selectedDocTypes.push(docType);

    return this._getParameter();
  }

  /**
   * ドキュメントタイプを取り除く
   * @param docType string
   * @returns params
   */
  removeDocType(docType: string): Parameter {
    const index = this.selectedDocTypes.findIndex((item) => item === docType);

    if (index > -1) {
      this.selectedDocTypes.splice(index, 1);
    }

    return this._getParameter();
  }

  /**
   * ドキュメントタイプをセットする
   * @param docTypes Array<string>
   * @returns params
   */
  setDocTypes(docTypes: Array<string>): Parameter {
    this.selectedDocTypes = docTypes;

    return this._getParameter();
  }

  /**
   * コンテンツタイプを追加する
   */
  addContentType(contentType: string): Parameter {
    this.selectedContentTypes.push(contentType);

    return this._getParameter();
  }

  /**
   * コンテンツタイプを取り除く
   * @param contentType string
   * @returns params
   */
  removeContentType(contentType: string): Parameter {
    const index = this.selectedContentTypes.findIndex(
      (item) => item === contentType
    );

    if (index > -1) {
      this.selectedContentTypes.splice(index, 1);
    }

    return this._getParameter();
  }

  /**
   * コンテンツタイプをセットする
   * @param contentTypes Array<string>
   * @returns params
   */
  setContentTypes(contentTypes: Array<string>): Parameter {
    this.selectedContentTypes = contentTypes;

    return this._getParameter();
  }

  /**
   * ソートの条件を指定する
   * @param sortKey string
   * @param sortOrder string
   * @returns params
   */
  setSort(sortKey: string, sortOrder: string): Parameter {
    this.sortKey = sortKey;
    this.sortOrder = sortOrder;

    return this._getParameter();
  }

  /**
   * キーワードをセットする
   * @param keywords string
   * @returns params
   */
  setKeywords(keywords: string): Parameter {
    this.keywords = keywords;

    return this._getParameter();
  }

  /**
   * モデルをセットする
   * @param model string
   * @returns params
   */
  setModel(model: string): Parameter {
    if (
      !this.selectedTags.find(
        (item) => item.category === 'model' && item.name === model
      )
    ) {
      this.inputModel = model;
    }

    return this._getParameter();
  }

  _getParameter(): Parameter {
    return {
      keywords: this.keywords,
      reqTagsMap: this.reqTagsMap,
      docTypes: this.selectedDocTypes,
      contentTypes: this.selectedContentTypes,
      inputModel: this.inputModel,
      sortKey: this.sortKey,
      sortOrder: this.sortOrder,
      from: this.from,
      size: this.size,
    };
  }

  /**
   * パラメータを返す
   * @returns params
   */
  getParams(): Parameter {
    return this._getParameter();
  }

  /**
   * ファセットをクリアする
   * @returns
   */
  clearFacets(): Parameter {
    this._initRequestTags(['series', 'generation', 'component', 'model']);

    this.selectedTags = [];
    this.requestTagsSubject.next([]);

    return this._getParameter();
  }

  /**
   * サジェストをクリアする
   */
  clearSuggest(): void {
    this.modelTagSetsSubject.next([]);
  }

  /**
   * 自転車の種類が追加されてタグを絞り込む
   * @param bikeType 追加された BikeType(自転車のタイプ)
   */
  setFilter(bikeType: BikeType): void {
    /*
    this.bikeTypes.push(bikeType);
    */
    this.bikeTypes = [bikeType];

    this.tagSetsSubject.next(
      filterTagSetsByBikeType(this.tagSets, this.bikeTypes)
    );

    this.bikeTypesSubject.next(this.bikeTypes);
  }

  /**
   * 自転車の種類が削除されてタグを絞り込む
   * @param bikeType 削除された BikeType(自転車のタイプ)
   */
  removeFilter(bikeType: BikeType): void {
    /*
    const index = this.bikeTypes.indexOf(bikeType);
    if (index !== -1) {
      this.bikeTypes.splice(index, 1);
    }
    */
    this.bikeTypes = [];

    if (this.bikeTypes.length) {
      this.tagSetsSubject.next(
        filterTagSetsByBikeType(this.tagSets, this.bikeTypes)
      );
    } else {
      this.tagSetsSubject.next(this.tagSets);
    }

    this.bikeTypesSubject.next(this.bikeTypes);
  }

  /**
   * 検索結果のレンジを指定する
   * @param from number
   * @param size number
   */
  setRange(from: number, size: number): void {
    this.from = from;
    this.size = size;
  }
}
