<ng-container *ngIf="_expanded">
  <!-- drag handler -->
  <span
    #dragHandleLeft
    [@sideOverAnimation]
    class="draggable absolute left-0 z-50 h-full w-4 bg-transparent"
    cdkDragLockAxis="x"
    [cdkDragDisabled]="_dragDisabled"
    cdkDrag
    (cdkDragMoved)="dragMove(dragHandleLeftElement, $event)"
  >
  </span>

  <section
    class="fixed z-40 overflow-hidden"
    aria-labelledby="slide-over"
    role="dialog"
    aria-modal="true"
  >
    <div class="absolute inset-0 overflow-hidden">
      <!-- Background overlay, show/hide based on slide-over state. -->
      <div class="absolute inset-0" aria-hidden="true"></div>

      <div
        class="fixed inset-y-0 top-12 right-0 flex max-w-full pl-10 md:top-24"
        [ngClass]="{
          'top-12': !notify.show,
          'top-24': notify.show,
          'md:top-24': !notify.show,
          'md:top-36': notify.show
        }"
      >
        <div [@sideOverAnimation] class="max-w-screen-xl">
          <div
            #resizeBox
            class="relative flex h-full flex-col overflow-y-scroll border border-gray-300 bg-white py-2 shadow-sm md:py-0"
          >
            <div
              class="fixed flex h-screen -translate-x-5 transform items-center"
            >
              <svg
                aria-hidden="true"
                class="pointer-events-none h-4 w-4 text-gray-600"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M8 5h2v14H8zM14 5h2v14h-2z"></path>
              </svg>
            </div>

            <div class="mt-2 px-3 sm:px-4 md:mt-4">
              <div class="flex items-center justify-between">
                <h2
                  class="text-base font-normal tracking-wider text-gray-900 antialiased"
                  id="slide-over-title"
                >
                  {{ title }}
                </h2>
                <div class="ml-0 flex h-7">
                  <button
                    class="rounded-none bg-white text-gray-400 hover:text-gray-500"
                    (click)="_close()"
                  >
                    <span class="sr-only">Close panel</span>
                    <app-chevron-right-solid-icon
                      svgClass="h-7 w-7"
                    ></app-chevron-right-solid-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="relative px-3 sm:px-4">
              <!-- Replace with your content -->
              <div #panelContent class="flex flex-row">
                <ng-content></ng-content>
              </div>
              <!-- /End replace -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
