import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';

// cdk
import { DialogModule } from '@angular/cdk/dialog';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ScrollingModule } from '@angular/cdk/scrolling';

// material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';

// ngx cookie
import { CookieService } from 'ngx-cookie-service';

// basic
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';

// shared
import { SharedModule } from 'shared';

import { CustomElementsModule } from './custom-elements/custom-elements.module';

// resolver
import { PageResolver } from './shared/page.resolver';

// docs, search
import { DocsComponent } from './docs/docs.component';
import { DocsCollectionComponent } from './docs/docs-collection/docs-collection.component';
import { BikeTypeFilterItemComponent } from './docs/bike-type-filter-item/bike-type-filter-item.component';
import { SearchComponent } from './search/search.component';
import { SearchResultTagsetComponent } from './search/search-result-tagset/search-result-tagset.component';
import { SearchNavMobileComponent } from './search/search-nav-mobile/search-nav-mobile.component';
import { SearchNavDesktopComponent } from './search/search-nav-desktop/search-nav-desktop.component';
import { SearchRequestTagComponent } from './search/search-request-tag/search-request-tag.component';
import { SearchResultItemComponent } from './search/search-result-item/search-result-item.component';
import { SearchResultComponent } from './search/search-result/search-result.component';
import { SearchBookmarkComponent } from './search/search-bookmark/search-bookmark.component';
import { SearchBookmarkItemComponent } from './search/search-bookmark-item/search-bookmark-item.component';
import { SearchGuideComponent } from './search/search-guide/search-guide.component';

// update information
import { UpdateInfoComponent } from './update-info/update-info.component';
import { UpdateInfoItemComponent } from './update-info/update-info-item/update-info-item.component';

// manual
import { ManualComponent } from './manual/manual.component';
import { ManualTopComponent } from './manual/manual-top/manual-top.component';
import { ManualPageComponent } from './manual/manual-page/manual-page.component';
import { ManualTocDesktopComponent } from './manual/manual-toc-desktop/manual-toc-desktop.component';
import { ManualTocMobileComponent } from './manual/manual-toc-mobile/manual-toc-mobile.component';
import { ManualTocItemComponent } from './manual/manual-toc-item/manual-toc-item.component';
import { ManualInfoComponent } from './manual/manual-info/manual-info.component';
import { ManualInfoBasicComponent } from './manual/manual-info-basic/manual-info-basic.component';
import { ManualInfoModelComponent } from './manual/manual-info-model/manual-info-model.component';
import { ManualModelItemComponent } from './manual/manual-model-item/manual-model-item.component';
import { ManualListComponent } from './manual/manual-list/manual-list.component';
import { SvgViewerComponent } from './manual/svg-viewer/svg-viewer.component';

// exploded view
import { ExplodedViewComponent } from './exploded-view/exploded-view.component';
import { BomItemComponent } from './exploded-view/bom-item/bom-item.component';
import { HotspotItemComponent } from './exploded-view/hotspot-item/hotspot-item.component';

// errorcode
import { ErrorComponent } from './error/error.component';
import { ErrorCollectionComponent } from './error/error-collection/error-collection.component';
import { ErrorItemComponent } from './error/error-item/error-item.component';

// compliance
import { ComplianceTopComponent } from './compliance/compliance-top/compliance-top.component';
import { CompliancePmiComponent } from './compliance/compliance-pmi/compliance-pmi.component';
import { ComplianceGuidComponent } from './compliance/compliance-guide/compliance-guide.component';

// common
import { TabComponent } from './shared/tab/tab.component';
import { ToolbarButtonComponent } from './shared/toolbar-button/toolbar-button.component';
import { LayoutModule } from './layout/layout.module';

// not found
import { NotFoundComponent } from './shared/not-found/not-found.component';

// pipe
import { StripHtmlPipe } from './pipe/strip-html.pipe';
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { DoctypePipe } from './pipe/doctype.pipe';

@NgModule({
  declarations: [
    AppComponent,
    DocsComponent,
    DocsCollectionComponent,
    BikeTypeFilterItemComponent,
    SearchComponent,
    SearchResultTagsetComponent,
    SearchNavMobileComponent,
    SearchNavDesktopComponent,
    SearchBookmarkComponent,
    SearchBookmarkItemComponent,
    SearchRequestTagComponent,
    SearchResultItemComponent,
    SearchGuideComponent,
    SearchResultComponent,
    UpdateInfoComponent,
    UpdateInfoItemComponent,
    ManualComponent,
    ManualTopComponent,
    ManualPageComponent,
    ManualTocDesktopComponent,
    ManualTocMobileComponent,
    ManualTocItemComponent,
    ManualInfoComponent,
    ManualInfoBasicComponent,
    ManualInfoModelComponent,
    ManualModelItemComponent,
    ManualListComponent,
    SvgViewerComponent,
    ExplodedViewComponent,
    BomItemComponent,
    HotspotItemComponent,
    ErrorComponent,
    ErrorCollectionComponent,
    ErrorItemComponent,
    ComplianceTopComponent,
    TabComponent,
    ToolbarButtonComponent,
    NotFoundComponent,
    StripHtmlPipe,
    SanitizeHtmlPipe,
    DoctypePipe,
    CompliancePmiComponent,
    ComplianceGuidComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DialogModule,
    CdkStepperModule,
    CdkTreeModule,
    ScrollingModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatPaginatorModule,
    MatTooltipModule,
    AppRoutingModule,
    CustomElementsModule,
    LayoutModule,
    SharedModule,
    AmplifyAuthenticatorModule,
  ],
  providers: [...environment.providers, CookieService, PageResolver],
  bootstrap: [AppComponent],
})
export class AppModule {}
