import { Component, Input } from '@angular/core';

import { MenuNode } from '../menu-node.interface';

@Component({
  selector: 'lib-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent {
  /**
   * メニューアイテム
   */
  @Input() node!: MenuNode;

  /**
   * メニューの開閉状態
   */
  public isOpen = false;

  /**
   * メニューを開く
   */
  onClickOpen(): void {
    this.isOpen = true;
  }

  /**
   * メニューを閉じる
   */
  onClickClose(): void {
    this.isOpen = false;
  }
}
