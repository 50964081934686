<!--
    Mobile menu
    Off-canvas menu for mobile, show/hide based on off-canvas menu state.
    -->
<div
  *ngIf="_expanded"
  class="fixed inset-0 z-30 flex xl:hidden"
  role="dialog"
  aria-modal="true"
>
  <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.
        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
        -->
  <div
    [@openAnimation]
    class="fixed inset-0 bg-black bg-opacity-25 transition-opacity ease-linear"
    aria-hidden="true"
  ></div>
  <!--
        Off-canvas menu, show/hide based on off-canvas menu state.
  
        Entering: "transition ease-in-out duration-300 transform"
        From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
        -->
  <div
    [@sideOverAnimation]
    class="relative flex w-full max-w-md flex-col overflow-y-hidden overscroll-none bg-white pb-4 shadow-xl transition ease-in-out"
  >
    <div class="flex px-2 pt-2 pb-0">
      <button
        (click)="onClick()"
        type="button"
        class="-m-0 inline-flex items-center justify-center rounded-md p-0 text-gray-400"
      >
        <span class="sr-only">Close menu</span>
        <app-chevron-left-solid-icon
          svgClass="h-7 w-7"
        ></app-chevron-left-solid-icon>
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</div>
