import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { TocItem, PageTocService } from '../../manual/page-toc.service';

@Component({
  selector: 'app-toc',
  templateUrl: './toc.component.html',
  styleUrls: ['./toc.component.scss'],
})
export class TocComponent {
  public tocList$!: Observable<TocItem[]>;

  /**
   * Constractor
   * @param pageTocService ページ目次サービス
   */
  constructor(private router: Router, private pageTocService: PageTocService) {
    this.tocList$ = this.pageTocService.valueChanges;
  }

  /**
   * ページ目次のクリックで指定したid属性までスクロール遷移させる
   * @param id スクロール遷移先のid属性
   */
  public onClickPageToc(fragment: string): void {
    const urlTree = this.router.parseUrl(this.router.url);
    const currentQueryParams = urlTree.queryParams;
    urlTree.queryParams = {};
    urlTree.fragment = null;

    this.router.navigate([urlTree.toString()], {
      queryParams: currentQueryParams,
      fragment: fragment,
    });
  }
}
