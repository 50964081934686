import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NavigationNode, ManualTocService } from '../manual-toc.service';
import { Observable } from 'rxjs';

import { Notify } from '../../shared/notify.service';

export interface Manual {
  docType: string;
  docId: string;
}

@Component({
  selector: 'app-manual-toc-desktop',
  templateUrl: './manual-toc-desktop.component.html',
  styleUrls: ['./manual-toc-desktop.component.scss'],
})
export class ManualTocDesktopComponent implements OnInit, AfterViewInit {
  /**
   * HostBinding
   */
  @HostBinding('class') classAttribute: string =
    'hidden md:flex md:flex-shrink-0 md:w-1/4 lg:w-1/5 xl:w-64 sticky h-screen';

  @HostBinding('class.top-36')
  get top36() {
    return !this._notify.show;
  }

  @HostBinding('class.top-44')
  get top44() {
    return this._notify.show;
  }

  /**
   * 現在のレベル
   */
  @Input()
  manual!: Manual;

  /**
   * 現在のレベル
   */
  @Input() pageId!: string;

  /**
   * フィルタキーワード
   */
  public filter!: string | undefined;

  /**
   * フィルタ要素に対する参照 (ViewChild)
   */
  @ViewChild('filterRef') filterRef!: ElementRef;

  /**
   * ドキュメントの目次
   */
  public nodes$?: Observable<NavigationNode[]>;

  /**
   * メニューが開いている状態であるか
   */
  @Input() set notify(value: Notify) {
    this._notify = value;
  }

  /**
   * 通知領域の開閉状態を保持する
   */
  public _notify = { show: false, message: '' };

  /**
   * Getter notify
   */
  get notify(): Notify {
    return this._notify;
  }

  /**
   * コンストラクタ
   * @param manualTocService ManualTocService
   */
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private manualTocService: ManualTocService
  ) {
    activateRoute.queryParams.subscribe((val) => {
      if (this.activateRoute.snapshot.queryParams.q) {
        this.filter = this.activateRoute.snapshot.queryParams.q;
        if (this.filter)
          this.filterRef.nativeElement.value = this.filter.replace(
            /("[^"]+")|[,]+/g,
            (m: any, g1: any) => (g1 ? g1 : ' ')
          );
      } else if (this.activateRoute.snapshot.firstChild?.queryParams.q) {
        this.filter = this.activateRoute.snapshot.firstChild?.queryParams.q;
        if (this.filter)
          this.filterRef.nativeElement.value = this.filter.replace(
            /("[^"]+")|[,]+/g,
            (m: any, g1: any) => (g1 ? g1 : ' ')
          );
      }
    });
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
    this.nodes$ = this.manualTocService.docTocChanges;
  }

  /**
   * ngAfterViewChecked
   */
  ngAfterViewInit(): void {
    if (this.filter) {
      this.filterRef.nativeElement.value = this.filter.replace(
        /("[^"]+")|[,]+/g,
        (m: any, g1: any) => (g1 ? g1 : ' ')
      );
    }
  }

  /**
   * フィルタの入力で目次を絞り込む
   * @param event イベント
   */
  onClickFilter(event: any): void {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};
    urlTree.fragment = null;

    if (event.target.value) {
      this.filter = event.target.value
        .trim()
        .replace(/("[^"]+")|[\s]+/g, (m: any, g1: any) => (g1 ? g1 : ','));
    } else {
      this.filter = undefined;
    }

    this.router.navigate([urlTree.toString()], {
      queryParams: { q: this.filter },
    });
  }
}
