<li
  class="relative flex items-stretch hover:bg-bluemine-500 focus:outline-none focus:ring-0"
  (mouseenter)="onClickOpen()"
  (mouseleave)="onClickClose()"
>
  <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" -->
  <a
    class="group items-center rounded-md py-3.5 px-4 align-middle text-sm font-normal uppercase tracking-wider text-white lg:px-6"
    href="{{ node.url }}"
    target="{{ node.target }}"
    [attr.aria-expanded]="isOpen"
  >
    {{ node.label }}
  </a>

  <lib-nav-popover
    *ngIf="node.children !== undefined"
    [expanded]="isOpen"
    [nodes]="node.children"
    [parent]="node"
  ></lib-nav-popover>
</li>
