<div
  class="fixed z-50 m-2 border bg-white shadow-sm sm:rounded-sm"
  [style.top.px]="top - 20"
  [style.left.px]="left + 15"
>
  <div class="max-w-2xl px-4 py-5 tooltip-arrow-left sm:p-6">
    <p class="text-center text-sm" i18n>
      The following is an explanation of the terms displayed on the website.
    </p>

    <div
      class="grid grid-flow-row grid-cols-2 gap-0 py-2 text-sm sm:grid-cols-2 md:grid-cols-3"
    >
      <div class="flex items-center">
        <i
          class="inline-block w-10 flex-shrink-0 border text-center text-white"
          style="background-color: #bd362f"
          >DM</i
        >
        <span class="break-words pl-2 sm:pl-4" i18n>Dealer's Manual</span>
      </div>
      <div class="flex items-center">
        <i
          class="inline-block w-10 flex-shrink-0 border text-center text-white"
          style="background-color: #51a351"
          >UM</i
        >
        <span class="break-words pl-2 sm:pl-4" i18n>User's Manual</span>
      </div>
      <div class="flex items-center">
        <i
          class="inline-block w-10 flex-shrink-0 border text-center text-white"
          style="background-color: #005686"
          >SI</i
        ><span class="break-words pl-2 sm:pl-4" i18n>Service Instructions</span>
      </div>
      <div class="flex items-center">
        <i
          class="inline-block w-10 flex-shrink-0 border text-center text-white"
          style="background-color: #fd8c34"
          >EV</i
        ><span class="break-words pl-2 sm:pl-4" i18n>Exploded View</span>
      </div>
      <div class="flex items-center">
        <i
          class="inline-block w-10 flex-shrink-0 border text-center text-white"
          style="background-color: #4aacc5"
          >QM</i
        >
        <span class="break-words pl-2 sm:pl-4" i18n>Quick Manual</span>
      </div>
      <div class="flex items-center">
        <i
          class="inline-block w-10 flex-shrink-0 border text-center text-white"
          style="background-color: #e6b8b7"
          >SM</i
        ><span class="break-words pl-2 sm:pl-4" i18n>Service Manual</span>
      </div>
    </div>
  </div>
</div>
