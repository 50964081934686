import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';

import { RequestTags, ResultItemService, TagSet } from '../result-item.service';
import { SearchScreenStatusService } from '../search-screen-status.service';

@Component({
  selector: 'app-search-nav-desktop',
  templateUrl: './search-nav-desktop.component.html',
  styleUrls: ['./search-nav-desktop.component.scss'],
  host: {
    class: 'hidden xl:flex xl:flex-shrink-0 xl:w-96 sticky top-24 h-screen',
  },
})
export class SearchNavDesktopComponent implements AfterViewInit {
  /**
   * ドキュメントタイプ
   */
  public docTypes$?: Observable<Array<string>>;

  public docTypes: { name: string; checked: boolean }[] = [
    { name: 'DM', checked: false },
    { name: 'UM', checked: false },
    { name: 'SI', checked: false },
    { name: 'EV', checked: false },
    { name: 'QM', checked: false },
    { name: 'SM', checked: false },
  ];

  /**
   * コンテンツタイプ
   */
  public contentTypes$?: Observable<Array<string>>;

  public contentTypes: { name: string; checked: boolean }[] = [
    { name: 'web', checked: false },
    { name: 'pdf', checked: false },
  ];

  /**
   * ファセット
   */
  public TagSets$?: Observable<Array<TagSet>>;

  /**
   * 選択タグ・オブザーバブル
   */
  public activeTags$: Observable<any>;

  /**
   * アコーディオンの開閉状態
   */
  public isOpen = true;

  /**
   * アコーディオンの開閉状態
   */
  public isContentTypeOpen = true;

  public expandedState$ =
    this.searchScreenStatusService.expandedState$.asObservable();

  /**
   * 凡例の表示状態
   */
  public showGuide = false;

  /**
   * ガイドエリア
   */
  public guideRect: any = {};

  /**
   * 凡例要素に対する参照 (ViewChild)
   */
  @ViewChild('guideRef') guideRef!: ElementRef;

  /**
   * マークのクリックを伝える
   */
  @Output() clickGuide: EventEmitter<boolean> = new EventEmitter();

  /**
   * コンストラクタ
   * @param resultItemService ResultItemService
   */
  constructor(
    private router: Router,
    private resultItemService: ResultItemService,
    private searchScreenStatusService: SearchScreenStatusService
  ) {
    this.TagSets$ = this.resultItemService.tagSetsChanges;
    this.activeTags$ = this.resultItemService.requestTagsChanges;
    this.resultItemService.docTypesChanges.subscribe((seledctedDocTypes) => {
      if (seledctedDocTypes.length) {
        this.docTypes.forEach((docType) => {
          if (seledctedDocTypes.includes(docType.name)) {
            docType.checked = true;
          } else {
            docType.checked = false;
          }
        });
      }
    });
    this.resultItemService.contentTypesChanges.subscribe(
      (seledctedContentTypes) => {
        if (seledctedContentTypes.length) {
          this.contentTypes.forEach((contentType) => {
            if (seledctedContentTypes.includes(contentType.name)) {
              contentType.checked = true;
            } else {
              contentType.checked = false;
            }
          });
        }
      }
    );
  }

  /**
   * (AfterViewInit)
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.guideRect = this.guideRef.nativeElement.getBoundingClientRect();
    }, 300);
  }

  /**
   * スクロール時の処理
   */
  onScroll(): void {
    this.clickGuide.emit(false);
    this.guideRect = this.guideRef.nativeElement.getBoundingClientRect();
  }

  /**
   * ファセット押下時に選択状態を保存しナビゲートする
   */
  onClick(event: any, docType: string): void {
    let params;
    let queryParams: any = {};

    params = event.target.checked
      ? this.resultItemService.addDocType(docType)
      : this.resultItemService.removeDocType(docType);

    queryParams.doctype = params.docTypes.length
      ? params.docTypes.join(',')
      : undefined;

    queryParams.contenttype = params.contentTypes.length
      ? params.contentTypes.join(',')
      : undefined;

    params.reqTagsMap.forEach((reqTags: RequestTags, category: string) => {
      if (reqTags.names?.length) {
        queryParams = {
          ...queryParams,
          [category]: reqTags.names.join(','),
        };
      }
    });

    queryParams.input_model = params.inputModel ? params.inputModel : undefined;

    queryParams.q = params.keywords
      ? params.keywords.replace(/("[^"]+")|[\s]+/g, (m, g1) => (g1 ? g1 : ','))
      : undefined;

    queryParams.sort =
      params.sortKey && params.sortOrder
        ? `${params.sortKey},${params.sortOrder}`
        : undefined;

    this.router.navigate(['manual/search'], {
      queryParams,
    });
  }

  /**
   * ファセット押下時に選択状態を保存しナビゲートする
   */
  onClickContentType(event: any, contentType: string): void {
    let params;
    let queryParams: any = {};

    params = event.target.checked
      ? this.resultItemService.addContentType(contentType)
      : this.resultItemService.removeContentType(contentType);

    queryParams.doctype = params.docTypes.length
      ? params.docTypes.join(',')
      : undefined;

    queryParams.contenttype = params.contentTypes.length
      ? params.contentTypes.join(',')
      : undefined;

    params.reqTagsMap.forEach((reqTags: RequestTags, category: string) => {
      if (reqTags.names?.length) {
        queryParams = {
          ...queryParams,
          [category]: reqTags.names.join(','),
        };
      }
    });

    queryParams.input_model = params.inputModel ? params.inputModel : undefined;

    queryParams.q = params.keywords
      ? params.keywords.replace(/("[^"]+")|[\s]+/g, (m, g1) => (g1 ? g1 : ','))
      : undefined;

    queryParams.sort =
      params.sortKey && params.sortOrder
        ? `${params.sortKey},${params.sortOrder}`
        : undefined;

    this.router.navigate(['manual/search'], {
      queryParams,
    });
  }

  /**
   * タグを全てクリアする
   */
  onClickAllClear(): void {
    this.resultItemService.clearFacets();

    this.router.navigate(['manual/search'], {});
  }

  /**
   * ドキュメントタイプのアコーディオンの開閉
   */
  onClickAccordion(): void {
    this.isOpen = !this.isOpen;
  }

  /**
   * コンテンツタイプのアコーディオンの開閉
   */
  onClickContentTypeAccordion(): void {
    this.isContentTypeOpen = !this.isContentTypeOpen;
  }

  /**
   * ガイドの表示をリクエストする
   * @param $event
   */
  onClickGuide($event: any): void {
    this.showGuide = true;
    $event.stopPropagation();
    this.clickGuide.emit(true);
  }
}
