import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-search-guide',
  templateUrl: './search-guide.component.html',
  styleUrls: ['./search-guide.component.scss'],
})
export class SearchGuideComponent {
  /**
   * 左の位置
   */
  @Input() set left(value: number) {
    this._left = value;
  }

  get left(): number {
    return this._left;
  }

  private _left = 0;

  /**
   * トップの位置
   */
  @Input() set top(value: number) {
    this._top = value;
  }

  get top(): number {
    return this._top;
  }

  private _top = 0;
}
