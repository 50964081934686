<div class="mt-6 px-2 max-w-screen-xl">
  <div class="min-w-min max-w-sm">
    <div>
      <h3 class="font-medium text-gray-900">Title</h3>
      <div class="mt-2 flex items-center justify-between">
        <p class="text-base text-gray-900 italic">{{ data.name }}</p>
      </div>
    </div>
    <div class="mt-4">
      <h3 class="font-medium text-gray-900">Information</h3>
      <dl
        class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200"
      >
        <div class="py-3 flex justify-between text-sm font-medium">
          <dt class="text-gray-500">Type</dt>
          <dd class="text-gray-900">
            <span
              class="text-xs p-1 px-2 text-white bg-bluemine-500"
              [style.backgroundColor]="setDocTypeColor(data.type)"
            >
              {{ data.type_name }}
            </span>
          </dd>
        </div>
        <!--
        <div
          class="py-3 flex justify-between text-sm font-medium"
        >
          <dt class="text-gray-500">Category</dt>
          <dd class="text-gray-900">{{ data.category }}</dd>
        </div>
        -->
        <div class="py-3 flex justify-between text-sm font-medium">
          <dt class="text-gray-500">No</dt>
          <dd class="text-gray-900">{{ data.type }}-{{ data.doc_no }}</dd>
        </div>
      </dl>
    </div>
  </div>
</div>
