import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { ApiResponse } from '../shared/apiResponse.interface';

export interface UpdateInfo {
  update_history: any[];
  per_page: number;
  from: number;
  to: number;
  total: number;
  current_page: number;
  last_page: number;
}

@Injectable({
  providedIn: 'root',
})
export class UpdateInfoService {
  /**
   * (Subject)
   */
  updateInfoSubject = new ReplaySubject<UpdateInfo>(1);

  /**
   * (Observable) updateInfoChanges
   */
  get updateInfoChanges(): Observable<UpdateInfo> {
    return this.updateInfoSubject.asObservable();
  }

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /**
   *
   */
  getUpdateInfo(perPage: number, page: number): void {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    const request = {
      data: {
        per_page: perPage,
        page,
      },
    };

    this.http
      .post<ApiResponse<UpdateInfo>>(
        `${environment.apiServer.baseUrl}/docs/update-history` +
          (environment.apiServer.local ? '.json' : ''),
        request,
        { headers }
      )
      .subscribe((response) => {
        this.updateInfoSubject.next(response.data);
      });
  }
}
