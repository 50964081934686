import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, LOCALE_ID, Injector } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ReplaySubject, Observable } from 'rxjs';
import { ScrollSpyService } from './scroll-spy.service';

// Helpers
function querySelectorAll<K extends keyof HTMLElementTagNameMap>(
  parent: Element,
  selector: K
): HTMLElementTagNameMap[K][];
function querySelectorAll<K extends keyof SVGElementTagNameMap>(
  parent: Element,
  selector: K
): SVGElementTagNameMap[K][];
function querySelectorAll<E extends Element = Element>(
  parent: Element,
  selector: string
): E[];
function querySelectorAll(parent: Element, selector: string): Array<Element> {
  // Wrap the `NodeList` as a regular `Array` to have access to array methods.
  // NOTE: IE11 does not even support some methods of `NodeList`, such as
  //       [NodeList#forEach()](https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach).
  return Array.from(parent.querySelectorAll(selector));
}

export interface TocItem {
  content: SafeHtml;
  href: string;
  fragment: string;
  level: string;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class PageTocService {
  /**
   * 表示中コンテンツのページ目次・インデックス Subject
   */
  activeItemIndex = new ReplaySubject<number | null>(1);

  /**
   * ページ目次 Subject
   */
  private tocSubject = new ReplaySubject<TocItem[]>(1);

  /**
   * valueChanges
   */
  get valueChanges(): Observable<TocItem[]> {
    return this.tocSubject.asObservable();
  }

  /**
   * MutationObserver
   */
  private changes!: MutationObserver;

  private headings: any;

  /**
   * コンストラクタ
   * @param injector Injector
   * @param document Document
   * @param sanitized DomSanitizer
   * @param scrollSpyService ScrollSpyService
   */
  constructor(
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document,
    private sanitized: DomSanitizer,
    private scrollSpyService: ScrollSpyService
  ) {}

  /**
   * ページ目次を初期化する
   * @param pageElement Element
   */
  initialize(pageElement: Element): void {
    const locale = this.injector.get(LOCALE_ID);
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        this.genPageToc(pageElement);
      });
    });

    this.changes.observe(pageElement, {
      attributes: true,
      childList: true,
      characterData: true,
    });
  }

  /**
   * ページ目次を生成する
   * @param pageElement Element
   * @param docId string
   */
  genPageToc(pageElement: Element, docId = ''): void {
    this.resetScrollSpyInfo();

    this.headings = this.findTocHeadings(pageElement);
    const idMap = new Map<string, number>();
    const tocList = this.headings.map((heading: any) => {
      const { title, content } = this.extractHeadingSafeHtml(heading);

      return {
        level: heading.tagName.toLowerCase(),
        href: `${docId}#${this.getId(heading, idMap)}`,
        fragment: this.getId(heading, idMap),
        title,
        content,
      };
    });

    this.tocSubject.next(tocList);
    this.scrollSpyService.spyOn(this.headings);
  }

  /**
   * サニタイズする
   * @param heading
   * @returns {content: SafeHtml, title: string}
   */
  private extractHeadingSafeHtml(heading: HTMLHeadingElement): {
    content: SafeHtml;
    title: string;
  } {
    const div: HTMLDivElement = this.document.createElement('div');
    div.innerHTML = heading.innerHTML;

    return {
      content: this.sanitized.bypassSecurityTrustHtml(div.innerHTML.trim()),
      title: (div.textContent || '').trim(),
    };
  }

  /**
   * Heading要素を探す
   * @private
   * @param pageElement Element
   * @param docId string
   * @return HTMLHeadingElement[]
   */
  private findTocHeadings(docElement: Element): HTMLHeadingElement[] {
    const headings = querySelectorAll<HTMLHeadingElement>(
      docElement,
      'h1,h2,h3'
    );
    return headings;
  }

  /**
   * スクロールをリセットする
   * @private
   */
  private resetScrollSpyInfo(): void {
    this.activeItemIndex.next(null);
  }

  /**
   * idを取得する
   * @private
   * @param h HTMLHeadingElement
   * @param idMap Map<string, number>
   * @returns string
   */
  private getId(h: HTMLHeadingElement, idMap: Map<string, number>): string {
    let id = h.parentElement?.id;
    if (id) {
      addToMap(id);
    } else {
      id = (h.textContent || '').trim().toLowerCase().replace(/\W+/g, '-');
      id = addToMap(id);
      h.id = id;
    }
    return id;

    // Map guards against duplicate id creation.
    function addToMap(key: string): string {
      const oldCount = idMap.get(key) || 0;
      const count = oldCount + 1;
      idMap.set(key, count);
      return count === 1 ? key : `${key}-${count}`;
    }
  }

  /**
   * スクロールスパイをリセットする
   */
  public resetScrollSpy(): void {
    this.scrollSpyService.spyOn(this.headings);
  }
}
