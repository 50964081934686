import { Component } from '@angular/core';

import { BaseOutlineIconComponent } from '../base-outline-icon.component';

@Component({
  selector: 'app-technical-information-outline-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    fill="none"
    viewBox="-851 815 113 113"
    stroke="currentColor"
  >
    <defs>
      <style>
        .cls-ti-1 {
          clip-path: url(#clip-ti);
        }
        .cls-ti-2 {
          fill: #3db8ed;
        }
        .cls-ti-3,
        .cls-ti-7 {
          fill: none;
        }
        .cls-ti-3 {
          stroke: #3db8ed;
        }
        .cls-ti-4 {
          opacity: 0.819;
        }
        .cls-ti-5 {
          fill: #fff;
        }
        .cls-ti-6 {
          stroke: none;
        }
        .cls-ti-7 {
          fill: #fff;
          fill-opacity: 0;
        }
      </style>
      <clipPath id="clip-ti">
        <rect x="-851" y="815" width="113" height="113" />
      </clipPath>
    </defs>
    <g id="a" class="cls-ti-1">
      <rect class="cls-ti-7" x="-851" y="815" width="113" height="113" />
      <g transform="translate(-1997 463)">
        <circle
          class="cls-ti-2"
          cx="47.874"
          cy="47.874"
          r="47.874"
          transform="translate(1156 361)"
        />
        <g class="cls-ti-3" transform="translate(1151 356)">
          <circle class="cls-ti-6" cx="53" cy="53" r="53" />
          <circle class="cls-ti-7" cx="53" cy="53" r="52.5" />
        </g>
      </g>
      <g class="cls-ti-4" transform="translate(-808.975 841.078)">
        <g transform="translate(-0.025)">
          <path
            class="cls-ti-5"
            d="M9.162,78.449c.225,0,.27,0,.4-.135a2.014,2.014,0,0,0,.27-1.259,3.624,3.624,0,0,0-.045-1.08c-.045-.09-.045-.36-.4-.36S7,75.57,6.868,75.57c0-.09-.045-.27-.225-.27H3.18c-.18,0-.225.18-.225.27-.135,0-2.159.045-2.519.045s-.36.225-.4.36A3.964,3.964,0,0,0-.014,77.1a2.12,2.12,0,0,0,.27,1.259c.135.135.18.135.4.135s.81.135.81,1.035c0,2.2-1.439,2.339-1.439,4.948V98.465c0,2.024.45,2.159.855,2.294.54.225,1.08.63,3.958.63s3.464-.4,3.958-.63c.36-.18.855-.27.855-2.294V84.431c0-2.519-1.439-2.744-1.439-4.948C8.308,78.584,8.892,78.449,9.162,78.449Z"
            transform="translate(0.025 -41.429)"
          />
          <path
            class="cls-ti-5"
            d="M10.583,5.263C10.268,3.1,9.819.045,9.819.045L8.469,0l-1.3.045S6.715,3.1,6.4,5.218c0,0,.36.9.765,1.934V37.379H9.819V7.152C10.223,6.117,10.583,5.263,10.583,5.263Z"
            transform="translate(-3.51)"
          />
        </g>
        <path
          class="cls-ti-5"
          d="M44.214,29.654h-.09c-.045-5.982-.045-11.02-.045-12.5,0-4.183,3.329-7.107,2.159-11.74C44.8-.259,41.066.551,41.066.551l1.349,5.083L37.692,6.758l-1.619-5.4c-1.035,0-2.114,1.125-2.609,3.958-.855,5.128,3.464,7.512,4.5,12.325.36,1.664.45,6.432.45,12.055h-.135a.9.9,0,0,0-.9.9V56.507c0,.495.4,3.149.9,3.149a6.5,6.5,0,0,0,3.059.63,5.228,5.228,0,0,0,2.834-.63c.495,0,.9-2.654.9-3.149V30.553A.838.838,0,0,0,44.214,29.654ZM40.8,12.291a1.664,1.664,0,1,1,1.664-1.664A1.66,1.66,0,0,1,40.8,12.291Z"
          transform="translate(-18.364 -0.281)"
        />
      </g>
    </g>
  </svg>`,
})
export class TechnicalInformationOutlineIconComponent extends BaseOutlineIconComponent {}
