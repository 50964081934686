import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { ReplaySubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { ApiResponse } from '../shared/apiResponse.interface';

import { fetchAuthSession } from 'aws-amplify/auth';

export interface Manual {
  docType: string;
  docId: string;
}

export interface Content {
  content: string;
}

@Injectable({
  providedIn: 'root',
})
export class PageContentService {
  /**
   * ドキュメントid
   */
  private manual!: Manual;

  /**
   * ページコンテンツ Subject
   */
  private contentSubject = new ReplaySubject<SafeHtml>(1);

  /**
   * ページコンテンツ
   */
  private content!: SafeHtml;

  /**
   * valueChanges
   */
  get valueChanges(): Observable<SafeHtml> {
    //    this.contentSubject.next(this.content);
    return this.contentSubject.asObservable();
  }

  /**
   * JWT Token
   */
  private token?: string;

  /**
   * コンストラクタ
   * @param sanitized DomSanitizer
   * @param http HttpClient
   */
  constructor(
    private sanitized: DomSanitizer,
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /**
   * サービスの初期化処理を行う
   * @returns Promise<void>
   */
  async init(): Promise<void> {
    if (environment.apiServer.auth) {
      this.token = (await fetchAuthSession()).tokens?.idToken?.toString()
    }
  }

  /**
   * ページコンテンツを取得する
   */
  getContent(manual: Manual, pageId: string): any {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.auth) {
      headers = headers.set('Authorization', `Bearer ${this.token}`);
    }

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    if (manual !== undefined) {
      this.manual = {
        docType: manual.docType,
        docId: manual.docId,
      };

      return this.http
        .get<ApiResponse<Content>>(
          `${environment.apiServer.baseUrl}/man/manuals/${manual.docType}/${manual.docId}/pages/${pageId}` +
            (environment.apiServer.local ? '.json' : ''),
          { headers }
        )
        .pipe(
          map((response) => {
            this.content = this.sanitized.bypassSecurityTrustHtml(
              response.data.content
            );
            this.contentSubject.next(this.content);
          })
        );
    }
  }

  /**
   * ページコンテンツを取得する
   */
  getContentWithKeywords(manual: Manual, pageId: string, keyword: string): any {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.auth) {
      headers = headers.set('Authorization', `Bearer ${this.token}`);
    }

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    let params = new HttpParams().set('keywords', keyword);

    if (manual !== undefined) {
      this.manual = {
        docType: manual.docType,
        docId: manual.docId,
      };

      return this.http
        .get<ApiResponse<Content>>(
          `${environment.apiServer.baseUrl}/man/manuals/${manual.docType}/${manual.docId}/pages/${pageId}` +
            (environment.apiServer.local ? '.json' : '') +
            '/highlight',
          { headers, params }
        )
        .pipe(
          map((response) => {
            this.content = this.sanitized.bypassSecurityTrustHtml(
              response.data.content
            );
            this.contentSubject.next(this.content);
          })
        );
    }
  }
}
