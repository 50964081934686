import { Component, HostBinding, HostListener, Inject } from '@angular/core';

import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';

export interface Data {
  title: string;
  message: string;
}

@Component({
  selector: 'app-confimation-dialog',
  templateUrl: './confimation-dialog.component.html',
  styleUrls: ['./confimation-dialog.component.scss'],
})
export class ConfimationDialogComponent {
  /**
   * HostBinding
   */
  @HostBinding('class') classAttribute: string = 'forcus:outline-none';

  constructor(
    public dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA) public data: Data
  ) {}

  @HostListener('mousedown', ['$event'])
  onClickOut($event: MouseEvent): void {
    $event.stopPropagation();
  }

  public onClickOk($event: any) {
    this.dialogRef.close(true);
  }

  public onClickCancel($event: any) {
    this.dialogRef.close(false);
  }
}
