import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, Subject } from 'rxjs';

import { ContentService } from './content.service';

export interface ImageSize {
  width: number;
  height: number;
}

@Injectable({
  providedIn: 'root',
})
export class SelectItemService {
  /**
   * BoM、HotSpotの選択状態 Subject
   */
  private itemIndexListSubject = new ReplaySubject<boolean[]>(1);

  /**
   * valueChanges
   */
  get valueChanges(): Observable<boolean[]> {
    return this.itemIndexListSubject.asObservable();
  }

  /**
   * BOM、Hotspotの選択状態
   */
  public itemIndexList: boolean[] = [];

  /**
   * コンストラクター
   * @param contentService ContentService
   */
  constructor(private contentService: ContentService) {
    this.contentService.valueChanges.subscribe((item) => {
      this.setInitItemIndexList(this.contentService.getItemIndexList());
    });
  }

  /**
   * BoMとHotSpotの選択状態を初期化する
   */
  setInitItemIndexList(nodes: boolean[]): void {
    this.itemIndexList = nodes;
    this.itemIndexListSubject.next(this.itemIndexList);
  }

  /**
   * BoMとHotspotの選択状態を保存する
   */
  changeItemIndex(key: string): void {
    let index = 0;
    index = Number(key);
    if (this.itemIndexList.length >= index) {
      this.itemIndexList[index - 1] = !this.itemIndexList[index - 1];
      this.itemIndexListSubject.next(this.itemIndexList);
    }
  }
}
