import { DOCUMENT } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  Inject,
  Renderer2,
  HostListener,
} from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-left-wide-sidenav',
  animations: [
    trigger('openAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('400ms', style({ opacity: 0 })),
      ]),
    ]),
    trigger('sideOverAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('200ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('200ms', style({ transform: 'translateX(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
  templateUrl: './left-wide-sidenav.component.html',
  styleUrls: ['./left-wide-sidenav.component.scss'],
})
export class LeftWideSidenavComponent {
  /**
   * サイドバーが開いている状態であるか
   */
  @Input() set expanded(value: boolean) {
    this._expanded = value;
    if (value === true) {
      this.renderer.addClass(this.document.body, 'overflow-y-hidden');
      //      this.renderer.addClass(this.document.body, 'inset-0');
      // this.renderer.addClass(this.document.body, 'fixed');
    } else {
      this.renderer.removeClass(this.document.body, 'overflow-y-hidden');
    }
  }

  get expanded(): boolean {
    return this._expanded;
  }

  public _expanded = false;

  /**
   * サイドバーのクローズを伝える
   */
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  /**
   * (HostListner) ウィンドウスクロールに対する動作をキャンセルする
   * @param $event any
   */
  @HostListener('window:scroll', ['$event'])
  onScroll($event: any): void {
    if (this._expanded === true) {
      $event.preventDefault();
    }
  }

  /**
   * (HostListner) マウスホイールで親要素に対する伝搬を止める
   * @param $event any
   */
  @HostListener('mousewheel', ['$event'])
  onMousewheel($event: any): void {
    if (this._expanded === true) {
      $event.stopPropagation();
    }
  }

  /**
   * (HostListner) タッチムーブで親要素に対する伝搬を止める
   * @param $event any
   */
  @HostListener('touchmove', ['$event'])
  onTouchmove($event: any): void {
    if (this._expanded === true) {
      $event.stopPropagation();
    }
  }

  /**
   * スマートフォン用サイドバーを閉じる
   */
  public onClick(): void {
    this._expanded = false;
    this.renderer.removeClass(this.document.body, 'overflow-y-hidden');
    //    this.renderer.removeClass(this.document.body, 'inset-0');
    //this.renderer.removeClass(this.document.body, 'fixed');
    this.close.emit(false);
  }
}
