import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-manual-info-basic',
  templateUrl: './manual-info-basic.component.html',
  styleUrls: ['./manual-info-basic.component.scss'],
})
export class ManualInfoBasicComponent implements OnInit {
  @Input() data: any;

  constructor() {}

  ngOnInit(): void {}

  /**
   * ドキュメントカラーをセットする
   * @param docType
   * @returns
   */
  setDocTypeColor(docType: any): string {
    let color: string = '';
    switch (docType) {
      case 'DM':
        color = '#bd362f';
        break;
      case 'UM':
        color = '#51a351';
        break;
      case 'SI':
        color = '#005686';
        break;
    }

    return color;
  }
}
