import { Component, OnInit, Input } from '@angular/core';
import { Breadcrumb, BreadcrumbService } from './breadcrumb.service';

import { Observable } from 'rxjs';

@Component({
  selector: 'lib-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() breadcrumbs: Array<{ label: string; url: string }> = [];

  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(private readonly breadcrumbService: BreadcrumbService) {
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$;
  }

  private url?: any = '';

  ngOnInit(): void {}
}
