import { Component } from '@angular/core';

import { BaseSolidIconComponent } from '../base-solid-icon.component';

@Component({
  selector: 'app-chevron-right-solid-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M7.293 14.707a1 1 0 0 1 0-1.414L10.586 10 7.293 6.707a1 1 0 0 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0z"
      clip-rule="evenodd"
    />
  </svg>`,
})
export class ChevronRightSolidIconComponent extends BaseSolidIconComponent {}
