import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';

export interface Bookmark {
  locale: string;
  doc_type: string;
  doc_no: string;
  content_type: string;
  bookmarked_date: string;
  pdf_path: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class BookmarkService {
  public bookmarks: Array<Bookmark> = [];

  /**
   * (Subject) ブックマーク
   */
  bookmarksSubject = new ReplaySubject<Array<Bookmark>>(1);

  /**
   * (Observable) bookmarksChanges
   */
  get bookmarksChanges(): Observable<Array<Bookmark>> {
    return this.bookmarksSubject.asObservable();
  }

  /**
   * constructor
   */
  constructor() {
    this.getBookmarksInLocalStorage();
    this.bookmarksSubject.next(this.bookmarks);
  }

  /**
   * LocalStorage内のブックマークを取得する
   */
  getBookmarksInLocalStorage(): void {
    const rawBookmarks = localStorage.getItem('bookmarks');
    if (rawBookmarks) {
      this.bookmarks = JSON.parse(rawBookmarks);
    }
  }

  /**
   * LocalStorage内のブックマークを取得し、ブックマーク・サブジェクトにセットする
   */
  refresh(): void {
    this.getBookmarksInLocalStorage();
    this.bookmarksSubject.next(this.bookmarks);
  }

  /**
   * ブックマークの追加
   * @param bookmark
   */
  add(bookmark: Bookmark): void {
    this.getBookmarksInLocalStorage();

    const index = this.bookmarks.findIndex(
      (element) =>
        element.locale === bookmark.locale &&
        element.doc_no === bookmark.doc_no &&
        element.doc_type === bookmark.doc_type
    );

    if (index === undefined || index === -1) {
      this.bookmarks.push(bookmark);
    }

    this.bookmarksSubject.next(this.bookmarks);
    if (this.bookmarks !== null) {
      const rawBookmarks = JSON.stringify(this.bookmarks);
      localStorage.setItem('bookmarks', rawBookmarks);
    }
  }

  /**
   * ブックマークの削除
   * @param bookmark
   */
  remove(locale: string, doc_no: string, doc_type: string): void {
    this.getBookmarksInLocalStorage();

    const index = this.bookmarks.findIndex(
      (element) =>
        element.locale === locale &&
        element.doc_no === doc_no &&
        element.doc_type === doc_type
    );

    if (!(index === undefined || index === -1)) {
      this.bookmarks.splice(index, 1);
    }

    this.bookmarksSubject.next(this.bookmarks);
    if (this.bookmarks !== null) {
      const rawBookmarks = JSON.stringify(this.bookmarks);
      localStorage.setItem('bookmarks', rawBookmarks);
    }
  }

  /**
   * ブックマークのクリア
   */
  clear(): void {
    this.bookmarks = [];
    this.bookmarksSubject.next(this.bookmarks);
    localStorage.removeItem('bookmarks');
  }

  /**
   * ブックマークを取得する
   * @returns
   */
  getBookmarks(): Array<Bookmark> {
    return this.bookmarks;
  }
}
