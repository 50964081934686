import { Component } from '@angular/core';

import { BaseSolidIconComponent } from '../base-solid-icon.component';

@Component({
  selector: 'app-home-solid-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
    />
  </svg>`,
})
export class HomeSolidIconComponent extends BaseSolidIconComponent {}
