import { Component } from '@angular/core';

import { BaseSolidIconComponent } from '../base-solid-icon.component';

@Component({
  selector: 'app-chevron-left-solid-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
      clip-rule="evenodd"
    />
  </svg>`,
})
export class ChevronLeftSolidIconComponent extends BaseSolidIconComponent {}
