import {
  AfterViewInit,
  Component,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Observable } from 'rxjs';

import { DocumentBasic, ManualTocService } from '../manual-toc.service';

import { ManualInfoBasicComponent } from '../manual-info-basic/manual-info-basic.component';
import { ManualInfoModelComponent } from '../manual-info-model/manual-info-model.component';

@Component({
  selector: 'app-manual-info',
  templateUrl: './manual-info.component.html',
  styleUrls: ['./manual-info.component.scss'],
})
export class ManualInfoComponent implements OnInit, AfterViewInit {
  public tabs = [
    {
      label: 'Basic',
    },
    {
      label: 'Models',
    },
  ];

  /**
   * ドキュメントの基本情報 (Observable)
   */
  public docBasic$?: Observable<DocumentBasic>;

  /**
   * ドキュメントの基本情報
   */
  public docBasic!: DocumentBasic;

  /**
   * ViewChild 選択されたタブのコンテンツ表示域
   */
  @ViewChild('tabComponentContainer', { read: ViewContainerRef })
  public tabComponentContainer!: ViewContainerRef;

  /**
   * コンストラクタ
   * @param componentFactoryResolver ComponentFactoryResolver
   */
  constructor(private manualTocService: ManualTocService) {}

  ngOnInit(): void {
    this.docBasic$ = this.manualTocService.docBasicChanges;
    this.docBasic$.subscribe((docBasic) => {
      this.docBasic = docBasic;
    });
  }

  /**
   * パネルの開閉状態
   */
  public isOpen = true;

  /**
   * AfterViewInit
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      let componentRef: ComponentRef<ManualInfoBasicComponent> =
        this.tabComponentContainer.createComponent(ManualInfoBasicComponent);
      componentRef.instance.data = this.docBasic;
    }, 0);
  }

  /**
   * 選択されたタブに応じてコンポーネントを動的に作成する
   * @param tabIndex number
   */
  selectedTab(tabIndex: number): void {
    this.tabComponentContainer.clear();
    switch (tabIndex) {
      case 0:
        let componentRef1: ComponentRef<ManualInfoBasicComponent> =
          this.tabComponentContainer.createComponent(ManualInfoBasicComponent);
        componentRef1.instance.data = this.docBasic;
        break;
      case 1:
        let componentRef2: ComponentRef<ManualInfoModelComponent> =
          this.tabComponentContainer.createComponent(ManualInfoModelComponent);
        componentRef2.instance.data = this.docBasic;
        break;
    }
  }

  /**
   * パネルをクローズする
   */
  closePanel(): void {
    this.isOpen = false;
  }
}
