import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'doctype',
})
export class DoctypePipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'DM') return "Dealer's Manual";
    if (value === 'UM') return "User's Manual";
    if (value === 'SI') return 'Service Instruction';
    if (value === 'EV') return 'Exploded View';
    return value;
  }
}
