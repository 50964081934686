import { Inject, Injectable } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRouteSnapshot,
  Data,
} from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface Breadcrumb {
  label: string;
  url?: string;
  altUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  private counter = 0;

  constructor(
    private router: Router,
    @Inject('BREADCRUMBS') private injectBreadcrumbs: Array<Breadcrumb>
  ) {
    this.router.events
      .pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        // Construct the breadcrumb hierarchy
        if (this.injectBreadcrumbs.length === 0) {
          const root = this.router.routerState.snapshot.root;
          const breadcrumbs: Breadcrumb[] = [
            {
              label: 'Home',
              url: '',
            },
          ];
          this.counter = 0;
          this.addBreadcrumb(breadcrumbs, root);

          this._breadcrumbs$.next(breadcrumbs);
        } else {
          this._breadcrumbs$.next(this.injectBreadcrumbs);
        }
      });
  }

  private addBreadcrumb(
    breadcrumbs: Breadcrumb[],
    route?: ActivatedRouteSnapshot | null
  ): void {
    if (route) {
      // Add an element for the current route part
      if (route.data.page) {
        const routeUrl = route.url.map((url) => url.path);

        const breadcrumb = {
          label: this.getLabel(route.data),
          url: route.data.page.baseUrl
            ? breadcrumbs[breadcrumbs.length - 1].url +
              '/' +
              route.data.page.baseUrl
            : breadcrumbs[breadcrumbs.length - 1].url +
              '/' +
              routeUrl.join('/'),
          altUrl: route.data.page.altUrl
            ? breadcrumbs[breadcrumbs.length - 1].url +
              '/' +
              route.data.page.altUrl
            : undefined,
        };

        breadcrumbs.push(breadcrumb);
      }
      // Add another element for the next route part
      this.addBreadcrumb(breadcrumbs, route.firstChild);
    }
  }

  private getLabel(data: Data): any {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return typeof data.page.breadcrumb === 'function'
      ? data.page.breadcrumb(data)
      : data.page.breadcrumb;
  }
}
