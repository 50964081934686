<div #navContainer class="z-30 bg-white">
  <!-- Start header -->
  <header class="fixed left-0 right-0 top-0 z-30">
    <div
      *ngIf="_notify.show"
      class="relative flex h-12 justify-center border border-green-300 bg-green-200 pb-0 pt-0.5"
    >
      <div
        class="flex h-full w-full max-w-screen-xl items-center justify-between px-4 sm:px-5"
      >
        <div
          class="text-gray-500"
          [innerHTML]="notify.message | sanitizeHtml"
        ></div>
        <button
          type="button"
          (click)="onClickNotify()"
          class="inline-flex w-6 items-center justify-between text-left text-gray-700 focus:border-transparent focus:outline-none"
        >
          <span class="ml-3 flex h-7 w-6 items-center">
            <app-x-outline-icon
              size="20"
              class="transition duration-300 ease-in-out"
              svgClass="h-6 w-6"
            ></app-x-outline-icon>
          </span>
        </button>
      </div>
    </div>

    <!-- Start top header (logo, locale selector)-->
    <div class="flex justify-center border-b bg-white">
      <div class="flex h-12 w-full max-w-screen-xl items-center">
        <a class="w-full" href="https://si.shimano.com">
          <img
            class="h-4 px-5"
            src="https://si.shimano.com/images/common/etc/logo-blue.webp"
          />
        </a>
        <span class="max-w-screen-xl"></span>
        <div class="flex w-full justify-end">
          <div class="flex items-center border-l px-1">
            <button
              type="button"
              (click)="onClick()"
              class="group inline-flex items-center bg-white text-base font-normal text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-bluemine-500 focus:ring-offset-2"
              [attr.aria-expanded]="localeExpanded"
            >
              <span>{{ locale_name }}</span>
              <app-chevron-down-solid-icon
                size="20"
                svgClass="text-gray-400 group-hover:text-gray-500"
                class="transition duration-300 ease-in-out"
                [ngClass]="{
                  'rotate-180 transform': localeExpanded,
                  '': !localeExpanded
                }"
              ></app-chevron-down-solid-icon>
            </button>
            <button
              class="relative ml-2 h-10 w-10 bg-white text-gray-500 focus:outline-none md:hidden"
              (click)="onClickMenu()"
            >
              <span class="sr-only">Open main menu</span>
              <div
                class="absolute left-1/2 top-1/2 block w-5 -translate-x-1/2 -translate-y-1/2 transform"
              >
                <span
                  aria-hidden="true"
                  class="absolute block h-0.5 w-5 transform bg-current text-blue-500 transition duration-300 ease-in-out"
                  [ngClass]="{
                    'rotate-45': menuExpanded,
                    ' -translate-y-1.5': !menuExpanded
                  }"
                ></span>
                <span
                  aria-hidden="true"
                  class="absolute block h-0.5 w-5 transform bg-current text-blue-500 transition duration-300 ease-in-out"
                  [ngClass]="{
                    'opacity-0': menuExpanded
                  }"
                ></span>
                <span
                  aria-hidden="true"
                  class="absolute block h-0.5 w-5 transform bg-current text-blue-500 transition duration-300 ease-in-out"
                  [ngClass]="{
                    '-rotate-45': menuExpanded,
                    ' translate-y-1.5': !menuExpanded
                  }"
                ></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End top header -->

    <!-- Start second header (navigation) -->
    <div class="relative hidden justify-center border-b bg-blue-500 md:flex">
      <div
        class="flex w-full max-w-screen-xl items-center justify-between px-4 md:justify-start md:space-x-4"
      >
        <div
          class="hidden md:flex md:flex-1 md:items-center md:justify-between"
        >
          <nav>
            <ul class="flex items-center">
              <ng-container *ngFor="let node of menuItems">
                <lib-nav-item [node]="node"></lib-nav-item>
              </ng-container>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <!-- End second header -->

    <!-- Start third header (breadcrumbs, toolbar) -->
    <div
      class="hidden justify-center"
      [ngClass]="{
        'md:flex': beneath_header,
        '': !beneath_header
      }"
    >
      <div class="relative flex w-full max-w-screen-xl flex-col">
        <!-- Start manual toolbar for PC -->
        <div
          class="app-toolbar relative flex h-10 flex-shrink-0 bg-gradient-to-b from-white via-white"
        >
          <div class="flex flex-1 items-center justify-between px-1 md:px-5">
            <!-- Start breadcrumb -->
            <lib-breadcrumbs [breadcrumbs]="breadcrumbs"></lib-breadcrumbs>
            <!-- End breadcrumb -->

            <ng-content select="[tools-desktop]"> </ng-content>
          </div>
        </div>
        <!-- End manual toolbar for PC -->
      </div>
    </div>
    <!-- End third header -->
  </header>
  <!-- End header -->

  <lib-nav-sub-menu *ngIf="menuExpanded" [notify]="_notify.show">
  </lib-nav-sub-menu>

  <!-- Start toolbar area for mobile -->
  <div
    class="relative flex w-full max-w-screen-xl flex-col"
    [ngClass]="{
      'mt-12': !_notify.show,
      'mt-24': _notify.show,
      'md:mt-[11rem]': _notify.show && beneath_header,
      'md:mt-[9rem]':
        (!_notify.show && beneath_header) || (_notify.show && !beneath_header),
      'md:mt-24': !_notify.show && !beneath_header
    }"
  >
    <button
      *ngIf="sidebar"
      (click)="onClickSidebar()"
      class="fixed right-1 z-10 m-1 bg-transparent px-1 text-gray-900 shadow-sm hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-bluemine-500 md:hidden"
      [ngClass]="{
        'top-36': _notify.show,
        'top-24': !_notify.show
      }"
    >
      <span class="sr-only">Open sidebar</span>
      <!-- Heroicon name: outline/menu-alt-2 -->
      <app-menu-alt-2-outline-icon
        svgClass="h-8 w-6"
      ></app-menu-alt-2-outline-icon>
    </button>

    <lib-toolbar-modal *ngIf="toolbar" [notify]="_notify.show">
      <ng-content select="[tools-smartphone]"> </ng-content>
    </lib-toolbar-modal>
  </div>
  <!-- End toolbar area for mobile -->
</div>

<!--
  Start locale selector

    Entering: "transition ease-out duration-200"
      From: "opacity-0 -translate-y-1"
      To: "opacity-100 translate-y-0"
    Leaving: "transition ease-in duration-150"
      From: "opacity-100 translate-y-0"
      To: "opacity-0 -translate-y-1"
  -->
<div
  *ngIf="localeExpanded"
  [@openAnimation]
  class="fixed inset-x-0 left-0 top-12 z-40 transform border-t shadow-sm transition ease-in-out"
  [ngClass]="{
    'top-24': _notify.show,
    'top-12': !_notify.show
  }"
>
  <div class="absolute inset-0 flex" aria-hidden="true">
    <div class="w-full bg-white"></div>
  </div>
  <div class="relative mx-auto h-screen max-w-7xl overflow-y-auto">
    <nav
      class="mb-36 bg-white px-4 py-6 sm:grid-cols-2 sm:gap-x-8 sm:px-6 sm:py-8 lg:px-8 xl:pr-12"
      aria-labelledby="select-language"
    >
      <div>
        <h3 class="text-sm font-medium uppercase tracking-wide text-gray-500">
          Select a language
        </h3>
        <ul
          role="list"
          class="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
        >
          <li *ngFor="let publicLocale of publicLocales; index as i">
            <a
              class="flex cursor-pointer items-center py-1 text-base text-gray-900 transition duration-75 ease-in-out hover:bg-gray-50 hover:text-blue-900 hover:underline"
              (click)="onClickLocale(publicLocale.locale)"
            >
              <span class="ml-4">{{ publicLocale.name }}</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
<!-- End locale selector -->
