import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchScreenStatusService {
  expandedState$ = new BehaviorSubject<{
    [name: string]: { isExpanded: boolean };
  }>({
    series: { isExpanded: false },
    generation: { isExpanded: false },
    component: { isExpanded: false },
    model: { isExpanded: false },
  });

  setExpandedState(name: string, isExpanded: boolean) {
    let expandedState = this.expandedState$.value;
    expandedState[name].isExpanded = isExpanded;
    this.expandedState$.next(expandedState);
  }
}
