<!-- Start left sidebar for mobile -->
<app-left-wide-sidenav [expanded]="_expanded" (close)="closeSidebar()">
  <nav class="mt-0 h-0 flex-1 overflow-y-auto">
    <div class="mx-3 pb-4">
      <ul class="py-0">
        <li class="text-gray-700">
          <div class="flex w-full items-center justify-between pr-0">
            <span>
              <p
                class="px-3 py-1 pl-1 text-sm font-semibold capitalize tracking-wide"
                i18n
              >
                FILTER
              </p>
            </span>
          </div>
        </li>
      </ul>
      <ul>
        <li class="border border-l-0 border-r-0 border-gray-200">
          <div class="flex py-0.5 pl-1 pr-0">
            <!-- Expand/collapse button -->
            <button
              type="button"
              (click)="onClickAccordion()"
              class="inline-flex w-full items-center justify-between text-left text-gray-700 focus:border-transparent focus:outline-none"
              [attr.aria-controls]=""
              [attr.aria-expanded]="isOpen"
            >
              <span class="flex w-full">
                <span
                  class="inline-flex items-center pl-0 text-sm font-semibold capitalize tracking-wide"
                  i18n
                  >document type</span
                >
              </span>
              <span class="ml-1 flex h-8 items-center">
                <app-chevron-down-outline-icon
                  size="18"
                  class="transition duration-300 ease-in-out"
                  [ngClass]="{
                    'rotate-180 transform': isOpen,
                    '': !isOpen
                  }"
                  svgClass="h-3 w-3"
                ></app-chevron-down-outline-icon>
              </span>
            </button>
          </div>

          <div
            class="overflow-hidden transition-[max-height] duration-700 ease-in-out"
            [ngClass]="{
              'max-h-[1000px]': isOpen,
              'max-h-0': !isOpen
            }"
          >
            <div
              *ngIf="isOpen"
              class="grid grid-flow-row grid-cols-3 gap-1 pb-3"
            >
              <div
                *ngFor="let docType of docTypes; index as i"
                class="flex items-center truncate py-1 px-1 text-sm text-gray-500"
              >
                <input
                  id="doctype_dm"
                  name="doctype"
                  type="checkbox"
                  [checked]="docType.checked"
                  class="z-10 h-5 w-5 rounded-sm border-gray-200 text-bluemine-600 accent-bluemine-500 focus:ring-bluemine-500"
                  (click)="onClick($event, docType.name)"
                />
                <span class="pl-1"> {{ docType.name }} </span>
              </div>
            </div>
          </div>
        </li>

        <app-search-result-tagset
          *ngFor="let tagSet of TagSets$ | async; index as i"
          [tagSetName]="tagSet.key"
          [tags]="tagSet.buckets"
        ></app-search-result-tagset>
      </ul>
    </div>
  </nav>
</app-left-wide-sidenav>
<!-- End left sidebar for mobile -->
