import { Component } from '@angular/core';

import { BaseOutlineIconComponent } from '../base-outline-icon.component';

@Component({
  selector: 'app-word-definition-outline-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    fill="none"
    viewBox="0 0 100.4 100.4"
    stroke="currentColor"
  >
    <g>
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M92.9,60.4l-7.4-5v-15l7.4-4.9c0.4-0.3,0.7-0.7,0.7-1.2c0-0.5-0.3-1-0.7-1.2L54.8,7.5c-0.5-0.3-1.2-0.3-1.7,0L6.1,38.9
c-0.2,0.1-0.3,0.2-0.4,0.4c0,0,0,0,0,0.1c-0.2,0.3-0.2,0.5-0.2,0.8v27.5c0,0.5,0.3,1,0.7,1.2l38.1,25.4c0.3,0.2,0.5,0.3,0.8,0.3
c0.3,0,0.6-0.1,0.8-0.3l47.1-31.4c0.4-0.3,0.7-0.7,0.7-1.2C93.6,61.1,93.3,60.6,92.9,60.4z M54,10.6l35.4,23.6L83.6,38
c-0.3,0.1-0.6,0.3-0.8,0.5L45,63.7L16.6,44.8c-0.1-0.1-0.2-0.1-0.2-0.2l-6.8-4.5L54,10.6z M17.1,48.8l27,18c0.5,0.3,1.2,0.3,1.7,0
l36.7-24.5v12.3L45.2,79.2l-28.1-18L17.1,48.8L17.1,48.8z M45,91.2L8.4,66.8V43l5.8,3.8V62c0,0.5,0.3,1,0.7,1.3l29.6,19
c0.2,0.2,0.5,0.2,0.8,0.2c0.3,0,0.6-0.1,0.8-0.2l37.4-24.6l5.9,4L45,91.2z"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M47.5,24.3l17.8,12.2c0.3,0.2,0.6,0.3,0.8,0.3c0.5,0,0.9-0.2,1.2-0.7c0.5-0.7,0.3-1.6-0.4-2.1L49.2,21.8
c-0.7-0.5-1.6-0.3-2.1,0.4C46.6,22.9,46.8,23.8,47.5,24.3z"
      />
    </g>
  </svg>`,
})
export class WordDefinitionOutlineIconComponent extends BaseOutlineIconComponent {}
