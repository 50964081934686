<app-panel
  [title]="title"
  [expanded]="isOpen"
  [initialWidth]="320"
  (close)="closePanel()"
>
  <section class="w-full pb-5">
    <div class="flex items-center space-x-1 py-1">
      <!-- Show Detail -->
      <button
        class="relative flex cursor-pointer items-center border border-transparent px-1 py-1 text-xs font-normal tracking-wider hover:rounded-md hover:border-gray-200 hover:shadow-sm sm:text-sm"
        (click)="onToggleShowTag()"
      >
        <span class="select-none whitespace-nowrap" i18n>Detail</span>
        <button
          type="button"
          class="group relative inline-flex h-5 w-8 flex-shrink-0 cursor-pointer items-center justify-center rounded-full border-bluemine-500 focus:outline-none"
          role="switch"
          [attr.aria-checked]="isShowTags"
        >
          <span class="sr-only">Tag</span>
          <span
            aria-hidden="true"
            class="pointer-events-none absolute h-full w-full rounded-md bg-white"
          ></span>
          <span
            aria-hidden="true"
            class="pointer-events-none absolute mx-auto h-3 w-7 rounded-full transition-colors duration-200 ease-in-out"
            [ngClass]="{
              'bg-bluemine-600': isShowTags,
              'bg-gray-200': !isShowTags
            }"
          ></span>
          <span
            aria-hidden="true"
            class="pointer-events-none absolute left-4 inline-block h-4 w-4 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
            [ngClass]="{
              'translate-x-0': isShowTags,
              '-translate-x-4': !isShowTags
            }"
          ></span>
        </button>
      </button>

      <span
        *ngIf="(bookmarks$ | async)?.length"
        class="pl-1 text-gray-500 hover:text-blue-500"
      >
        <a
          (click)="onClickAllClear()"
          href="javascript:void(0)"
          class="text-sm"
        >
          Clear All
        </a>
      </span>
    </div>

    <div class="relative flex-1">
      <p
        *ngIf="!(bookmarks$ | async)?.length"
        class="my-8 text-center text-base"
        i18n
      >
        No Bookmarks
      </p>

      <ul class="grid grid-cols-1 gap-y-1">
        <app-search-bookmark-item
          *ngFor="
            let bookmark of (bookmarks$ | async)?.slice()?.reverse();
            index as i
          "
          [bookmark]="bookmark"
          [isShowTags]="isShowTags"
          [isPDFLink]="isPDFLink"
        ></app-search-bookmark-item>
      </ul>
    </div>
  </section>
  <!-- End search area -->
</app-panel>
