import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toolbar-button',
  templateUrl: './toolbar-button.component.html',
  styleUrls: ['./toolbar-button.component.scss'],
})
export class ToolbarButtonComponent {
  /**
   * ボタンのクリックを伝える
   */
  @Output() emitClick: EventEmitter<boolean> = new EventEmitter();

  /**
   * ボタンのクリックを伝える
   */
  public onClick(): void {
    this.emitClick.emit(true);
  }
}
