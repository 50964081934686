<!-- Start nav  -->
<nav #pageToc class="lg:hidden">
  <ul>
    <ng-container *ngFor="let toc of tocList$ | async; index as i">
      <li
        *ngIf="toc.level !== 'h1'"
        title="{{ toc.title }}"
        class="text-blue-500"
        [ngClass]="{
          '': toc.level === 'h1',
          'ml-0': toc.level === 'h2',
          'ml-4': toc.level === 'h3'
        }"
      >
        <a
          href="javascript:void(0);"
          class="hover:text-bluemine-500 hover:underline"
          (click)="onClickPageToc(toc.fragment)"
          [innerHTML]="toc.content"
        ></a>
      </li>
    </ng-container>
  </ul>
</nav>
<!-- End nav  -->
