<a
  [@NavPopoverAnimation]
  href="{{ node.url }}"
  target="{{ node.target }}"
  class="w-full rounded-sm p-3 pl-1 hover:bg-blue-600 lg:pl-5"
>
  <div class="ml-4">
    <p class="text-sm font-normal tracking-wider text-white">
      {{ node.label }}
    </p>
  </div>
</a>
