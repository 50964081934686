import { Component } from '@angular/core';

import { BaseOutlineIconComponent } from '../base-outline-icon.component';

@Component({
  selector: 'app-x-outline-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M6 18 18 6M6 6l12 12"
    />
  </svg>`,
})
export class XOutlineIconComponent extends BaseOutlineIconComponent {}
