import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  LOCALE_ID,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

import {
  Hit,
  RequestTag,
  RequestTags,
  ResultItemService,
  Tag,
} from '../result-item.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent implements OnInit {
  /**
   * 検索結果の表示開始インデックス
   */
  public from = 0;

  /**
   * 検索結果の表示数
   */
  public size = 10;

  /**
   * インプットキーワード
   */
  public keywords = '';

  /**
   * インプットモデル
   */
  public inputModel = '';

  /**
   * ソートキー
   */
  public sortKey = 'doc_type';

  /**
   * ソート順
   */
  public sortOrder = 'asc';

  /**
   * タグの表示
   */
  public isShowTags = true;

  /**
   * タグの表示
   */
  public isShowTagsOpen = false;

  /**
   * ソートセレクト
   */
  public isSortSelectOpen = false;

  /**
   * リンクセレクト
   */
  public isFormatSelectOpen = false;

  /**
   * PDFリンク
   */
  public isPDFLink = false;

  /**
   * ページサイズ
   */
  public pageSizeOptions = [10, 20, 50];

  /**
   * サイドバーのオープンを伝える
   */
  @Output() openSidebar: EventEmitter<boolean> = new EventEmitter();

  /**
   * ブックマークのオープンを伝える
   */
  @Output() openBookmark: EventEmitter<boolean> = new EventEmitter();

  /**
   * キーワードインプット要素に対する参照 (ViewChild)
   */
  @ViewChild('keywordRef') keywordRef!: ElementRef;

  /**
   * モデルサジェスト
   */
  public modelTagSets$?: Observable<Tag[]>;

  /**
   * 検索結果
   */
  public hits$?: Observable<Array<Hit>>;

  /**
   * 検索結果
   */
  public hitTotal$?: Observable<number>;

  /**
   * 選択タグ・オブザーバブル
   */
  public activeTags$: Observable<any>;

  /**
   * ロケールid
   */
  public locale!: string;

  /**
   * モバイル幅の判定
   */
  public isMobile = false;

  constructor(
    private activateRoute: ActivatedRoute,
    private injector: Injector,
    private router: Router,
    private resultItemService: ResultItemService
  ) {
    this.locale = this.injector.get(LOCALE_ID);

    this.modelTagSets$ = this.resultItemService.modelTagSetsChanges;
    this.hits$ = this.resultItemService.hitsChanges;
    this.hitTotal$ = this.resultItemService.hitTotalChanges;
    this.activeTags$ = this.resultItemService.requestTagsChanges;

    const value1 = localStorage.getItem('size_per_page');
    if (value1) {
      this.size = JSON.parse(value1);
    }

    const value2 = localStorage.getItem('is_pdf_link');
    if (value2) {
      this.isPDFLink = JSON.parse(value2);
    }

    activateRoute.queryParams.subscribe((val) => {
      this.keywords = this.activateRoute.snapshot.queryParams.q
        ? this.activateRoute.snapshot.queryParams.q.replace(
            /("[^"]+")|[,]+/g,
            (m: any, g1: any) => (g1 ? g1 : ' ')
          )
        : undefined;

      this.inputModel = '';
      const modelString = this.activateRoute.snapshot.queryParams.input_model;
      if (modelString) {
        this.inputModel = modelString;
      }

      this.from = Number(this.activateRoute.snapshot.queryParams.from)
        ? Number(this.activateRoute.snapshot.queryParams.from)
        : 0;

      const value = localStorage.getItem('size_per_page');

      this.size = Number(this.activateRoute.snapshot.queryParams.size)
        ? Number(this.activateRoute.snapshot.queryParams.size)
        : value
        ? JSON.parse(value)
        : 10;

      const sortString = this.activateRoute.snapshot.queryParams.sort;
      if (sortString) {
        const sortStrings = sortString.split(',');
        this.sortKey = sortStrings[0];
        this.sortOrder = sortStrings[1];
      }
    });
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
    const viewportWidth = this.width;
    if (viewportWidth > 760) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  /**
   * ウィンドウのリサイズでViewportScrollerのオフセットを変更する
   */
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const viewportWidth = this.width;
    if (viewportWidth > 760) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  /**
   * モデル(入力)の変更をトリガーとする
   */
  public inputModelChange(): void {
    this.resultItemService.setModel(this.inputModel);
    if (this.inputModel !== undefined && this.inputModel.length > 1) {
      this.resultItemService.suggest();
    } else {
      this.resultItemService.clearSuggest();
    }
  }

  /**
   * サジェストからモデル・タグを選択する
   * @param value any
   */
  public addModelTag(value: any): void {
    this.inputModel = '';
    this.resultItemService.setModel(this.inputModel);
    this.resultItemService.clearSuggest();

    let params = this.resultItemService.addTag({
      name: value,
      category: 'model',
    });

    this.setQueryParams(params, true);
  }

  /**
   * モデル(入力)で検索する
   */
  public onEnterModel(): void {
    const params = this.resultItemService.setModel(this.inputModel);

    this.setQueryParams(params, true);
  }

  /**
   * キーワード(入力)の変更をトリガーとする
   */
  public keywordsChange(): void {
    this.resultItemService.setKeywords(this.keywords);
  }

  /**
   * 検索を行う
   * @param event any
   */
  public search(event: any): void {
    const params = this.resultItemService.setKeywords(event.target.value);

    this.setQueryParams(params, true);
  }

  /**
   * クリックで選択タグからタグを除去する
   * @param tag Tag・インターフェース
   * @returns void
   */
  public removeTag(tag: RequestTag): void {
    const params = this.resultItemService.removeTag(tag);

    this.setQueryParams(params, true);
  }

  /**
   * タグを全てクリアする
   */
  onClickAllClear(): void {
    const params = this.resultItemService.clearFacets();

    this.setQueryParams(params, true);
  }

  /**
   * ページを変更する/ページの表示数を変更する
   * @param event
   */
  public handlePageEvent(event: PageEvent): void {
    if (event.pageSize !== this.size) {
      this.size = event.pageSize;
      this.from = 0;
    } else {
      this.from = this.size * event.pageIndex;
    }

    const params = this.resultItemService.getParams();

    this.setQueryParams(params);
  }

  public divide(): number {
    return Math.trunc(this.from / this.size);
  }

  /**
   * モバイル用サイドバーを閉じる
   */
  public onClickSidebar(): void {
    this.openSidebar.emit(true);
  }

  /**
   * タグの表示ボタンを広げる
   */
  public wideShowTagButton(): void {
    this.isShowTagsOpen = true;
  }

  /**
   * タグの表示ボタンを縮める
   */
  public narrowShowTagButton(): void {
    this.isShowTagsOpen = false;
  }

  /**
   * タグ表示を切り替える
   */
  public onToggleShowTag(): void {
    this.isShowTags = !this.isShowTags;
  }

  /**
   * ブックマーク表示に切り替える
   */
  public onClickBookmark($event: any): void {
    this.openBookmark.emit(true);
  }

  /**
   * ソートセレクションを開く
   */
  public wideSortButton(): void {
    this.isSortSelectOpen = true;
  }

  /**
   * ソートセレクションを閉じる
   */
  public narrowSortButton(): void {
    this.isSortSelectOpen = false;
  }

  /**
   * ソートの条件を変更するボタンを押下する
   */
  public onClickSortButton($event: any): void {
    this.isSortSelectOpen = !this.isSortSelectOpen;
    $event.stopPropagation();
  }

  /**
   * ソートの条件を変更する
   * @param $event
   * @param sortKey
   * @param sortOrder
   */
  public onClickSelectSort(
    $event: any,
    sortKey: string,
    sortOrder: string
  ): void {
    let params;

    $event.stopPropagation();

    this.sortKey = sortKey;
    this.sortOrder = sortOrder;

    params = this.resultItemService.setSort(sortKey, sortOrder);

    this.setQueryParams(params, true);

    this.isSortSelectOpen = !this.isSortSelectOpen;
  }

  /**
   * リンク条件セレクションを開く
   */
  public wideFormatButton(): void {
    this.isFormatSelectOpen = true;
  }

  /**
   * リンク条件セレクションを閉じる
   */
  public narrowFormatButton(): void {
    this.isFormatSelectOpen = false;
  }

  /**
   * リンクの条件を変更するボタンを押下する
   */
  public onClickFormatButton($event: any): void {
    this.isFormatSelectOpen = !this.isFormatSelectOpen;
    $event.stopPropagation();
  }

  /**
   * リンクの条件を変更する
   */
  public onClickSelectFormat($event: any): void {
    this.isPDFLink = !this.isPDFLink;

    localStorage.setItem('is_pdf_link', JSON.stringify(this.isPDFLink));
    this.openBookmark.emit(false);
  }

  /**
   * クエリパラメータをセットしナビゲートする
   * @param params
   * @param init
   */
  setQueryParams(params: any, init: boolean = false): void {
    let queryParams: any = {};

    queryParams.doctype = params.docTypes.length
      ? params.docTypes.join(',')
      : undefined;

    queryParams.contenttype = params.contentTypes.length
      ? params.contentTypes.join(',')
      : undefined;

    params.reqTagsMap.forEach((reqTags: RequestTags, category: string) => {
      if (reqTags.names?.length) {
        queryParams = {
          ...queryParams,
          [category]: reqTags.names.join(','),
        };
      }
    });

    queryParams.input_model = params.inputModel ? params.inputModel : undefined;

    queryParams.q = params.keywords
      ? params.keywords.replace(/("[^"]+")|[\s]+/g, (m: any, g1: any) =>
          g1 ? g1 : ','
        )
      : undefined;

    queryParams.sort =
      params.sortKey && params.sortOrder
        ? `${params.sortKey},${params.sortOrder}`
        : undefined;

    queryParams.from = !init ? this.from : undefined;

    queryParams.size = !init ? this.size : undefined;

    this.router.navigate(['manual/search'], {
      queryParams,
    });
  }

  /**
   * ドキュメントタイプの背景色の設定
   * @param docType
   * @returns
   */
  setDocTypeColor(docType: string): string {
    let color = '';
    switch (docType) {
      case 'DM':
        color = '#bd362f';
        break;
      case 'UM':
        color = '#51a351';
        break;
      case 'SI':
        color = '#005686';
        break;
      case 'EV':
        color = '#FD8C34';
        break;
      case 'QM':
        color = '#4aacc5';
        break;
      case 'SM':
        color = '#E6B8B7';
        break;
    }
    return color;
  }

  public get width() {
    return window.innerWidth;
  }
}
