<div id="top-of-page"></div>
<lib-nav [sidebar]="false" [notify]="notify" (closeNotify)="closeNotify()">
</lib-nav>

<main>
  <div class="flex justify-center">
    <div class="max-w-screen-xl w-full mx-2 md:mx-5">
      <div class="relative flex flex-col px-4 sm:px-4 text-left">
        <div class="flex flex-row items-center">
          <h1
            class="font-semibold text-gray-700 tracking-wider uppercase text-2xl sm:text-3xl"
          >
            Update Information
          </h1>
          <app-rss-outline-icon svgClass="h-6 w-6"></app-rss-outline-icon>
        </div>
        <div class="w-10 border border-blue-500 m-1"></div>
      </div>
    </div>
  </div>

  <div class="flex justify-center">
    <div class="max-w-screen-xl w-full mx-2 md:mx-5">
      <div class="px-4 py-2 text-sm sm:text-normal">
        <p>
          In order to use an RSS feed, use a browser with Internet Explorer 8 or
          later. For any other browser, install RSS Feed reader software before
          use.
        </p>
      </div>
    </div>
  </div>

  <div class="flex justify-center mb-20">
    <div class="max-w-screen-xl w-full mx-2 md:mx-5">
      <ul class="divide-y divide-gray-100">
        <app-update-info-item
          *ngFor="let item of (updateInfo$ | async)?.update_history"
          [item]="item"
        ></app-update-info-item>
      </ul>

      <mat-paginator
        (page)="handlePageEvent($event)"
        [length]="(updateInfo$ | async)?.total"
        [pageSize]="(updateInfo$ | async)?.per_page"
        [pageIndex]="minus((updateInfo$ | async)?.current_page)"
      >
      </mat-paginator>
    </div>
  </div>
</main>

<lib-footer></lib-footer>
