import {
  Component,
  HostListener,
  Inject,
  Injector,
  LOCALE_ID,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router, ActivatedRoute, Scroll, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';
import { Observable } from 'rxjs';

import { Dialog, DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';

import { Notify, NotifyService } from '../shared/notify.service';
import { DocumentBasic, ManualTocService } from './manual-toc.service';
import { Bookmark, BookmarkService } from '../shared/bookmark.service';

import { ManualInfoComponent } from './manual-info/manual-info.component';
import { ConfimationDialogComponent } from '../layout/confimation-dialog/confimation-dialog.component';

import { environment } from '../../environments/environment';

export interface Manual {
  docType: string;
  docId: string;
}

const getPageInfo = (toc: any[], current_url: string) => {
  const index = toc!.findIndex(({ url }) => url === current_url);
  const prevPage = 0 < index ? toc![index - 1] : null;
  const currentPage = toc![index];
  const nextPage = index < toc!.length - 1 ? toc![index + 1] : null;

  return {
    prev: prevPage,
    current: currentPage,
    next: nextPage,
  };
};

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss'],
})
export class ManualComponent implements OnInit {
  /**
   * 情報パネル・コンテナ
   */
  @ViewChild('infoPanelContainer', { read: ViewContainerRef })
  public infoPanelContainer!: ViewContainerRef;

  /**
   * サイドバーが開いている状態であるか
   */
  public isSidebarOpen = false;

  /**
   * ドキュメントの種類と記号
   */
  public manual!: Manual;

  /**
   * ページId
   */
  public pageId = '';

  /**
   * フィルタ
   */
  public filter = '';

  /**
   * 目次(リスト)
   */
  public toc_array: string[] | null = null;

  /**
   * 前のページ
   */
  public previousPage: any = null;

  /**
   * 次のページ
   */
  public nextPage: any = null;

  public locales: { [key: string]: string } = {
    en: 'ENG',
    ja: 'JPN',
    'zh-CN': 'CHI',
    'zh-TW': 'CTW',
    fr: 'FRE',
    es: 'SPA',
    de: 'GER',
    it: 'ITA',
    cs: 'CZE',
    sk: 'SLK',
    hu: 'HUN',
    ru: 'RUS',
    nl: 'DUT',
    sv: 'SWE',
    fi: 'FIN',
    no: 'NOR',
    pl: 'POL',
    da: 'DAN',
    lt: 'LIT',
    lv: 'LAV',
    et: 'EST',
    el: 'GRE',
    sl: 'SLV',
    ro: 'ROM',
    bg: 'BUL',
    tr: 'TUR',
    sr: 'SRP',
    hr: 'HRV',
    pt: 'POR',
    ko: 'KOR',
    th: 'THA',
    id: 'IND',
  };

  /**
   * 通知オブジェクト
   */
  public notify: Notify;

  /**
   * ウィンドウのスクロールの状態
   */
  public windowScrolled = false;

  /**
   * スクロールトップ オフセット
   */
  public scrollTopOffset = 0;

  /**
   * ロケールid
   */
  public locale!: string;

  /**
   * ドキュメントの基本情報 (Observable)
   */
  public docBasic$?: Observable<DocumentBasic>;

  /**
   * ドキュメントの基本情報
   */
  public docBasic!: DocumentBasic;

  /**
   * ブックマーク済みか否か
   */
  public isBookmarked = false;

  /**
   * ブックマーク
   */
  public bookmarks$?: Observable<Array<Bookmark>>;

  /**
   * コンストラクター
   * @param route Router
   * @param activateRoute ActivateRoute
   * @param manualTocService ManualTocService
   */
  constructor(
    private injector: Injector,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private viewportScroller: ViewportScroller,
    @Inject('SCROLL_OFFSET_MOBILE') private scrollOffsetMobile: number,
    @Inject('SCROLL_OFFSET_DESKTOP') private scrollOffsetDesktop: number,
    private manualTocService: ManualTocService,
    private bookmarkService: BookmarkService,
    private notifyService: NotifyService,
    private dialog: Dialog
  ) {
    this.locale = this.injector.get(LOCALE_ID);

    this.notify = this.notifyService.getNotify();

    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.manual = {
          docType: this.activateRoute.snapshot.pathFromRoot[1].url[0].path,
          docId: this.activateRoute.snapshot.params.docId,
        };
        if (this.manual.docId) {
          const pageId = this.activateRoute.snapshot.firstChild?.params.pageId;
          if (pageId) {
            this.pageId = pageId;
            this.title.setTitle(
              this.activateRoute.snapshot.firstChild?.data.title
                ? `${this.activateRoute.snapshot.firstChild?.data.title} | Manuals & Technical Documents`
                : `${this.manual.docId.toUpperCase()} | Manuals & Technical Documents`
            );
            this.meta.addTag({
              name: 'description',
              content: `${this.activateRoute.snapshot.firstChild?.data.description}`,
            });

            if (this.toc_array !== null) {
              const temp = getPageInfo(this.toc_array!, this.pageId);

              this.previousPage = temp.prev;
              this.nextPage = temp.next;
            }
          } else {
            this.pageId = '';
            if (this.activateRoute.snapshot.data.page.contentType === 'pdf') {
              const currentLocale = this.injector.get(LOCALE_ID);
              const locale = this.locales[currentLocale];
              window.location.href = `/${currentLocale}/pdfs/${
                this.manual.docType
              }/${this.manual.docId.toUpperCase()}/${this.manual.docType.toUpperCase()}-${this.manual.docId.toUpperCase()}.pdf`;
            }
            this.title.setTitle(
              this.activateRoute.snapshot.firstChild?.data.title
                ? `${this.activateRoute.snapshot.firstChild?.data.title} | Manuals & Technical Documents`
                : `${this.manual.docId.toUpperCase()} | Manuals & Technical Documents`
            );
            this.meta.addTag({
              name: 'description',
              content: `${this.activateRoute.snapshot.data.description}`,
            });
          }
        }
      } else if (e instanceof Scroll) {
        if (e instanceof Scroll) {
          if (e.anchor !== null) {
            setTimeout(() => {
              const anchor = e.anchor !== null ? e.anchor : '';
              this.viewportScroller.scrollToAnchor(anchor);
            }, 0);
          }
        }
      }
    });

    // クエリパラメータの変更を検知する
    activateRoute.queryParams.subscribe(async (val) => {
      if (environment.apiServer.auth) {
        await this.manualTocService.init();
      }

      if (this.activateRoute.snapshot.queryParams.q) {
        this.filter = this.activateRoute.snapshot.queryParams.q;
        this.manualTocService.getTocWidthKeywords(this.manual, this.filter);
      } else if (this.activateRoute.snapshot.firstChild?.queryParams.q) {
        this.filter = this.activateRoute.snapshot.firstChild?.queryParams.q;
        this.manualTocService.getTocWidthKeywords(this.manual, this.filter);
      } else {
        this.filter = '';
        this.manualTocService.getToc(this.manual);
      }
    });
  }

  /**
   * ngOnInit
   */
  async ngOnInit(): Promise<void> {
    if (environment.apiServer.auth) {
      await this.manualTocService.init();
    }

    this.setViewportScrollerOffset();

    this.manual = {
      docType: this.activateRoute.snapshot.pathFromRoot[1].url[0].path,
      docId: this.activateRoute.snapshot.params.docId,
    };

    if (this.manual.docId) {
      const pageId = this.activateRoute.snapshot.firstChild?.params.pageId;
      if (pageId) {
        this.pageId = pageId;
      }
    }

    // 初期化時のデータ取得
    if (this.activateRoute.snapshot.queryParams.q) {
      this.filter = this.activateRoute.snapshot.queryParams.q;
      this.manualTocService.getTocWidthKeywords(this.manual, this.filter);
    } else if (this.activateRoute.snapshot.firstChild?.queryParams.q) {
      this.filter = this.activateRoute.snapshot.firstChild?.queryParams.q;
      this.manualTocService.getTocWidthKeywords(this.manual, this.filter);
    } else {
      this.manualTocService.getToc(this.manual);
    }

    this.docBasic$ = this.manualTocService.docBasicChanges;
    this.docBasic$.subscribe((docBasic) => {
      this.docBasic = docBasic;

      this.toc_array = docBasic.toc_array;
      const temp = getPageInfo(this.toc_array, this.pageId);

      this.previousPage = temp.prev;
      this.nextPage = temp.next;
    });

    this.bookmarks$ = this.bookmarkService.bookmarksChanges;
    this.bookmarks$.subscribe((bookmarks) => {
      const index = bookmarks.findIndex(
        (bookmark) =>
          bookmark.locale === this.locale &&
          bookmark.doc_no === this.manual.docId &&
          bookmark.doc_type === this.manual.docType.toUpperCase()
      );

      if (index === undefined || index === -1) {
        this.isBookmarked = false;
      } else {
        this.isBookmarked = true;
      }
    });
  }

  /**
   * ウィンドウのリサイズでViewportScrollerのオフセットを変更する
   */
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setViewportScrollerOffset();
  }

  /**
   * (HostListner) ウィンドウスクロール
   * @param $event any
   */
  @HostListener('window:scroll', ['$event'])
  onScroll($event: any): void {
    if (
      document.body.scrollTop > this.scrollTopOffset ||
      document.documentElement.scrollTop > this.scrollTopOffset
    ) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  /**
   * ViewportScroller の オフセット を変更する
   */
  private setViewportScrollerOffset(): void {
    const viewportWidth = this.width;
    if (viewportWidth <= 760) {
      if (!this.notify.show) {
        this.viewportScroller.setOffset([0, this.scrollOffsetMobile]);
        this.scrollTopOffset = this.scrollOffsetMobile;
      } else {
        this.viewportScroller.setOffset([0, this.scrollOffsetMobile + 40]);
        this.scrollTopOffset = this.scrollOffsetMobile + 40;
      }
    } else {
      if (!this.notify.show) {
        this.viewportScroller.setOffset([0, this.scrollOffsetDesktop]);
        this.scrollTopOffset = this.scrollOffsetDesktop;
      } else {
        this.viewportScroller.setOffset([0, this.scrollOffsetDesktop + 40]);
        this.scrollTopOffset = this.scrollOffsetDesktop + 40;
      }
    }
  }

  /**
   * スマートフォン用サイドバーを開く
   */
  public openSidebar(): void {
    this.isSidebarOpen = true;
  }

  /**
   * スマートフォン用サイドバーを閉じる
   */
  public closeSidebar(): void {
    this.isSidebarOpen = false;
  }

  /**
   * ブックマークを反転させる
   */
  public toggleBookmark(): void {
    if (this.isBookmarked) {
      const dialogRef = this.dialog.open<string>(ConfimationDialogComponent, {
        width: '250px',
        data: {
          title: 'Confirm',
          message: 'Are you sure want to delete the bookmark from list?',
        },
        autoFocus: '__non_existing_element__',
      });

      dialogRef.closed.subscribe((result) => {
        if (result) {
          this.bookmarkService.remove(
            this.locale,
            this.manual.docId,
            this.manual.docType.toUpperCase()
          );
        }
      });

      this.isBookmarked = !this.isBookmarked;
    } else {
      var now = new Date();
      this.bookmarkService.add({
        locale: this.locale,
        doc_type: this.manual.docType.toUpperCase(),
        doc_no: this.manual.docId,
        content_type: 'web',
        bookmarked_date: now.toISOString().split('T')[0],
        pdf_path: this.docBasic.pdf_file_path,
        name: this.docBasic.name,
      });
    }
  }

  /**
   * インフォメーションパネルを開く
   */
  public openInfoPanel(): void {
    this.infoPanelContainer.createComponent(ManualInfoComponent);
  }

  /**
   * ページ印刷を行う
   */
  public printPage(): void {
    window.print();
  }

  /**
   * PDFを表示する
   */
  public viewPdf(): void {
    const currentLocale = this.injector.get(LOCALE_ID);
    window.open(
      `/${currentLocale}/pdfs/${
        this.manual.docType
      }/${this.manual.docId.toUpperCase()}/${this.docBasic.pdf_file_path}`,
      '_blank'
    );
  }

  /**
   * 通知表示を停止する
   */
  closeNotify(): void {
    this.notify = this.notifyService.setNotifyDisable();
    this.setViewportScrollerOffset();
  }

  /**
   * ページトップにスクロール移動する
   */
  onClickBackToTop(): void {
    let offset = 0;

    window.scrollTo({
      top: offset,
      left: 0,
      behavior: 'smooth',
    });
  }

  /**
   * 前へのボタンクリックで前のページに遷移させる
   */
  public onClickPrevious(): void {}

  /**
   * 次へのボタンクリックで次のページに遷移させる
   */
  public onClickNext(): void {}

  public get width() {
    return window.innerWidth;
  }
}
