import { Component } from '@angular/core';

import { BaseSolidIconComponent } from '../base-solid-icon.component';

@Component({
  selector: 'app-search-solid-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM2 8a6 6 0 1 1 10.89 3.476l4.817 4.817a1 1 0 0 1-1.414 1.414l-4.816-4.816A6 6 0 0 1 2 8z"
      clip-rule="evenodd"
    />
  </svg>`,
})
export class SearchSolidIconComponent extends BaseSolidIconComponent {}
