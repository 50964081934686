import { DocsComponent } from '../app/docs/docs.component';
import { SearchComponent } from '../app/search/search.component';
import { ManualComponent } from '../app/manual/manual.component';
import { ExplodedViewComponent } from '../app/exploded-view/exploded-view.component';
import { ErrorComponent } from '../app/error/error.component';
import { ComplianceTopComponent } from '../app/compliance/compliance-top/compliance-top.component';
import { CompliancePmiComponent } from '../app/compliance/compliance-pmi/compliance-pmi.component';
import { UpdateInfoComponent } from '../app/update-info/update-info.component';

import { NotFoundComponent } from '../app/shared/not-found/not-found.component';

import { Routes } from '@angular/router';
import { PageResolver } from '../app/shared/page.resolver';
import { RedirectResolver } from '../app/shared/redirect.resolver';

export const routes: Routes = [
  {
    path: '',
    component: DocsComponent,
    resolve: {
      page: RedirectResolver,
    },
  },
  {
    path: 'manual',
    component: DocsComponent,
    data: { page: { breadcrumb: 'Manuals' } },
  },
  {
    path: 'manual',
    data: { page: { breadcrumb: 'Manuals' } },
    children: [
      {
        path: 'search',
        component: SearchComponent,
        data: { page: { breadcrumb: 'Search' } },
      },
      {
        path: 'update-info',
        component: UpdateInfoComponent,
        data: { page: { breadcrumb: 'Update Information' } },
      },
    ],
  },
  {
    path: 'dm',
    data: { page: { breadcrumb: 'Manuals', altUrl: 'manual' } },
    children: [
      {
        path: ':docId',
        component: ManualComponent,
        resolve: {
          page: PageResolver,
        },
        children: [
          {
            path: ':pageId',
            component: ManualComponent,
            resolve: {
              page: PageResolver,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'um',
    data: { page: { breadcrumb: 'Manuals', altUrl: 'manual' } },
    children: [
      {
        path: ':docId',
        component: ManualComponent,
        resolve: {
          page: PageResolver,
        },
        children: [
          {
            path: ':pageId',
            component: ManualComponent,
            resolve: {
              page: PageResolver,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'si',
    data: { page: { breadcrumb: 'Manuals', altUrl: 'manual' } },
    children: [
      {
        path: ':docId',
        component: ManualComponent,
        resolve: {
          page: PageResolver,
        },
        children: [
          {
            path: ':pageId',
            component: ManualComponent,
            resolve: {
              page: PageResolver,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'ev',
    data: { page: { breadcrumb: 'Manuals', altUrl: 'manual' } },
    children: [
      {
        path: ':docId',
        component: ExplodedViewComponent,
        resolve: {
          page: PageResolver,
        },
      },
    ],
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: { page: { breadcrumb: 'Error & Warning' } },
  },
  {
    path: 'compliance',
    component: ComplianceTopComponent,
    data: { page: { breadcrumb: 'Compliance' } },
  },
  {
    path: 'compliance-search',
    data: { page: { breadcrumb: 'Compliance' } },
    children: [
      {
        path: 'pmi',
        component: CompliancePmiComponent,
        data: { page: { breadcrumb: 'Package Material Information' } },
      },
    ],
  },
  { path: 'NotFound', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];
