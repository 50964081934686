import { Component, Input } from '@angular/core';

import { Error } from '../error-set.interface';

@Component({
  selector: 'app-error-collection',
  templateUrl: './error-collection.component.html',
  styleUrls: ['./error-collection.component.scss']
})
export class ErrorCollectionComponent {

  /**
   * アコーディオンに表示するエラーコードデータを指定する
   */
  @Input() errors?: Error[];

}
