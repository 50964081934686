<!-- Start header -->
<div id="top-of-page"></div>
<lib-nav
  (openSidebar)="openSidebar()"
  [notify]="notify"
  (closeNotify)="closeNotify()"
>
</lib-nav>
<!-- End header -->

<div class="flex min-h-screen justify-center overflow-hidden">
  <div
    class="w-screen max-w-md px-4 sm:w-screen sm:max-w-3xl sm:px-6 lg:max-w-7xl"
  >
    <h1
      class="text-2xl font-semibold uppercase tracking-wider text-gray-700 sm:text-3xl"
    >
      Informazioni sull'imballaggio
    </h1>
    <p class="mt-2">
      Le informazioni sull’imballaggio qui pubblicate possono essere modificate
      senza preavviso.Verifica le disposizioni del tuo Comune per la gestione
      dei rifiuti.
    </p>

    <div class="mt-8 flex flex-col items-center justify-center sm:w-96">
      <!-- search -->
      <div class="mx-auto flex w-full flex-col sm:mx-12 sm:max-w-lg">
        <div class="relative flex h-11 w-full text-gray-400">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2"
          >
            <app-search-solid-icon size="24"></app-search-solid-icon>
          </div>
          <input
            #filterRef
            id="ean_field"
            class="block h-full w-full rounded-sm border border-gray-300 pl-10 pr-3 leading-6 text-gray-900 placeholder-gray-500 focus-within:text-gray-700 focus:border-blue-500 focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
            placeholder="Ricerca per codice EAN (13 cifre)"
            type="text"
            inputmode="search"
            name="model"
            (keyup.enter)="onClickFilter($event)"
            autocapitalize="none"
          />
          <span
            #guideRef
            (mouseover)="onClickGuide($event)"
            (click)="onClickGuide($event)"
            class="hidden items-center md:inline-flex"
          >
            <p class="flex-shrink-0 pl-2 text-sm text-gray-700">codice EAN</p>
            <app-question-mark-circle-outline-icon
              size="20"
            ></app-question-mark-circle-outline-icon>
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="(pmi$ | async) === null" class="my-4 max-w-md md:hidden">
      <img src="/images/compliance/pmi/IMG_2983.jpg" />
    </div>

    <div
      *ngIf="(pmi$ | async) && (pmi$ | async).status !== 'not_found'"
      class="my-7 md:my-10"
    >
      <table class="mt-2 divide-y divide-gray-300 border border-gray-300">
        <thead>
          <tr class="table-row flex-col">
            <th
              class="border-gray-300 px-3 py-3 text-left text-sm font-semibold uppercase tracking-wide text-gray-700 sm:border"
            >
              prodotto
            </th>
            <th
              class="border-gray-300 px-3 py-3 text-left text-sm font-semibold uppercase tracking-wide text-gray-700 sm:border"
            >
              CODICE PRODOTTO
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr class="table-row flex-col even:bg-gray-50">
            <td
              class="whitespace-nowrap border-gray-300 px-3 py-4 text-sm text-gray-500 sm:border"
            >
              {{ (pmi$ | async).product }}
            </td>
            <td
              class="whitespace-nowrap border-gray-300 px-3 py-4 text-sm text-gray-500 sm:border"
            >
              {{ (pmi$ | async).product_code }}
            </td>
          </tr>
        </tbody>
      </table>

      <h2 class="mt-2 p-2 text-sm font-semibold tracking-wide text-gray-700">
        Materiale da imballaggio
      </h2>
      <div class="overflow-x-auto border-none">
        <table
          class="mt-2 inline-block divide-y divide-gray-300 border border-gray-300 sm:border-none"
        >
          <thead>
            <tr class="table-row flex-col">
              <th
                class="border-gray-300 px-3 py-3 text-left text-sm font-semibold uppercase tracking-wide text-gray-700 sm:border"
              >
                No
              </th>
              <th
                class="border-gray-300 px-3 py-3 text-left text-sm font-semibold uppercase tracking-wide text-gray-700 sm:border"
              >
                Tipologia di imballaggio
              </th>
              <th
                class="border-gray-300 px-3 py-3 text-left text-sm font-semibold uppercase tracking-wide text-gray-700 sm:border"
              >
                Codifica alfanumerica
              </th>
              <th
                class="border-gray-300 px-3 py-3 text-left text-sm font-semibold uppercase tracking-wide text-gray-700 sm:border"
              >
                indicazioni per la raccolta
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr
              *ngFor="let item of (pmi$ | async).material_infos; index as i"
              class="table-row flex-col even:bg-gray-100"
            >
              <td
                class="whitespace-nowrap border-gray-300 px-3 py-4 text-sm text-gray-500 sm:border"
              >
                {{ i + 1 }}
              </td>
              <td
                class="whitespace-nowrap border-gray-300 px-3 py-4 text-sm text-gray-500 sm:border"
              >
                {{ item.packaging_type }}
              </td>
              <td
                class="whitespace-nowrap border-gray-300 px-3 py-4 text-sm text-gray-500 sm:border"
              >
                {{ item.material_code }}
              </td>
              <td
                class="whitespace-nowrap border-gray-300 px-3 py-4 text-sm text-gray-500 sm:border"
              >
                {{ item.sorting_instruction }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      *ngIf="(pmi$ | async) && (pmi$ | async).status === 'not_found'"
      class="my-7 md:my-10"
    >
      No results found.
    </div>
  </div>
</div>

<app-compliance-guide
  *ngIf="showGuide"
  [left]="guideRect.left"
  [top]="guideRect.top"
></app-compliance-guide>

<lib-footer></lib-footer>
