<!-- Start header -->
<div id="top-of-page"></div>
<lib-nav
  (openSidebar)="openSidebar()"
  [notify]="notify"
  (closeNotify)="closeNotify()"
>
</lib-nav>
<!-- End header -->

<main>
  <div class="flex justify-center">
    <div class="mx-2 w-full max-w-screen-xl pt-2 sm:pt-0 md:mx-5">
      <div class="relative flex flex-col px-4 text-left sm:px-4">
        <h1
          class="text-2xl font-semibold uppercase tracking-wider text-gray-700 sm:text-3xl"
          i18n
        >
          Error & Warning Code
        </h1>
        <div class="m-1 w-10 border border-blue-500"></div>
      </div>
    </div>
  </div>

  <div class="flex justify-center">
    <div class="mx-2 w-full max-w-screen-xl md:mx-5">
      <!-- Expand/collapse button -->
      <button
        type="button"
        (click)="toggleInfoStart()"
        class="flex h-8 items-center py-1 text-left text-blue-500 focus:border-transparent focus:outline-none"
      >
        <span class="ml-6 flex h-7 items-center">
          <app-chevron-down-outline-icon
            size="24"
            class="transition duration-300 ease-in-out"
            [ngClass]="{
              'rotate-180 transform': isInfoStartOpen,
              '': !isInfoStartOpen
            }"
            svgClass="h-6 w-6"
          ></app-chevron-down-outline-icon>
        </span>
        <h3
          class="px-1 text-xl font-medium tracking-wider text-bluemine-500"
          i18n
        >
          Introduction
        </h3>
      </button>
      <p
        *ngIf="isInfoStartOpen"
        class="prose prose-sm max-w-none border-t border-b bg-gray-50 py-3 px-5 sm:prose sm:max-w-none lg:px-8"
        [innerHTML]="infoStart | sanitizeHtml"
      ></p>
    </div>
  </div>

  <div class="flex justify-center">
    <div class="mx-2 w-full max-w-screen-xl md:mx-5">
      <div class="sm:text-normal px-4 py-1 text-sm">
        <ul
          class="grid list-inside gap-1 py-2 md:grid-flow-row md:grid-cols-2 lg:grid-cols-3"
        >
          <ng-container *ngFor="let item of errorSets$ | async; index as i">
            <li *ngIf="i < 4 || more" class="list-disc text-blue-500">
              <a
                [routerLink]="['/error']"
                [queryParams]="{ q: filter }"
                fragment="{{ item.id }}"
                [innerHTML]="item.name | sanitizeHtml"
              >
              </a>
            </li>
          </ng-container>
          <li
            *ngIf="(errorSets$ | async)?.length > 5"
            class="flex list-inside pl-5"
          >
            <a (click)="more = !more" href="javascript:void(0)">
              <p class="flex items-center">
                {{ more ? show_less : show_more }}
                <app-chevron-down-outline-icon
                  [ngClass]="{
                    'rotate-180 transform': more,
                    '': !more
                  }"
                  class="transition duration-300 ease-in-out"
                  svgClass="flex-shrink-0
                h-3
                w-3"
                ></app-chevron-down-outline-icon>
              </p>
            </a>
          </li>
        </ul>
      </div>

      <div class="my-2 px-4">
        <label for="filter_field" class="sr-only">Filter</label>
        <div
          class="relative w-full text-black focus-within:text-gray-700 md:w-1/2 lg:w-1/3"
        >
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2"
          >
            <app-search-solid-icon size="24"></app-search-solid-icon>
          </div>
          <input
            #filterRef
            id="filter_field"
            class="h-full border border-gray-300 py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 opacity-70 transition duration-500 ease-in-out focus:w-full focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-bluemine-500 sm:text-sm"
            [ngClass]="{
              'w-full': filter !== undefined,
              'w-32': filter === undefined
            }"
            placeholder="Search"
            type="text"
            name="filter"
            (keyup.enter)="onClickFilter($event)"
            autocapitalize="none"
            i18n-placeholder
          />
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center">
    <div class="mx-2 w-full max-w-screen-xl md:mx-5">
      <div
        class="lg:grid lg:grid-cols-3 lg:gap-8"
        *ngFor="let item of errorSets$ | async"
      >
        <div class="flex justify-center p-3 lg:p-6" [attr.id]="item.id">
          <div>
            <h3
              class="px-1 text-xl font-medium tracking-wider text-bluemine-500"
              [innerHTML]="item.name | sanitizeHtml"
            ></h3>
          </div>
        </div>

        <div class="lg:col-span-2 lg:mt-0">
          <app-error-collection [errors]="item.errors"></app-error-collection>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-20 flex justify-center">
    <div class="mx-2 w-full max-w-screen-xl md:mx-5">
      <p
        class="p-3 text-sm text-gray-500 lg:p-6"
        [innerHTML]="infoEnd | sanitizeHtml"
      ></p>
    </div>
  </div>
</main>

<lib-footer></lib-footer>
