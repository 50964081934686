import { Component, OnInit, Input } from '@angular/core';
import { ResultItemService, BikeType } from '../../search/result-item.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bike-type-filter-item',
  templateUrl: './bike-type-filter-item.component.html',
  styleUrls: ['./bike-type-filter-item.component.scss'],
})
export class BikeTypeFilterItemComponent implements OnInit {
  @Input() bikeType!: BikeType;

  /**
   * 自転車タイプ・オブザーバブル
   */
  public bikeTypes$: Observable<any>;

  public isSelected = false;

  constructor(private ResultItemService: ResultItemService) {
    this.bikeTypes$ = this.ResultItemService.bikeTypesChanges;
    this.bikeTypes$.subscribe((bikeTypes) => {
      const index = bikeTypes.indexOf(this.bikeType);
      if (index !== -1) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }
    });
  }

  ngOnInit(): void {}

  onClick(bikeType: BikeType): void {
    this.isSelected = !this.isSelected;
    if (this.isSelected) {
      this.ResultItemService.setFilter(bikeType);
    }
    if (!this.isSelected) {
      this.ResultItemService.removeFilter(bikeType);
    }
  }
}
