import { NgModule, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';

import { CommonModule } from '@angular/common';
import { TocComponent } from './toc/toc.component';


@NgModule({
    declarations: [
        TocComponent
    ],
    imports: [
        CommonModule
    ]
})
export class CustomElementsModule {
  constructor(private injector: Injector) {
    const inpageToc = createCustomElement(TocComponent, { injector });
    customElements.define('inpage-toc', inpageToc);
  }
}
