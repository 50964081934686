<cdk-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="flex flex-col items-start float-left"
>
  <!-- This is the tree node template for leaf nodes -->
  <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="example-tree-node">
    <span class="flex flex-nowrap pt-1 pb-0">
      <span class="inline-block w-4 flex-shrink-0"> </span>
      <span
        class="inline-block align-middle text-sm text-left break-words antialiased text-gray-700 hover:text-blue-900"
        >{{ node.name }}
      </span>
    </span>
  </cdk-nested-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="pb-3">
    <button
      type="button"
      [attr.aria-label]="'Toggle ' + node.name"
      class="flex items-center w-full text-sm hover:text-blue-900 flex-nowrap py-1"
      cdkTreeNodeToggle
    >
      <span>
        <app-chevron-right-outline-icon
          class="flex justify-center transition duration-300 ease-in-out"
          [ngClass]="{
            '': treeControl.isExpanded(node),
            'transform rotate-90': !treeControl.isExpanded(node)
          }"
          svgClass="h-4 w-4"
        ></app-chevron-right-outline-icon>
      </span>
      <span
        class="inline-block align-middle text-left break-all antialiased text-base text-gray-900 hover:text-blue-900 pl-1.5"
        >{{ node.name }}</span
      >
    </button>

    <div *ngIf="!treeControl.isExpanded(node)">
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>
</cdk-tree>
