import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  LOCALE_ID,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { Dialog, DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';

import { ExplodedView } from './exploded-view.interface';
import { SelectItemService } from './select-item.service';
import { ContentService } from './content.service';

import { Notify, NotifyService } from '../shared/notify.service';

import { Bookmark, BookmarkService } from '../shared/bookmark.service';
import { ConfimationDialogComponent } from '../layout/confimation-dialog/confimation-dialog.component';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-exploded-view',
  templateUrl: './exploded-view.component.html',
  styleUrls: ['./exploded-view.component.scss'],
})
export class ExplodedViewComponent implements OnInit, AfterViewInit {
  /**
   * 展開図・オブザーバ
   */
  public explodedView$?: Observable<ExplodedView>;

  /**
   * boms
   */
  public boms$?: Observable<any>;

  /**
   * ドキュメントの記号 (route parameter)
   */
  public docId!: string;

  /**
   * 要求されたフィルタのキーワードをセットする (query parameter)
   */
  public filter!: string;

  /**
   * フィルタ要素に対する参照 (ViewChild)
   */
  @ViewChild('filterRef1') filterRef1!: ElementRef;

  /**
   * フィルタ要素に対する参照 (ViewChild)
   */
  @ViewChild('filterRef2') filterRef2!: ElementRef;

  /**
   * 展開図画像・コンテナ
   */
  @ViewChild('explodedViewImageContainer')
  explodedViewImageContainer!: ElementRef;

  /**
   * BoM・コンテナ (モバイル)
   */
  @ViewChild('explodedViewBomMobileContainer')
  explodedViewBomMobileContainer!: ElementRef;

  /**
   * BoM・コンテナ (デスクトップ)
   */
  @ViewChild('explodedViewBomDesktopContainer')
  explodedViewBomDesktopContainer!: ElementRef;

  /**
   * 展開図画像の幅
   */
  public imageWidth = 0;

  /**
   * 展開図画像の高さ
   */
  public imageHeight = 0;

  /**
   * サイドバーが開いている状態であるか
   */
  public isSidebarOpen = false;

  /**
   * パネルが開いている状態であるか
   */
  public isPanelOpen = false;

  public imageSize!: { width: number; height: number };

  public viewBoxValue = '0 0 1000 1000';

  /**
   * ロケールid
   */
  public locale!: string;

  public locales: { [key: string]: string } = {
    en: 'ENG',
    ja: 'JPN',
    'zh-CN': 'CHI',
    'zh-TW': 'CTW',
    fr: 'FRE',
    es: 'SPA',
    de: 'GER',
    it: 'ITA',
    cs: 'CZE',
    sk: 'SLK',
    hu: 'HUN',
    ru: 'RUS',
    nl: 'DUT',
    sv: 'SWE',
    fi: 'FIN',
    no: 'NOR',
    pl: 'POL',
    da: 'DAN',
    lt: 'LIT',
    lv: 'LAV',
    et: 'EST',
    el: 'GRE',
    sl: 'SLV',
    ro: 'ROM',
    bg: 'BUL',
    tr: 'TUR',
    sr: 'SRP',
    hr: 'HRV',
    pt: 'POR',
    ko: 'KOR',
    th: 'THA',
    id: 'IND',
  };

  /**
   * 通知オブジェクト
   */
  public notify: Notify;

  /**
   * ブックマーク済みか否か
   */
  public isBookmarked = false;

  /**
   * ブックマーク
   */
  public bookmarks$?: Observable<Array<Bookmark>>;

  /**
   * 選択アイテムリスト
   */
  public selectItemIndex$?: Observable<boolean[]>;

  /**
   * コンストラクター
   * @param contentService EVデータ取得サービス
   * @param selectItemService 選択情報サービス
   */
  constructor(
    private title: Title,
    private injector: Injector,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private contentService: ContentService,
    private selectItemService: SelectItemService,
    private bookmarkService: BookmarkService,
    private notifyService: NotifyService,
    private dialog: Dialog
  ) {
    this.locale = this.injector.get(LOCALE_ID);

    this.notify = this.notifyService.getNotify();

    this.explodedView$ = this.contentService.valueChanges;
    this.boms$ = this.contentService.bomsValueChanges;
  }

  /**
   * ngOnInit
   */
  async ngOnInit(): Promise<void> {
    //    this.contentService.getEvContent(this.activateRoute.snapshot.params.docId);

    if (environment.apiServer.auth) {
      await this.contentService.init();
    }

    this.activateRoute.queryParams.subscribe((val) => {
      if (this.activateRoute.snapshot.params.docId) {
        this.docId = this.activateRoute.snapshot.params.docId;

        if (this.docId) {
          if (this.activateRoute.snapshot.data.page.contentType === 'pdf') {
            window.location.href = `/pdfs/ev/${this.docId.toUpperCase()}/EV-${this.docId.toUpperCase()}.pdf`;
          }

          this.title.setTitle(
            `${this.docId.toUpperCase()} | Manuals & Technical Documents`
          );
        }

        if (this.activateRoute.snapshot.queryParams.q) {
          this.filter = this.activateRoute.snapshot.queryParams.q;
          this.contentService.getEvContentWithKeywords(this.docId, this.filter);
        } else {
          this.contentService.getEvContent(this.docId);
        }
      }
    });

    this.bookmarks$ = this.bookmarkService.bookmarksChanges;
    this.bookmarks$.subscribe((bookmarks) => {
      const index = bookmarks.findIndex(
        (bookmark) =>
          bookmark.locale === this.locale &&
          bookmark.doc_no === this.docId &&
          bookmark.doc_type === 'EV'
      );

      if (index === undefined || index === -1) {
        this.isBookmarked = false;
      } else {
        this.isBookmarked = true;
      }
    });

    this.selectItemIndex$ = this.selectItemService.valueChanges;
    this.selectItemIndex$.subscribe((itemIndexList) => {
      this.contentService.notifySelectItemIndex(itemIndexList);

      if (this.explodedViewBomMobileContainer?.nativeElement)
        this.explodedViewBomMobileContainer?.nativeElement.scrollTo(0, 0);

      if (this.explodedViewBomDesktopContainer?.nativeElement)
        this.explodedViewBomDesktopContainer?.nativeElement.scrollTo(0, 0);
    });
  }

  /**
   * ngAfterViewInit
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.filter && this.filterRef1) {
        this.filterRef1.nativeElement.value = this.filter.replace(/,/g, ' ');
      }

      if (this.filter && this.filterRef2) {
        this.filterRef2.nativeElement.value = this.filter.replace(/,/g, ' ');
      }
    }, 300);
  }

  /**
   * EV画像読み込み処理
   * ウィンドウ幅に影響を受けない、元画像の縦、横幅を取得し、
   * SelectItemServiceへ保存
   */
  onImageLoad(): void {
    const image = new Image();
    image.src =
      this.explodedViewImageContainer.nativeElement.getAttribute('src');

    this.imageSize = image;
    this.viewBoxValue = `0 0 ${this.imageSize.width} ${this.imageSize.height}`;
  }

  /**
   * フィルタの入力でエラーセットを絞り込む
   * @param event イベント
   */
  onClickFilter(event: any): void {
    if (event.target.value) {
      this.router.navigate([`ev/${this.docId}`], {
        queryParams: { q: event.target.value.replace(/\s/g, ',') },
      });
    } else {
      this.filter = '';
      this.router.navigate([`ev/${this.docId}`]);
    }
  }

  /**
   * スマートフォン用サイドバーを開く
   */
  public openSidebar(): void {
    this.isSidebarOpen = true;
  }

  /**
   * スマートフォン用サイドバーを閉じる
   */
  public closeSidebar(): void {
    this.isSidebarOpen = false;
  }

  /**
   * パネルを開く
   */
  public openPanel(): void {
    this.isPanelOpen = true;
  }

  /**
   * パネルを閉じる
   */
  public closePanel(): void {
    this.isPanelOpen = false;
  }

  /**
   * ブックマークを反転させる
   */
  public toggleBookmark(): void {
    if (this.isBookmarked) {
      const dialogRef = this.dialog.open<string>(ConfimationDialogComponent, {
        width: '250px',
        data: {
          title: 'Confirm',
          message: 'Are you sure want to delete the bookmark from list?',
        },
        autoFocus: '__non_existing_element__',
      });

      dialogRef.closed.subscribe((result) => {
        if (result) {
          this.bookmarkService.remove(this.locale, this.docId, 'EV');
        }
      });

      this.isBookmarked = !this.isBookmarked;
    } else {
      var now = new Date();
      this.bookmarkService.add({
        locale: this.locale,
        doc_type: 'EV',
        doc_no: this.docId,
        content_type: 'web',
        bookmarked_date: now.toISOString().split('T')[0],
        pdf_path: `/pdfs/ev/${this.docId}/EV-${this.docId}.pdf`,
        name: '',
      });
    }
  }

  /**
   * 通知表示を停止する
   */
  closeNotify(): void {
    this.notify = this.notifyService.setNotifyDisable();
  }
}
