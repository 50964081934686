import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  NavigationNode,
  DocumentBasic,
  ManualTocService,
} from '../manual-toc.service';
import { Observable } from 'rxjs';

export interface Manual {
  docType: string;
  docId: string;
}

@Component({
  selector: 'app-manual-top',
  templateUrl: './manual-top.component.html',
  styleUrls: ['./manual-top.component.scss'],
})
export class ManualTopComponent implements OnInit {
  /**
   * 現在のレベル
   */
  @Input() manual!: Manual;

  /**
   * フィルタキーワード
   */
  public filter!: string | undefined;

  /**
   * ドキュメントの目次
   */
  public docBasic$?: Observable<DocumentBasic>;

  /**
   * ドキュメントの目次
   */
  public nodes$?: Observable<NavigationNode[]>;

  /**
   * コンストラクタ
   * @param manualTocService ManualTocService
   */
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private manualTocService: ManualTocService
  ) {
    activateRoute.queryParams.subscribe((val) => {
      if (this.activateRoute.snapshot.queryParams.q) {
        this.filter = this.activateRoute.snapshot.queryParams.q;
      } else if (this.activateRoute.snapshot.firstChild?.queryParams.q) {
        this.filter = this.activateRoute.snapshot.firstChild?.queryParams.q;
      } else {
        this.filter = undefined;
      }
    });
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
    this.nodes$ = this.manualTocService.docTocChanges;
    this.docBasic$ = this.manualTocService.docBasicChanges;
  }

  /**
   * ドキュメントカラーをセットする
   * @param docType
   * @returns
   */
  setDocTypeColor(docType: any): string {
    let color: string = '';
    switch (docType) {
      case 'DM':
        color = '#bd362f';
        break;
      case 'UM':
        color = '#51a351';
        break;
      case 'SI':
        color = '#005686';
        break;
    }
    return color;
  }
}
