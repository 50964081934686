import { Component } from '@angular/core';

import { BaseOutlineIconComponent } from '../base-outline-icon.component';

@Component({
  selector: 'app-adjustments-horizon-outline-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
    />
  </svg>`,
})
export class AdjustmentsHorizonOutlineIconComponent extends BaseOutlineIconComponent {}
