import {
  Component,
  Input,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';

import { NavigationNode } from '../manual-toc.service';

export interface NavigationViews {
  [name: string]: NavigationNode[];
}

/**
 *  Navigation information about a node at specific URL
 *  url: the current URL
 *  view: 'SideNav' | 'TopBar' | 'Footer' | etc
 *  nodes: the current node and its ancestor nodes within that view
 */
export interface CurrentNode {
  url: string;
  view: string;
  nodes: NavigationNode[];
}

/**
 * A map of current nodes by view.
 * This is needed because some urls map to nodes in more than one view.
 * If a view does not contain a node that matches the current url then the value will be undefined.
 */
export interface CurrentNodes {
  [view: string]: CurrentNode;
}

export interface Manual {
  docType: string;
  docId: string;
}

@Component({
  selector: 'app-manual-toc-item',
  templateUrl: './manual-toc-item.component.html',
  styleUrls: ['./manual-toc-item.component.scss'],
})
export class ManualTocItemComponent implements OnChanges {
  /**
   * マニュアル情報
   */
  @Input() manual!: Manual;
  /**
   * マニュアル情報
   */
  @Input() pageId!: string;
  /**
   * フィルタキーワード
   */
  @Input() filter!: string | undefined;

  /**
   * 現在のレベル
   */
  @Input() level = 1;
  /**
   * ナビゲーションノード
   */
  @Input() node!: NavigationNode;
  /**
   * 親ノードの開閉
   */
  @Input() isParentExpanded = true;
  /**
   * 選択されているノード (未実装)
   */
  @Input() selectedNodes: NavigationNode[] | undefined;

  /**
   * 目次がクリックされたことを伝える
   */
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  isExpanded = true;
  //  isSelected = false;
  nodeChildren: NavigationNode[] = [];

  /**
   * OnChanges
   */
  ngOnChanges(): void {
    this.nodeChildren =
      this.node && this.node.children ? this.node.children : [];

    /*
    if (this.selectedNodes) {
      const ix = this.selectedNodes.indexOf(this.node);
      this.isSelected = ix !== -1; // this node is the selected node or its ancestor
      this.isExpanded =
        this.isParentExpanded &&
        (this.isSelected || // expand if selected or ...
          // preserve expanded state when display is wide; collapse in mobile.
          this.isExpanded);
    } else {
      this.isSelected = false;
    }*/
  }

  /**
   * 要素クリック時の処理 (コンポーネント限定)
   */
  onClickAnker(): void {
    this.isExpanded = !this.isExpanded;
    this.close.emit(false);
  }

  /**
   * 要素クリック時の処理 (コンポーネント限定)
   */
  onClickButton(): void {
    this.isExpanded = !this.isExpanded;
  }

  emitClose(): void {
    this.close.emit(false);
  }
}
