import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ApiResponse } from '../shared/apiResponse.interface';

import { ErrorSet, ErrorSets, IErrorSetService } from './error-set.interface';

import { fetchAuthSession } from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class ErrorSetService implements IErrorSetService {
  /**
   * エラーセット(基本情報) Subject
   */
  private infoSubject = new ReplaySubject<{
    id: string;
    name: string;
    title: string;
    description: string;
    infoStart: string;
    infoEnd: string;
  }>(1);

  /**
   * エラーセット(コンテンツ) Subject
   */
  private contentSubject = new ReplaySubject<ErrorSet[]>(1);

  /**
   * エラーセット(コンテンツ)
   */
  private errorSets: ErrorSet[] = [];

  /**
   * JWT Token
   */
  private token?: string;

  /**
   * コンストラクタ
   * @param http HttpClient
   */
  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /**
   * サービスの初期化処理を行う
   * @returns Promise<void>
   */
  async init(): Promise<void> {
    if (environment.apiServer.auth) {
      this.token = (await fetchAuthSession()).tokens?.idToken?.toString()
    }
  }

  /**
   * valueChanges
   */
  get valueChanges(): Observable<ErrorSet[]> {
    return this.contentSubject.asObservable();
  }

  /**
   * エラードメインのエラーセットを取得し、基本情報(Observable)を返す
   * @param faqDomainId エラードメインid
   * @returns Observable<{ id: string, name: string, title: string, description: string }>
   */
  getErrorSets(errorDomainId: string): Observable<{
    id: string;
    name: string;
    title: string;
    description: string;
    infoStart: string;
    infoEnd: string;
  }> {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.auth) {
      headers = headers.set('Authorization', `Bearer ${this.token}`);
    }

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    this.http
      .get<ApiResponse<ErrorSets>>(
        `${environment.apiServer.baseUrl}/error/errorsets/${errorDomainId}` +
          (environment.apiServer.local ? '.json' : ''),
        { headers }
      )
      .subscribe((response) => {
        this.errorSets = response.data.categories;
        this.contentSubject.next(this.errorSets);
        this.infoSubject.next({
          id: response.data.id,
          name: response.data.name,
          title: response.data.title,
          description: response.data.description,
          infoStart: response.data.info_start,
          infoEnd: response.data.info_end,
        });
      });
    return this.infoSubject;
  }

  /**
   * キーワードで絞り込まれたfaqドメインのエラーコードセットを取得し、基本情報(Observable)を返す
   * @param errorDomainId faqドメインId
   * @param keyword キーワード
   * @returns Observable<{ id: string, name: string, title: string, description: string }>
   */
  getErrorSetsWithKeywords(
    errorDomainId: string,
    keyword: string
  ): Observable<{
    name: string;
    title: string;
    description: string;
    infoStart: string;
    infoEnd: string;
  }> {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.auth) {
      headers = headers.set('Authorization', `Bearer ${this.token}`);
    }

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    let params = new HttpParams().set('keywords', keyword);

    this.http
      .get<ApiResponse<ErrorSets>>(
        `${environment.apiServer.baseUrl}/error/errorsets/${errorDomainId}` +
          (environment.apiServer.local ? '.json' : '') +
          '/filter',
        { headers, params }
      )
      .subscribe((response) => {
        this.errorSets = response.data.categories;
        this.contentSubject.next(this.errorSets);
        this.infoSubject.next({
          id: response.data.id,
          name: response.data.name,
          title: response.data.title,
          description: response.data.description,
          infoStart: response.data.info_start,
          infoEnd: response.data.info_end,
        });
      });

    return this.infoSubject;
  }

}
