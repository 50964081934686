<nav class="hidden h-full md:flex" aria-label="Breadcrumb">
  <ol class="flex items-center space-x-1">
    <li
      *ngFor="
        let breadcrumb of breadcrumbs$ | async;
        let i = index;
        let first = first;
        let last = last
      "
    >
      <!-- 
      <div *ngIf="first">
        <a
          href="{{ breadcrumb.url }}"
          class="text-blue-500 hover:text-bluemine-500"
        >
          <app-home-solid-icon
            size="20"
            svgClass="flex-shrink-0"
          ></app-home-solid-icon>

          <span class="sr-only">{{ breadcrumb.label }}</span>
        </a>
      </div>
      -->

      <div class="flex items-center pl-0" *ngIf="!first && !last">
        <span *ngIf="i !== 1" class="font-normal text-gray-400"> > </span>
        <a
          href="{{ breadcrumb.altUrl ? breadcrumb.altUrl : breadcrumb.url }}"
          class="ml-1 overflow-ellipsis text-xs font-normal text-gray-400 hover:text-bluemine-500"
        >
          {{ breadcrumb.label }}
        </a>
      </div>

      <div class="flex items-center pl-0" *ngIf="last && !first">
        <span *ngIf="i !== 1" class="font-normal text-blue-700"> > </span>
        <a
          href="{{ breadcrumb.url }}"
          class="ml-1 text-xs font-normal text-blue-700 hover:text-gray-700"
          aria-current="page"
        >
          {{ breadcrumb.label }}
        </a>
      </div>
    </li>
  </ol>
</nav>
