<div [@PageAnimation]="_show ? true : false" class="flex min-w-0">
  <!-- Start main  -->
  <main class="relative z-0 w-screen flex-1 md:w-full lg:w-4/5">
    <div class="main px-3 py-4 sm:px-6 md:px-8">
      <div
        class="prose prose-sm max-w-none sm:prose sm:max-w-none"
        #pageContent
        [innerHTML]="content$ | async"
      ></div>
    </div>

    <div
      class="mx-2 mb-20 mt-24 flex justify-between space-x-6 font-normal text-blue-500 md:mb-32"
    >
      <div class="w-5/12">
        <div
          *ngIf="pageId !== '' && previousPage !== null"
          matTooltip="{{ previousPage.title }}"
          class="border-tranparent group flex items-center rounded-md border p-3 text-blue-500 hover:border hover:border-gray-100 hover:bg-gray-50 hover:text-bluemine-500 hover:shadow-sm"
          [routerLink]="['/' + docType + '/' + docId + '/' + previousPage.url]"
          [queryParams]="{ q: filter ? filter : undefined }"
        >
          <app-chevron-left-outline-icon
            size="20"
          ></app-chevron-left-outline-icon>
          <p
            class="line-clamp-1"
            [innerHTML]="previousPage.title | sanitizeHtml"
          ></p>
        </div>
      </div>
      <div class="w-5/12">
        <div
          *ngIf="pageId !== '' && nextPage !== null"
          matTooltip="{{ nextPage.title }}"
          class="border-tranparent group flex items-center justify-end rounded-md border p-3 text-blue-500 hover:border hover:border-gray-100 hover:bg-gray-50 hover:text-bluemine-500 hover:shadow-sm"
          [routerLink]="['/' + docType + '/' + docId + '/' + nextPage.url]"
          [queryParams]="{ q: filter ? filter : undefined }"
        >
          <p
            class="line-clamp-1"
            [innerHTML]="nextPage.title | sanitizeHtml"
          ></p>

          <app-chevron-right-outline-icon
            size="20"
          ></app-chevron-right-outline-icon>
        </div>
      </div>
    </div>
  </main>
  <!-- End main  -->

  <!-- Start nav  -->
  <nav
    #pageToc
    class="sticky hidden h-screen flex-shrink-0 lg:flex lg:w-1/5"
    [ngClass]="{
      'top-36': !_notify.show,
      'top-44': _notify.show
    }"
  >
    <ul class="overflow-y-auto px-0 pb-36 pt-5">
      <ng-container *ngFor="let toc of tocList$ | async; index as i">
        <li
          title="{{ toc.title }}"
          class="m-2 text-sm font-normal text-blue-500"
          [ngClass]="{
            'pl-0': toc.level === 'h1',
            'pl-4': toc.level === 'h2',
            'pl-8': toc.level === 'h3',
            'list-disc text-bluemine-500': i === (activeItemIndex$ | async)
          }"
        >
          <a
            [routerLink]="['/' + docType + '/' + docId + '/' + pageId]"
            [queryParams]="{ q: filter ? filter : undefined }"
            fragment="{{ toc.fragment }}"
            class="hover:text-bluemine-500 hover:underline"
            href="javascript:void(0);"
            (click)="onClickPageToc(toc.fragment)"
            [innerHTML]="toc.content"
          ></a>
        </li>
      </ng-container>
    </ul>
  </nav>
  <!-- End nav  -->
</div>

<!-- Start Modal -->
<ng-container
  *ngComponentOutlet="modalComponent; content: modalContent"
></ng-container>
<!-- End Modal -->
