import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-compliance-guide',
  templateUrl: './compliance-guide.component.html',
  styleUrls: ['./compliance-guide.component.scss'],
})
export class ComplianceGuidComponent {
  /**
   * 左の位置
   */
  @Input() set left(value: number) {
    this._left = value;
  }

  get left(): number {
    return this._left;
  }

  private _left = 0;

  /**
   * トップの位置
   */
  @Input() set top(value: number) {
    this._top = value;
  }

  get top(): number {
    return this._top;
  }

  private _top = 0;
}
