import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { LeftSidenavComponent } from './left-sidenav/left-sidenav.component';
import { PanelComponent } from './panel/panel.component';
import { SharedModule } from 'shared';
import { LeftWideSidenavComponent } from './left-wide-sidenav/left-wide-sidenav.component';
import { ConfimationDialogComponent } from './confimation-dialog/confimation-dialog.component';

@NgModule({
  declarations: [
    LeftSidenavComponent,
    PanelComponent,
    LeftWideSidenavComponent,
    ConfimationDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    DragDropModule,
    SharedModule,
  ],
  exports: [LeftSidenavComponent, LeftWideSidenavComponent, PanelComponent],
})
export class LayoutModule {}
