import { Component, Input, OnInit } from '@angular/core';
import { SelectItemService } from '../select-item.service';
import { Observable } from 'rxjs';

import { Bom } from '../exploded-view.interface';

@Component({
  selector: 'app-bom-item',
  templateUrl: './bom-item.component.html',
  styleUrls: ['./bom-item.component.scss'],
})
export class BomItemComponent implements OnInit {
  @Input() bom!: Bom;

  /**
   * 選択状態か否か
   */
  public isSelected = false;

  /**
   * 互換性の開閉状態
   */
  public isOpenInterchange = false;

  /**
   * Bom インデックス
   */
  public index: number = -1;

  /**
   * 選択アイテムリスト
   */
  public selectItemIndex$?: Observable<boolean[]>;

  /**
   * 互換性の存在
   */
  public isInterchange = false;

  /**
   * コンストラクタ
   */
  constructor(private selectItemService: SelectItemService) {
    this.selectItemIndex$ = this.selectItemService.valueChanges;

    this.selectItemIndex$.subscribe((itemIndexList) => {
      // ホットスポットあるいはBOMが選択されたら、BOMの選択状態を変更する
      // if (this.index !== -1 && itemIndexList[this.index] === true) {
      //   if (
      //     this.bom.interchangeabilitys != null &&
      //     this.bom.interchangeabilitys.length > 0
      //   ) {
      //     this.isOpenInterchange = true;
      //   }
      // } else {
      //   if (
      //     this.bom.interchangeabilitys != null &&
      //     this.bom.interchangeabilitys.length > 0
      //   ) {
      //     this.isOpenInterchange = false;
      //   }
      // }
    });
  }

  /**
   * 初期処理
   * 互換性の存在有無を設定
   */
  ngOnInit(): void {
    this.index = Number(this.bom.bom_index) - 1;

    if (
      this.bom.interchangeabilitys != null &&
      this.bom.interchangeabilitys.length > 0
    ) {
      this.isInterchange = true;
    }
  }

  /**
   * BOM情報押下メソッド
   * 選択状態をSelectItemServiceへ保存
   */
  onClickList(item: string): void {
    console.log('onClickList', item);
    this.selectItemService.changeItemIndex(item);
  }
}
