<!-- Start Nav -->
<div id="top-of-page"></div>
<lib-nav
  [sidebar]="true"
  [toolbar]="true"
  (openSidebar)="openSidebar()"
  [notify]="notify"
  (closeNotify)="closeNotify()"
>
  <span tools-desktop>
    <div class="flex flex-1 justify-between px-1 md:px-5">
      <app-toolbar-button *ngIf="pageId !== '' && previousPage !== null">
        <div
          matTooltip="{{ previousPage.title | stripHtml }}"
          [routerLink]="[
            '/' + manual.docType + '/' + manual.docId + '/' + previousPage.url
          ]"
          [queryParams]="{ q: filter ? filter : undefined }"
        >
          <app-chevron-left-outline-icon
            size="20"
          ></app-chevron-left-outline-icon>
          <span class="sr-only">Previous</span>
        </div>
      </app-toolbar-button>

      <app-toolbar-button *ngIf="pageId !== '' && nextPage !== null">
        <div
          matTooltip="{{ nextPage.title | stripHtml }}"
          [routerLink]="[
            '/' + manual.docType + '/' + manual.docId + '/' + nextPage.url
          ]"
          [queryParams]="{ q: filter ? filter : undefined }"
        >
          <app-chevron-right-outline-icon
            size="20"
          ></app-chevron-right-outline-icon>
          <span class="sr-only">Next</span>
        </div>
      </app-toolbar-button>

      <app-toolbar-button (emitClick)="toggleBookmark()">
        <app-star-solid-icon
          size="20"
          [svgClass]="isBookmarked ? 'text-blue-900' : 'text-gray-400'"
        ></app-star-solid-icon>
        <span class="sr-only">Bookmark</span>
      </app-toolbar-button>

      <app-toolbar-button (emitClick)="openInfoPanel()">
        <app-information-circle-outline-icon
          size="20"
        ></app-information-circle-outline-icon>
        <span class="sr-only">Information</span>
      </app-toolbar-button>

      <app-toolbar-button (emitClick)="printPage()">
        <app-printer-outline-icon size="20"></app-printer-outline-icon>
        <span class="sr-only">Print</span>
      </app-toolbar-button>

      <a
        href="/{{ this.locale }}/pdfs/{{ this.manual.docType }}/{{
          this.manual.docId.toUpperCase()
        }}/{{ this.docBasic.pdf_file_path }}"
        target="_blank"
        rel="noopener noreferrer"
      >
        <app-toolbar-button>
          <app-adobe-pdf-outline-icon size="20"></app-adobe-pdf-outline-icon>
          <span class="sr-only">PDF</span>
        </app-toolbar-button>
      </a>
    </div>
  </span>
  <span tools-smartphone>
    <nav aria-label="tools">
      <div class="space-y-1 p-4">
        <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
        <a
          (click)="toggleBookmark(); (false)"
          href="#"
          class="group flex items-center rounded-md px-2 py-2 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
        >
          <app-star-solid-icon
            [svgClass]="
              isBookmarked
                ? 'text-blue-500 group-hover:text-gray-500 mr-4 h-6 w-6'
                : 'text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6'
            "
          ></app-star-solid-icon>
          <span i18n>Bookmark</span>
        </a>

        <a
          (click)="openInfoPanel(); (false)"
          href="#"
          class="group flex items-center rounded-md px-2 py-2 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
        >
          <app-information-circle-outline-icon
            svgClass="text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6"
          ></app-information-circle-outline-icon>
          <span i18n>Information</span>
        </a>

        <a
          (click)="printPage(); (false)"
          href="#"
          class="group flex items-center rounded-md px-2 py-2 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
        >
          <app-printer-outline-icon
            svgClass="text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6"
          ></app-printer-outline-icon>
          <span i18n>Print</span>
        </a>

        <a
          class="group flex items-center rounded-md px-2 py-2 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
          href="/{{ this.locale }}/pdfs/{{ this.manual.docType }}/{{
            this.manual.docId.toUpperCase()
          }}/{{ this.docBasic.pdf_file_path }}"
          target="_blank"
          rel="noopener noreferrer"
        >
          <app-adobe-pdf-outline-icon
            svgClass="text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6"
          ></app-adobe-pdf-outline-icon>
          <span i18n>PDF</span>
        </a>
      </div>
    </nav>
  </span>
</lib-nav>
<!-- End Nav -->

<!-- Start Mobile Sidebar -->
<app-manual-toc-mobile
  [manual]="manual"
  [pageId]="pageId"
  [expanded]="isSidebarOpen"
  (close)="closeSidebar()"
>
</app-manual-toc-mobile>
<!-- End Mobile Sidebar -->

<!-- Start Contents -->
<div class="flex justify-center">
  <div class="relative flex w-full max-w-screen-xl flex-col">
    <div class="flex justify-center sm:w-screen xl:w-auto">
      <!-- Start left sidebar for desktop -->
      <app-manual-toc-desktop
        [manual]="manual"
        [pageId]="pageId"
        [notify]="notify"
      ></app-manual-toc-desktop>
      <!-- End left sidebar for desktop -->

      <!-- Start manual page -->
      <section class="z-0 md:w-3/4 lg:w-4/5">
        <ng-container *ngIf="pageId !== ''; else topContent">
          <app-manual-page
            [docType]="manual.docType"
            [docId]="manual.docId"
            [pageId]="pageId"
            [filter]="filter"
            [notify]="notify"
            [previousPage]="previousPage"
            [nextPage]="nextPage"
          ></app-manual-page>
        </ng-container>

        <ng-template #topContent>
          <app-manual-top [manual]="manual"> </app-manual-top>
        </ng-template>
      </section>

      <!-- End manual page -->
    </div>
  </div>
  <ng-container #infoPanelContainer></ng-container>
</div>
<!-- End Contents -->

<!-- Back to top button -->
<button
  type="button"
  [ngStyle]="{ display: windowScrolled ? 'block' : 'none' }"
  class="fixed bottom-5 right-5 z-30 inline-block hidden rounded-full border bg-blue-700 p-3 text-xs font-medium uppercase leading-tight text-white shadow-sm transition duration-150 ease-in-out hover:bg-white hover:text-bluemine-300 hover:shadow-sm focus:bg-blue-700 focus:text-white focus:shadow-sm focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-sm"
  id="btn-back-to-top"
  (click)="onClickBackToTop()"
>
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    class="h-4 w-4"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
    ></path>
  </svg>
</button>

<!-- Start footer -->
<lib-footer></lib-footer>
<!-- End footer -->
