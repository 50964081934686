import { Component } from '@angular/core';

import { BaseOutlineIconComponent } from '../base-outline-icon.component';

@Component({
  selector: 'app-specifications-outline-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="-722 683 113 113"
    stroke="currentColor"
  >
    <defs>
      <style>
        .cls-spec-1 {
          clip-path: url(#clip-spec);
        }
        .cls-spec-2 {
          fill: #3db8ed;
        }
        .cls-spec-3,
        .cls-spec-5,
        .cls-spec-8 {
          fill: none;
        }
        .cls-spec-3 {
          stroke: #3db8ed;
        }
        .cls-spec-4 {
          opacity: 0.8;
        }
        .cls-spec-5 {
          stroke: #fff;
        }
        .cls-spec-6 {
          fill: #fff;
          fill-opacity: 0;
        }
        .cls-spec-7 {
          stroke: none;
        }
      </style>
      <clipPath id="clip-spec">
        <rect x="-722" y="683" width="113" height="113" />
      </clipPath>
    </defs>
    <g class="cls-spec-1">
      <rect class="cls-spec-6" x="-722" y="683" width="113" height="113" />
      <g transform="translate(-1576 331)">
        <ellipse
          class="cls-spec-2"
          cx="48"
          cy="47.874"
          rx="48"
          ry="47.874"
          transform="translate(863 361)"
        />
        <g class="cls-spec-3" transform="translate(858 356)">
          <circle class="cls-spec-7" cx="53" cy="53" r="53" />
          <circle class="cls-spec-8" cx="53" cy="53" r="52.5" />
        </g>
        <g class="cls-spec-4" transform="translate(0 2)">
          <g class="cls-spec-5" transform="translate(894 384)">
            <rect class="cls-spec-7" width="34" height="45" />
            <rect class="cls-spec-8" x="0.5" y="0.5" width="33" height="44" />
          </g>
          <line class="cls-spec-5" x2="20" transform="translate(901 415.5)" />
          <line class="cls-spec-5" x2="20" transform="translate(901 410.5)" />
          <line class="cls-spec-5" x2="20" transform="translate(901 420.5)" />
          <rect
            class="cls-spec-6"
            width="20"
            height="11"
            transform="translate(901 392)"
          />
        </g>
      </g>
    </g>
  </svg>`,
})
export class SpecificationsOutlineIconComponent extends BaseOutlineIconComponent {}
