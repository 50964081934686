import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'lib-toolbar-modal',
  templateUrl: './toolbar-modal.component.html',
  styleUrls: ['./toolbar-modal.component.scss'],
})
export class ToolbarModalComponent {
  /**
   * モバイル用ツールバーモーダルの開閉状態
   */
  public expanded = false;

  /**
   * (ViewChild) ツールバーコンテナ
   */
  @ViewChild('toolbarContainer') toolbarContainer!: ElementRef;

  /**
   * 通知領域が開いている状態であるか
   */
  @Input() set notify(value: boolean) {
    this._notify = value;
  }

  /**
   * 通知領域の開閉状態を保持する
   */
  public _notify = true;

  /**
   * Getter notify
   */
  get notify(): boolean {
    return this._notify;
  }

  /**
   * コンストラクタ
   * @param document Document
   * @param renderer Renderer2
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll($event: any): void {
    if (this.expanded === true) {
      $event.preventDefault();
    }
  }

  @HostListener('mousewheel', ['$event'])
  onMousewheel($event: any): void {
    if (this.expanded === true) {
      $event.preventDefault();
    }
  }

  @HostListener('touchmove', ['$event'])
  onTouchmove($event: any): void {
    if (this.expanded === true) {
      $event.preventDefault();
    }
  }

  /**
   * ウィンドウのリサイズでViewportScrollerのオフセットを変更する
   */
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const viewportWidth = this.width;
    if (viewportWidth > 760) {
      this.expanded = false;
    }
  }

  public onClickOpen(): void {
    this.expanded = !this.expanded;
    this.renderer.addClass(this.document.body, 'overflow-y-hidden');
  }

  /**
   * スマートフォン用サイドバーを閉じる
   */
  public onClickClose(): void {
    this.expanded = false;
    this.renderer.removeClass(this.document.body, 'overflow-y-hidden');
  }

  public get width() {
    return window.innerWidth;
  }
}
