import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

import { UpdateInfo, UpdateInfoService } from './update-info.service';

import { Notify, NotifyService } from '../shared/notify.service';

@Component({
  selector: 'app-update-info',
  templateUrl: './update-info.component.html',
  styleUrls: ['./update-info.component.scss'],
})
export class UpdateInfoComponent implements OnInit {
  /**
   * 検索結果
   */
  public updateInfo$?: Observable<UpdateInfo>;

  /**
   * 通知オブジェクト
   */
  public notify: Notify;

  constructor(
    private title: Title,
    private updateInfoService: UpdateInfoService,
    private notifyService: NotifyService
  ) {
    this.notify = this.notifyService.getNotify();
    this.title.setTitle(
      `Manuals Update Information | Manuals & Technical Documents`
    );

    this.updateInfo$ = this.updateInfoService.updateInfoChanges;
  }

  ngOnInit(): void {
    this.updateInfoService.getUpdateInfo(10, 1);
  }

  public handlePageEvent(event: PageEvent): void {
    this.updateInfoService.getUpdateInfo(event.pageSize, event.pageIndex + 1);
  }

  public minus(value: any): number {
    return --value;
  }

  /**
   * 通知表示を停止する
   */
  closeNotify(): void {
    this.notify = this.notifyService.setNotifyDisable();
  }
}
