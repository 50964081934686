import { Component, OnInit, Input } from '@angular/core';
import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';

export interface TreeNode {
  name: string;
  children?: TreeNode[];
}

const modelsOfXTR: TreeNode[] = [
  {
    name: 'XTR',
    children: [
      { name: 'BR-M9100' },
      { name: 'BR-M9110' },
      { name: 'BR-M9120' },
      { name: 'BL-M9100' },
      { name: 'RT-MT900' },
    ],
  },
];

@Component({
  selector: 'app-manual-model-item',
  templateUrl: './manual-model-item.component.html',
  styleUrls: ['./manual-model-item.component.scss'],
})
export class ManualModelItemComponent implements OnInit {
  @Input() tnode!: TreeNode[];

  public treeControl!: NestedTreeControl<TreeNode>;
  public dataSource!: ArrayDataSource<TreeNode>;

  constructor() {}

  ngOnInit(): void {
    this.treeControl = new NestedTreeControl<TreeNode>((node) => node.children);
    this.dataSource = new ArrayDataSource(this.tnode);
  }

  hasChild = (_: number, node: TreeNode) =>
    !!node.children && node.children.length > 0;
}
