<div
  *ngFor="let tag of activeTags$ | async"
  class="pl-7 pr-2 w-full flex justify-between items-center"
>
  <span class="w-36">
    <p
      matTooltip="{{ tag.name }}"
      class="whitespace-nowrap overflow-ellipsis overflow-hidden"
    >
      {{ tag.name }}
    </p>
  </span>
  <button
    type="button"
    (click)="onClickFacet($event, tag.category, tag.name)"
    class="inline-flex w-6 justify-between items-center text-left text-gray-700 focus:border-transparent focus:outline-none"
  >
    <span class="ml-3 h-7 w-6 flex items-center">
      <app-x-outline-icon
        size="20"
        class="transition duration-300 ease-in-out"
        svgClass="h-6 w-6"
      ></app-x-outline-icon>
    </span>
  </button>
</div>
