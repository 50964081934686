import { Component, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { ModalService } from 'shared';

// Helpers
function querySelectorAll<K extends keyof HTMLElementTagNameMap>(
  parent: Element,
  selector: K
): HTMLElementTagNameMap[K][];
function querySelectorAll<K extends keyof SVGElementTagNameMap>(
  parent: Element,
  selector: K
): SVGElementTagNameMap[K][];
function querySelectorAll<E extends Element = Element>(
  parent: Element,
  selector: string
): E[];
function querySelectorAll(parent: Element, selector: string): Array<Element> {
  // Wrap the `NodeList` as a regular `Array` to have access to array methods.
  // NOTE: IE11 does not even support some methods of `NodeList`, such as
  //       [NodeList#forEach()](https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach).
  return Array.from(parent.querySelectorAll(selector));
}

@Component({
  selector: 'app-svg-viewer',
  animations: [
    trigger('sideOverAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('300ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
  templateUrl: './svg-viewer.component.html',
  styleUrls: ['./svg-viewer.component.scss'],
})
export class SvgViewerComponent {
  private initialWith = 400;
  private panelContentContainer!: ElementRef;
  private svgElement!: ElementRef;

  /**
   * パネルコンテンツ View
   */
  @ViewChild('panelContent') set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.panelContentContainer = content;
      this.svgElement =
        this.panelContentContainer.nativeElement.querySelectorAll('svg')[0];
      this.renderer.setStyle(this.svgElement, 'width', `${this.initialWith}px`);
    }
  }

  /**
   * コンストラクタ
   * @param renderer renderer
   * @param modalService モーダルサービス
   */
  constructor(
    private renderer: Renderer2,
    private modalService: ModalService
  ) {}

  /**
   * スライド操作後に画像のサイズを変更する
   * @param event イベント
   */
  public onSlide(event: any): void {
    const width = this.initialWith * event.value;
    this.svgElement =
      this.panelContentContainer.nativeElement.querySelectorAll('svg')[0];
    this.renderer.setStyle(this.svgElement, 'width', `${width}px`);
  }

  /**
   * xボタンの押下でクローズする
   */
  public onClick($event: any): void {
    this.notifyClose();
  }

  /**
   * クローズを通知する
   */
  private notifyClose(): void {
    this.modalService.requestCloseModal();
  }
}
