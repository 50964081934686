<!-- Start header -->
<div id="top-of-page"></div>
<lib-nav
  (openSidebar)="openSidebar()"
  [notify]="notify"
  (closeNotify)="closeNotify()"
>
</lib-nav>
<!-- End header -->

<div class="flex justify-center bg-white min-h-screen">
  <div class="relative flex flex-col">
    <div class="max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl sm:w-screen">
      <div
        class="relative flex justify-center flex-col px-6 py-8 sm:px-12 sm:pt-10 sm:pb-20 overflow-hidden"
      >
        <div class="text-center">
          <h2
            class="font-medium uppercase text-black tracking-wider text-2xl sm:text-3xl"
          >
            Compliance Documents
          </h2>
        </div>
        <div class="mx-auto w-10 border border-blue-500 mt-2"></div>
      </div>
    </div>

    <div class="pb-32 lg:max-w-7xl">
      <div class="overflow-hidden flex justify-center">
        <ul class="w-full md:w-3/5">
          <li
            class="border-b boder-gray-300"
            *ngFor="let node of nodes$ | async"
          >
            <a href="{{ node.url }}" class="block hover:bg-gray-50">
              <div class="px-4 py-4 flex items-center sm:px-6">
                <div
                  class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between"
                >
                  <div class="truncate">
                    <div class="flex text-xl sm:text-2xl">
                      <p class="font-medium text-bluemine-600 truncate">
                        {{ node.title }}
                      </p>
                    </div>
                    <div class="mt-2 flex">
                      <div class="flex items-center text-sm text-gray-500">
                        <app-calendar-solid-icon
                          size="20"
                          svgClass="flex-shrink-0 mr-1.5 text-gray-400"
                        ></app-calendar-solid-icon>
                        <p>
                          Last Updated on
                          <time datetime="2020-01-07">
                            {{ node.lastUpdateDate }}
                          </time>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ml-5 flex-shrink-0">
                  <app-chevron-right-solid-icon
                    size="20"
                    svgClass="text-gray-400"
                  ></app-chevron-right-solid-icon>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<lib-footer></lib-footer>
