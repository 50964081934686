import {
  Component,
  HostListener,
  Injector,
  OnInit,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-update-info-item',
  templateUrl: './update-info-item.component.html',
  styleUrls: ['./update-info-item.component.scss'],
})
export class UpdateInfoItemComponent implements OnInit {
  /**
   * アイテム
   */
  @Input() item!: any;

  /**
   * モバイル幅の判定
   */
  public isMobile = false;

  /**
   * constructor
   * @param injector
   */
  constructor(private injector: Injector) {}

  /**
   * OnInit
   */
  ngOnInit(): void {
    const viewportWidth = this.width;
    if (viewportWidth > 640) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  /**
   * ウィンドウのリサイズでViewportScrollerのオフセットを変更する
   */
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const viewportWidth = this.width;
    if (viewportWidth > 760) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  setDocTypeColor(docType: string): string {
    let color = '';
    switch (docType) {
      case 'DM':
        color = '#bd362f';
        break;
      case 'UM':
        color = '#51a351';
        break;
      case 'SI':
        color = '#005686';
        break;
      case 'EV':
        color = '#fd8c34';
        break;
      case 'QM':
        color = '#4aacc5';
        break;
      case 'SM':
        color = '#E6B8B7';
        break;
    }
    return color;
  }

  /**
   * PDFの表示でPageViewをカウントする
   * @param locale
   * @param docType
   * @param docNo
   * @param pdfPath
   */
  sendPageView(
    locale: string,
    docType: string,
    docNo: string,
    pdfPath: string
  ): void {}

  public get width() {
    return window.innerWidth;
  }
}
