import { Component } from '@angular/core';

import { BaseOutlineIconComponent } from '../base-outline-icon.component';

@Component({
  selector: 'app-bookmark-outline-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M5 5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16l-7-3.5L5 21V5z"
    />
  </svg>`,
})
export class BookmarkOutlineIconComponent extends BaseOutlineIconComponent {}
