<div
  [attr.id]="error.id"
  [ngClass]="{
    'my-1 border shadow-sm': isOpen,
    '': !isOpen
  }"
>
  <dt
    class="border-t p-3 text-lg sm:px-10"
    [ngClass]="{
      'border-blue-500 bg-gray-50 bg-opacity-30': isOpen,
      '': !isOpen
    }"
  >
    <!-- Expand/collapse button -->
    <button
      type="button"
      (click)="onClick()"
      class="flex h-8 w-full items-center justify-between text-left text-blue-500 focus:border-transparent focus:outline-none"
      [attr.aria-controls]="error.id"
      [attr.aria-expanded]="isOpen"
    >
      <span
        class="overflow-hidden overflow-ellipsis whitespace-nowrap font-medium"
        [ngClass]="{
          'text-bluemine-500': isOpen,
          'w-2/5 text-gray-900': !isOpen
        }"
      >
        {{ error.code }}
      </span>
      <span
        *ngIf="!isOpen"
        class="h-6 w-3/5 overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-normal text-gray-500"
        [innerHTML]="error.indication_conditions | sanitizeHtml"
      ></span>
      <span class="ml-6 flex h-7 items-center">
        <app-chevron-down-outline-icon
          size="24"
          class="transition duration-300 ease-in-out"
          [ngClass]="{
            'rotate-180 transform': isOpen,
            '': !isOpen
          }"
          svgClass="h-6 w-6"
        ></app-chevron-down-outline-icon>
      </span>
    </button>
  </dt>
  <dd
    class="overflow-hidden transition-[max-height,opacity] duration-700 ease-in-out"
    id="faq-0"
    [ngClass]="{
      'max-h-[1200px] opacity-100': isOpen,
      'max-h-0 opacity-0': !isOpen
    }"
  >
    <div *ngIf="isOpen" class="bg-gray-50 bg-opacity-30 p-6 pr-12 sm:px-10">
      <h3
        class="mb-3 mt-3 text-lg font-medium leading-6 text-gray-900 md:text-xl"
        i18n
      >
        Indication conditions
      </h3>
      <p
        class="prose prose-sm max-w-none sm:prose sm:max-w-none"
        [innerHTML]="error.indication_conditions | sanitizeHtml"
      ></p>
      <h3
        *ngIf="error.operational_restrictions"
        class="mb-3 mt-8 text-lg font-medium leading-6 text-gray-900 md:text-xl"
        i18n
      >
        Operational restrictions being displayed
      </h3>
      <p
        class="prose prose-sm max-w-none sm:prose sm:max-w-none"
        [innerHTML]="error.operational_restrictions | sanitizeHtml"
      ></p>
      <h3
        *ngIf="error.remedy"
        class="mb-3 mt-8 text-lg font-medium leading-6 text-gray-900 md:text-xl"
        i18n
      >
        Remedy
      </h3>
      <p
        class="prose prose-sm max-w-none sm:prose sm:max-w-none"
        [innerHTML]="error.remedy | sanitizeHtml"
      ></p>
    </div>
  </dd>
</div>
