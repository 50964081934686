<div *ngIf="show">
  <footer class="bg-blue-500 p-6 leading-loose">
    <div class="max-w-screen-xl mx-auto py-4 px-4">
      <!--  
        <div class="md:grid md:grid-cols-3 md:gap-8">
        <div class="m-4" *ngFor="let node of nodes">
          <h3 class="text-lg font-semibold text-white tracking-wider uppercase">
            {{ node.title }}
          </h3>
          <ul class="mt-4 space-y-4">
            <li *ngFor="let item of node.children">
              <a
                class="text-base text-white hover:text-gray-50 hover:underline"
                [href]="item.url"
                [title]="item.title"
                >{{ item.title }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      -->
      <div class="border-t border-gray-200">
        <div class="mt-1">
          <a
            href="https://www.shimano.com"
            class="text-lg text-gray-50 hover:text-blue-200"
            i18n
            >CORPORATE</a
          >
        </div>
        <ul class="flex flex-col md:flex-row items-center">
          <li>
            <a href="https://www.shimano.com/en/privacypolicy.html"
              ><span
                class="align-middle text-sm text-white border-r-2 pl-2 pr-2 md:pr-6 uppercase hover:text-blue-200"
                i18n
                >Privacy Policy</span
              ></a
            >
          </li>

          <!--
          <li>
            <a href="/en-US/cookie-preference-page.html">
              <span
                class="align-middle text-sm text-white border-r-2 px-2 md:px-6 uppercase hover:text-blue-200"
                i18n
                >Cookies Preferences</span
              >
            </a>
          </li>
          -->

          <li>
            <a href="https://www.shimano.com/en/term_of_use.html">
              <span
                class="align-middle text-sm text-white border-r-2 px-2 md:px-6 uppercase hover:text-blue-200"
                i18n
              >
                Terms of Use
              </span></a
            >
          </li>

          <li>
            <a
              href="https://bike.shimano.com/en-EU/information/legal-info.html"
            >
              <span
                class="align-middle text-sm text-white pl-2 md:pl-6 pr-2 uppercase hover:text-blue-200"
                i18n
              >
                Legal - General Information (EU Region)
              </span></a
            >
          </li>
        </ul>
        <div class="mt-1">
          <p class="text-sm text-gray-50">
            &copy; SHIMANO INC. ALL RIGHTS RESERVED
          </p>
        </div>
      </div>
    </div>
  </footer>
</div>
