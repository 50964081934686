import {
  AfterViewInit,
  Component,
  EventEmitter,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { CdkDragMove } from '@angular/cdk/drag-drop';

import { Notify, NotifyService } from '../../shared/notify.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  animations: [
    trigger('sideOverAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('300ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements AfterViewInit {
  /**
   * サイドバーが開いている状態であるか
   */
  @Input() set expanded(value: boolean) {
    this._expanded = value;
  }

  get expanded(): boolean {
    return this._expanded;
  }

  public _expanded = false;

  public _dragDisabled = false;

  @Input() title: string = '';

  @Input() initialWidth = 250;

  /**
   * サイドバーのクローズを伝える
   */
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('resizeBox') resizeBox!: ElementRef;
  @ViewChild('dragHandleLeft') dragHandleLeft!: ElementRef;

  /**
   * 通知オブジェクト
   */
  public notify: Notify;

  /**
   * コンストラクタ
   * @param elementRef
   * @param ngZone
   * @param notifyService
   */
  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private notifyService: NotifyService
  ) {
    this.notify = this.notifyService.getNotify();
  }

  /**
   * AfterViewInit
   */
  ngAfterViewInit(): void {
    this.resizeBoxElement.style.width = this.initialWidth + 'px';
    this.setHandleTransform();
  }

  /**
   * パネル外のクリックでパネルをクローズする
   * @param event
   */
  @HostListener('document:mousedown', ['$event'])
  onClickOut(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this._close();
    }
  }

  /**
   * (HostListner) マウスホイールで親要素に対する伝搬を止める
   * @param $event any
   */
  @HostListener('mousewheel', ['$event'])
  onMousewheel($event: any): void {
    if (this._expanded === true) {
      $event.stopPropagation();
    }
  }

  /**
   * (HostListner) タッチムーブで親要素に対する伝搬を止める
   * @param $event any
   */
  @HostListener('touchmove', ['$event'])
  onTouchmove($event: any): void {
    if (this._expanded === true) {
      $event.stopPropagation();
    }
  }

  /**
   *
   */
  public setHandleTransform(): void {
    const targetRect = this.resizeBoxElement.getBoundingClientRect();
    const dragRect = this.dragHandleLeftElement.getBoundingClientRect();

    const translateX = targetRect.left - dragRect.width;
    //    const translateX = 0;

    this.dragHandleLeftElement.style.transform = `translate3d(${translateX}px, 0, 0)`;
  }

  /**
   *
   * @param dragHandle
   * @param $event
   */
  public dragMove(dragHandle: HTMLElement, $event: CdkDragMove<any>): void {
    this.resize(dragHandle, this.resizeBoxElement);
  }

  /**
   *
   * @param dragHandle
   * @param target
   */
  public resize(dragHandle: HTMLElement, target: HTMLElement): void {
    const dragRect = dragHandle.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    const width = this.width - dragRect.left - dragRect.width;

    if (300 <= width && width <= 1280) {
      this._dragDisabled = false;
      target.style.width = width + 'px';
      this.setHandleTransform();
    } else {
      this._dragDisabled = true;
    }
  }

  /**
   * パネルをクローズする
   */
  public _close(): void {
    this._expanded = false;
    this.close.emit(false);
  }

  public get resizeBoxElement(): HTMLElement {
    return this.resizeBox.nativeElement;
  }

  public get dragHandleLeftElement(): HTMLElement {
    return this.dragHandleLeft.nativeElement;
  }

  public get width() {
    return window.innerWidth;
  }
}
