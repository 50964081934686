import { Component, HostBinding, Input } from '@angular/core';
import { MenuNode } from '../menu-node.interface';

@Component({
  selector: 'lib-nav-popover',
  templateUrl: './nav-popover.component.html',
  styleUrls: ['./nav-popover.component.scss'],
})
export class NavPopoverComponent {
  /**
   * ホスト クラス
   */
  @HostBinding('class') class = 'absolute z-10 -ml-0 mt-12';

  /**
   * メニューアイテム
   */
  @Input() nodes!: Array<MenuNode>;

  /**
   * メニューアイテム
   */
  @Input() parent!: MenuNode;

  /**
   * メニューが開いている状態であるか
   */
  @Input() set expanded(value: boolean) {
    this._expanded = value;
  }

  /**
   * メニューの開閉状態を保持する
   */
  public _expanded = false;

  /**
   * Getter expanded
   */
  get expanded(): boolean {
    return this._expanded;
  }
}
