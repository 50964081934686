import { Component, HostBinding, Input } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { MenuNode } from '../menu-node.interface';

@Component({
  selector: 'lib-nav-popover-item',
  animations: [
    trigger('NavPopoverAnimation', [
      transition(':enter', [
        style({ opacity: 0, height: '0px' }),
        animate('100ms', style({ opacity: 1, height: '*' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, height: '*' }),
        animate('200ms', style({ opacity: 0, height: '0px' })),
      ]),
    ]),
  ],
  templateUrl: './nav-popover-item.component.html',
  styleUrls: ['./nav-popover-item.component.scss'],
})
export class NavPopoverItemComponent {
  /**
   * ホスト クラス
   */
  @HostBinding('class') class = 'flex items-start';

  /**
   * メニューアイテム
   */
  @Input() node!: MenuNode;
}
