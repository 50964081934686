import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import {
  ComplianceNaviNode,
  ComplianceTocService,
} from '../compliance-toc.service';
import { Observable } from 'rxjs';

import { Notify, NotifyService } from '../../shared/notify.service';

@Component({
  selector: 'app-compliance-top',
  templateUrl: './compliance-top.component.html',
  styleUrls: ['./compliance-top.component.scss'],
})
export class ComplianceTopComponent implements OnInit {
  /**
   * 通知オブジェクト
   */
  public notify: Notify;

  /**
   * メニューノード
   */
  public nodes$?: Observable<ComplianceNaviNode[]>;

  /**
   * サイドバーが開いている状態であるか
   */
  public isSidebarOpen = false;

  constructor(
    private title: Title,
    private complianceTocService: ComplianceTocService,
    private notifyService: NotifyService
  ) {
    this.nodes$ = this.complianceTocService.valueSubject;
    this.notify = this.notifyService.getNotify();
    this.title.setTitle(`Compliance | Manuals & Technical Documents`);
  }

  ngOnInit(): void {
    this.complianceTocService.getToc();
  }

  /**
   * スマートフォン用サイドバーを開く
   */
  public openSidebar(): void {
    this.isSidebarOpen = true;
  }

  /**
   * スマートフォン用サイドバーを閉じる
   */
  public closeSidebar(): void {
    this.isSidebarOpen = false;
  }

  /**
   * 通知表示を停止する
   */
  closeNotify(): void {
    this.notify = this.notifyService.setNotifyDisable();
  }
}
