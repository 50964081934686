import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { ResultItemService, RequestTags } from '../result-item.service';

@Component({
  selector: 'app-search-request-tag',
  templateUrl: './search-request-tag.component.html',
  styleUrls: ['./search-request-tag.component.scss'],
})
export class SearchRequestTagComponent implements OnInit {
  /**
   * 選択タグ・オブザーバブル
   */
  public activeTags$: Observable<any>;

  constructor(
    private resultItemService: ResultItemService,
    private router: Router
  ) {
    this.activeTags$ = this.resultItemService.requestTagsChanges;
  }

  ngOnInit(): void {}

  /**
   * ファセット押下時に選択状態を保存しナビゲートする
   */
  onClickFacet(event: any, category: string, key: string): void {
    let params;
    let queryParams: any = {};

    params = this.resultItemService.removeTag({
      category: category,
      name: key,
    });

    queryParams.doctype = params.docTypes.length
      ? params.docTypes.join(',')
      : undefined;

    queryParams.contenttype = params.contentTypes.length
      ? params.contentTypes.join(',')
      : undefined;

    params.reqTagsMap.forEach((reqTags: RequestTags, category: string) => {
      if (reqTags.names?.length) {
        queryParams = {
          ...queryParams,
          [category]: reqTags.names.join(','),
        };
      }
    });

    queryParams.input_model = params.inputModel ? params.inputModel : undefined;

    queryParams.q = params.keywords
      ? params.keywords.replace(/("[^"]+")|[\s]+/g, (m, g1) => (g1 ? g1 : ','))
      : undefined;

    queryParams.sort =
      params.sortKey && params.sortOrder
        ? `${params.sortKey},${params.sortOrder}`
        : undefined;

    this.router.navigate(['manual/search'], {
      queryParams,
    });
  }
}
