<div class="relative px-4 pb-20 pt-6 sm:px-6 lg:px-8 lg:pb-28">
  <div class="relative mx-auto">
    <h1
      *ngIf="(docBasic$ | async) != undefined"
      class="pb-4 text-3xl text-bluemine-500"
    >
      {{ (docBasic$ | async)?.name }} ({{ (docBasic$ | async)?.type }}-{{
        (docBasic$ | async)?.doc_no
      }})
    </h1>
    <span
      class="p-1 px-2 text-xs text-white"
      [style.backgroundColor]="setDocTypeColor((docBasic$ | async)?.type)"
    >
      {{ (docBasic$ | async)?.type_name }}
    </span>

    <div class="mx-auto grid max-w-lg gap-5 py-5 lg:max-w-none lg:grid-cols-3">
      <ng-container
        *ngFor="let node of nodes$ | async; let first = first; let last = last"
      >
        <div
          *ngIf="node.children === undefined && node.last === undefined"
          class="flex flex-col overflow-hidden border shadow-sm"
        >
          <a
            class="text px-6 py-4 font-medium text-bluemine-500"
            routerLink="/{{ manual.docType }}/{{ manual.docId }}/{{ node.url }}"
            [queryParams]="{ q: filter }"
          >
            <p [innerHTML]="node.title | sanitizeHtml"></p>
          </a>
        </div>
      </ng-container>
    </div>

    <div class="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
      <ng-container *ngFor="let node of nodes$ | async">
        <div
          *ngIf="node.children"
          class="row-span-1 flex flex-col overflow-hidden border shadow-sm"
        >
          <div class="flex flex-1 flex-col justify-between bg-white">
            <div class="flex-1">
              <p
                [innerHTML]="node.title | sanitizeHtml"
                class="text border-b px-6 py-4 font-medium text-bluemine-500"
              ></p>
              <a
                *ngFor="let nodeChildren of node.children"
                href="#"
                class="mt-2 block"
              >
                <p
                  class="text px-6 py-1 font-normal text-blue-500 hover:text-bluemine-500"
                >
                  <a
                    class=""
                    routerLink="/{{ manual.docType }}/{{ manual.docId }}/{{
                      nodeChildren.url
                    }}"
                    [queryParams]="{ q: filter }"
                  >
                    <p [innerHTML]="nodeChildren.title | sanitizeHtml"></p>
                  </a>
                </p>
              </a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="mx-auto grid max-w-lg gap-5 py-5 lg:max-w-none lg:grid-cols-3">
      <ng-container
        *ngFor="let node of nodes$ | async; let first = first; let last = last"
      >
        <div
          *ngIf="node.children === undefined && node.last === true"
          class="flex flex-col overflow-hidden border shadow-sm"
        >
          <a
            class="text px-6 py-4 font-medium text-bluemine-500"
            routerLink="/{{ manual.docType }}/{{ manual.docId }}/{{ node.url }}"
            [queryParams]="{ q: filter }"
          >
            <p [innerHTML]="node.title | sanitizeHtml"></p>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</div>
