<div #header class="app-header flex h-11 justify-center border-b">
  <div class="flex w-full max-w-screen-xl items-center">
    <a href="https://si.shimano.com">
      <img
        class="h-4 px-2 xl:px-0"
        src="https://si.shimano.com/images/common/etc/logo-blue.webp"
      />
    </a>
    <span class="example-spacer max-w-screen-xl"></span>
    <div class="flex w-full justify-end">
      <div class="flex items-center border-l px-1">
        <button
          (click)="onClick()"
          type="button"
          class="group inline-flex items-center bg-white text-base font-normal text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-bluemine-500 focus:ring-offset-2"
          aria-expanded="false"
        >
          <span>{{ locale_name }}</span>
          <!--
            Heroicon name: solid/chevron-down

            Item active: "text-gray-600", Item inactive: "text-gray-400"
          -->
          <svg
            class="ml-2 h-5 w-5 text-gray-400 transition duration-300 ease-in-out group-hover:text-gray-500"
            [ngClass]="{
              'rotate-180 transform': expanded,
              '': !expanded
            }"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button
          class="relative ml-2 h-10 w-10 bg-white text-gray-500 focus:outline-none md:hidden"
          (click)="onClickMenu()"
        >
          <span class="sr-only">Open main menu</span>
          <div
            class="absolute left-1/2 top-1/2 block w-5 -translate-x-1/2 -translate-y-1/2 transform"
          >
            <span
              aria-hidden="true"
              class="absolute block h-0.5 w-5 transform bg-current text-blue-500 transition duration-300 ease-in-out"
              [ngClass]="{
                'rotate-45': menuExpanded,
                ' -translate-y-1.5': !menuExpanded
              }"
            ></span>
            <span
              aria-hidden="true"
              class="absolute block h-0.5 w-5 transform bg-current text-blue-500 transition duration-300 ease-in-out"
              [ngClass]="{
                'opacity-0': menuExpanded
              }"
            ></span>
            <span
              aria-hidden="true"
              class="absolute block h-0.5 w-5 transform bg-current text-blue-500 transition duration-300 ease-in-out"
              [ngClass]="{
                '-rotate-45': menuExpanded,
                ' translate-y-1.5': !menuExpanded
              }"
            ></span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<!--
    Flyout menu, show/hide based on flyout menu state.

    Entering: "transition ease-out duration-200"
      From: "opacity-0 -translate-y-1"
      To: "opacity-100 translate-y-0"
    Leaving: "transition ease-in duration-150"
      From: "opacity-100 translate-y-0"
      To: "opacity-0 -translate-y-1"
  -->
<div
  *ngIf="expanded"
  [@openAnimation]
  class="absolute inset-x-0 z-10 transform shadow-sm transition ease-in-out"
>
  <div class="absolute inset-0 flex" aria-hidden="true">
    <div class="w-full bg-white"></div>
  </div>
  <div class="relative mx-auto max-w-7xl">
    <nav
      class="bg-white px-4 py-6 sm:grid-cols-2 sm:gap-x-8 sm:px-6 sm:py-12 lg:px-8 xl:pr-12"
      aria-labelledby="select-language"
    >
      <div>
        <h3 class="text-sm font-medium uppercase tracking-wide text-gray-500">
          Select a language
        </h3>
        <ul
          role="list"
          class="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
        >
          <li *ngFor="let publicLocale of publicLocales; index as i">
            <a
              class="flex items-center rounded-md py-1 text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-50"
              (click)="onClickLocale(publicLocale.locale)"
            >
              <span class="ml-4">{{ publicLocale.name }}</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
