<svg:circle
  #hotSpotCircle
  [ngClass]="{
    'text-blue-500': isSelected,
    'text-transparent': !isSelected
  }"
  class="text-opacity-50"
  [attr.cx]="xHotSpot"
  [attr.cy]="yHotSpot"
  r="1.5%"
  (click)="onClickHotspot(hotSpot.item_no)"
  fill="currentColor"
></svg:circle>
