import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent {
  /**
   * (Input) タブのラベル(配列)
   */
  @Input() tabs!: Array<{ label: string }>;

  /**
   * 選択タブのインデックス
   */
  public selectedTab = 0;

  /**
   * 選択されたタブのインデックスを伝える
   */
  @Output() selectedTabIndex: EventEmitter<number> = new EventEmitter();

  /**
   * タブのクリック
   * @param i any
   */
  onClick(i: any): void {
    this.selectedTab = i;
    this.selectedTabIndex.emit(this.selectedTab);
  }
}
