<ul role="list" class="divide-y divide-blue-500">
  <li *ngIf="prev">
    <a (click)="onClickPrev(prev); (false)" href="#" class="block">
      <div class="flex items-center px-3 py-4 sm:px-6">
        <app-chevron-left-solid-icon
          svgClass="h-5 w-5 text-white"
        ></app-chevron-left-solid-icon>
        <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div class="truncate">
            <div class="flex text-sm">
              <p
                class="truncate text-base font-normal uppercase tracking-wider text-white"
              >
                Back
              </p>
            </div>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li *ngFor="let node of current">
    <ng-container *ngIf="node.children">
      <a href="{{ node.url }}" target="{{ node.target }}" class="block">
        <div class="flex items-center py-4 pl-6 sm:px-6">
          <div
            class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between"
          >
            <div class="truncate">
              <div class="flex text-sm">
                <p
                  class="truncate text-base font-normal uppercase tracking-wider text-white"
                >
                  {{ node.label }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex-shrink-0 px-4" (click)="onClickNext(node); (false)">
            <app-chevron-right-solid-icon
              svgClass="h-5 w-5 text-white"
            ></app-chevron-right-solid-icon>
          </div>
        </div>
      </a>
    </ng-container>
    <ng-container *ngIf="!node.children">
      <a href="{{ node.url }}" target="{{ node.target }}" class="block">
        <div class="flex items-center px-6 py-4 sm:px-6">
          <div
            class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between"
          >
            <div class="truncate">
              <div class="flex text-sm">
                <p
                  class="truncate text-base font-normal uppercase tracking-wider text-white"
                >
                  {{ node.label }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </ng-container>
  </li>
</ul>
