import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  Input,
  LOCALE_ID,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { trigger, style, animate, transition } from '@angular/animations';

import { MenuNode } from './menu-node.interface';
import { NavService } from './nav.service';

export interface Notify {
  show: boolean;
  message: string;
}

@Component({
  selector: 'lib-nav',
  animations: [
    trigger('openAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(
          '150ms ease-in',
          style({ transform: 'translateY(0)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(
          '50ms ease-out',
          style({ transform: 'translateY(-2%)', opacity: 0 })
        ),
      ]),
    ]),
  ],
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  /**
   * モバイル用のツールモーダル表示ボタンの表示
   */
  @Input() toolbar = false;

  /**
   * モバイル用のサイドバーの表示
   */
  @Input() sidebar = false;

  /**
   * ぱんくずの表示
   */
  @Input() beneath_header = true;

  /**
   * メニューが開いている状態であるか
   */
  @Input() set notify(value: Notify) {
    this._notify = value;
  }

  /**
   * 通知領域の開閉状態を保持する
   */
  public _notify = { show: false, message: '' };

  /**
   * Getter expanded
   */
  get notify(): Notify {
    return this._notify;
  }

  /**
   * 通知メッセージ
   */
  @Input() notifyMessage = '';

  /**
   * 選択可能なロケール
   */
  @Input() publicLocales = [
    { locale: 'en', name: 'English' },
    { locale: 'ja', name: '日本語' },
    { locale: 'zh-CN', name: '简体中文' },
    { locale: 'zh-TW', name: '繁體中文' },
    { locale: 'fr', name: 'français' },
    { locale: 'es', name: 'españoles' },
    { locale: 'de', name: 'Deutsch' },
    { locale: 'it', name: 'Italiano' },
    { locale: 'cs', name: 'Česky' },
    { locale: 'sk', name: 'Slovensky' },
    { locale: 'hu', name: 'Magyar' },
    { locale: 'ru', name: 'Русский' },
    { locale: 'nl', name: 'Nederlands' },
    { locale: 'sv', name: 'svenska' },
    { locale: 'fi', name: 'suomi' },
    { locale: 'no', name: 'Norsk' },
    { locale: 'pl', name: 'Polski' },
    { locale: 'da', name: 'dansk' },
    { locale: 'lt', name: 'Lietuvių kalba' },
    { locale: 'lv', name: 'Latviski' },
    { locale: 'et', name: 'Eesti' },
    { locale: 'el', name: 'Ελληνικά' },
    { locale: 'sl', name: 'Slovenščina' },
    { locale: 'ro', name: 'Română' },
    { locale: 'bg', name: 'Български' },
    { locale: 'tr', name: 'Türkçe' },
    { locale: 'sr', name: 'Српски' },
    { locale: 'hr', name: 'Hrvatski' },
    { locale: 'pt', name: 'Português' },
    { locale: 'ko', name: '한국어' },
    { locale: 'th', name: 'ไทย' },
    { locale: 'id', name: 'Bahasa Indonesia' },
  ];

  /**
   * パンくずリスト
   */
  @Input() breadcrumbs: Array<{ label: string; url: string }> = [];

  /**
   * サイドバーのオープンを伝える
   */
  @Output() openSidebar: EventEmitter<boolean> = new EventEmitter();

  /**
   * 通知エリアのクローズを伝える
   */
  @Output() closeNotify: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('navContainer')
  private navContainer!: ElementRef;

  /**
   * ロケール表示名
   */
  public locale_name? = '';

  /**
   * メニューアイテム
   */
  public menuItems!: Array<MenuNode>;

  /**
   * 言語選択の開閉状態
   */
  public localeExpanded = false;

  /**
   * モバイル用メニューの開閉状態
   */
  public menuExpanded = false;

  /**
   * コンストラクター
   * @param navService NavService
   */
  constructor(
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    @Inject('NavService') private navService: NavService
  ) {
    const locale = this.injector.get(LOCALE_ID);
    this.publicLocales = this.navService.getPublicLocales();

    this.locale_name = this.publicLocales.find(
      (localeObject) => localeObject.locale === locale
    )?.name;

    this.menuItems = this.navService.getNav();
  }

  /**
   * (HostListner) ウィンドウスクロールに対する動作をキャンセルする
   * @param $event any
   */
  @HostListener('window:scroll', ['$event'])
  onScroll($event: any): void {
    if (this.localeExpanded === true || this.menuExpanded === true) {
      $event.preventDefault();
    }
  }

  /**
   * (HostListner) マウスホイールで親要素に対する伝搬を止める
   * @param $event any
   */
  @HostListener('mousewheel', ['$event'])
  onMousewheel($event: any): void {
    if (this.localeExpanded === true || this.menuExpanded === true) {
      $event.stopPropagation();
    }
  }

  /**
   * (HostListner) タッチムーブで親要素に対する伝搬を止める
   * @param $event any
   */
  @HostListener('touchmove', ['$event'])
  onTouchmove($event: any): void {
    if (this.menuExpanded === true) {
      $event.stopPropagation();
    }
  }

  /**
   * 言語選択を開閉を切り替える
   */
  public onClick(): void {
    if (this.menuExpanded) {
      this.menuExpanded = false;
      this.renderer.removeClass(this.navContainer.nativeElement, 'h-full');
      this.renderer.removeClass(this.navContainer.nativeElement, 'inset-0');
      this.renderer.removeClass(this.navContainer.nativeElement, 'fixed');
    }
    this.localeExpanded = !this.localeExpanded;
    if (this.localeExpanded) {
      this.renderer.addClass(this.document.body, 'overflow-y-hidden');
      this.renderer.addClass(this.document.body, 'inset-0');
      this.renderer.addClass(this.document.body, 'fixed');
    } else {
      this.renderer.removeClass(this.document.body, 'overflow-y-hidden');
      this.renderer.removeClass(this.document.body, 'inset-0');
      this.renderer.removeClass(this.document.body, 'fixed');
    }
  }

  /**
   * モバイル用メニューの開閉を変更する
   */
  public onClickMenu(): void {
    if (this.localeExpanded) {
      this.localeExpanded = false;
      this.renderer.removeClass(this.document.body, 'overflow-y-hidden');
      this.renderer.removeClass(this.document.body, 'inset-0');
      this.renderer.removeClass(this.document.body, 'fixed');
    }

    this.menuExpanded = !this.menuExpanded;
    if (this.menuExpanded) {
      this.renderer.addClass(this.navContainer.nativeElement, 'h-full');
      this.renderer.addClass(this.navContainer.nativeElement, 'inset-0');
      this.renderer.addClass(this.navContainer.nativeElement, 'fixed');
    } else {
      this.renderer.removeClass(this.navContainer.nativeElement, 'h-full');
      this.renderer.removeClass(this.navContainer.nativeElement, 'inset-0');
      this.renderer.removeClass(this.navContainer.nativeElement, 'fixed');
    }
  }

  /**
   * モバイル用サイドバーを閉じる
   */
  public onClickSidebar(): void {
    this.openSidebar.emit(true);
  }

  /**
   * ロケールを選択する
   * @param locale
   */
  public onClickLocale(locale: string): void {
    const currentLocale = this.injector.get(LOCALE_ID);
    window.location.href = window.location.href.replace(
      `/${currentLocale}/`,
      `/${locale}/`
    );
  }

  /**
   * 通知領域を閉じる
   */
  public onClickNotify(): void {
    this._notify.show = false;
    this.closeNotify.emit(false);
  }
}
