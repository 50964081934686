import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { PageService } from './page.service';

@Injectable({
  providedIn: 'root',
})
export class PageResolver  {
  constructor(private router: Router, private pageService: PageService) {}

  /**
   * ページ　リゾルバ
   * @param route
   * @param state
   * @returns
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.pageService.getPage(this.getResolveUrl(route, state)).pipe(
      map((data) => {
        return data;
      }),
      catchError(() => {
        this.router.navigateByUrl('/NotFound', { skipLocationChange: true });
        return of(null);
      })
    );
  }

  /**
   * このリゾルバでページ情報を取得するURLを取得する
   */
  getResolveUrl(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): string {
    let url: string = '';

    let child = state.root.children[0];
    do {
      url += `/${child.url[0].path}`;
      if (child.url[0].path === route.url[0].path) break;
    } while ((child = child.children[0]));

    return url;
  }
}
