<li class="relative z-0 bg-gray-50">
  <div class="flex">
    <div
      class="px-2 py-2 hover:bg-gray-200"
      (click)="onClickList(bom.bom_index)"
    >
      <button
        class="flex h-6 w-6 items-center justify-center rounded-full text-center"
        [ngClass]="{
          'bg-blue-500': bom.isSelected,
          'text-white': bom.isSelected
        }"
      >
        {{ bom.bom_index }}
      </button>
    </div>

    <div class="flex w-40 flex-col px-2 py-2">
      <div class="flex">
        <div class="col-span-3 min-w-0 flex-1">
          <p
            class="text-sm font-medium"
            [ngClass]="{
              'text-gray-900': !bom.isSelected
            }"
            [innerHTML]="bom.global_sku_code"
          ></p>
        </div>

        <div
          *ngIf="isInterchange"
          class="z-10 flex items-center"
          (click)="bom.isOpenInterchange = !bom.isOpenInterchange"
        >
          <app-chevron-down-outline-icon
            class="block transition duration-300 ease-in-out"
            [ngClass]="{
              '-rotate-180 transform': bom.isOpenInterchange,
              '': !bom.isOpenInterchange
            }"
            svgClass="stroke-2 h-6 w-6 text-blue-500"
            stroke="2"
          ></app-chevron-down-outline-icon>
        </div>
      </div>

      <div class="col-span-5 col-start-2 min-w-0 flex-1">
        <p class="text-sm" [innerHTML]="bom.part_name"></p>
      </div>
    </div>
  </div>

  <div
    *ngIf="isInterchange"
    [ngClass]="{
      'hidden': !bom.isOpenInterchange,
    }"
    class="ml-1 w-full px-2 text-xs"
    i18n
  >
    INTERCHANGE ABILITY
  </div>

  <div
    *ngIf="isInterchange"
    [ngClass]="{
      'hidden': !bom.isOpenInterchange,
    }"
    class="grid grid-cols-3 justify-between px-2 pb-1"
  >
    <ng-container *ngFor="let interchangeability of bom.interchangeabilitys">
      <div
        *ngIf="interchangeability.model"
        class="row-span-2 grid grid-rows-subgrid"
      >
        <div
          class="h-auto break-words border border-black bg-gray-200 px-1 py-1 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          {{ interchangeability.model }}
        </div>
        <div
          class="h-6 whitespace-nowrap break-words border border-black bg-white px-1 py-1 text-center text-sm font-medium text-gray-900"
        >
          {{ interchangeability.rank }}
        </div>
      </div>
    </ng-container>
  </div>
  <!-- 互換性 -->
</li>
