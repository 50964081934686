import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';

import { HotSpot } from '../exploded-view.interface';

import { SelectItemService } from '../select-item.service';

@Component({
  selector: '[app-hotspot-item]',
  templateUrl: './hotspot-item.component.html',
  styleUrls: ['./hotspot-item.component.scss'],
})
export class HotspotItemComponent {
  /**
   * HotSpot (インプット)
   */
  @Input() hotSpot!: HotSpot;

  /**
   * 画像サイズ (インプット)
   */
  @Input() set imageSize(value: { width: number; height: number }) {
    this._imageSize = value;

    if (this._imageSize) {
      const x =
        ((this.hotSpot.x_coordinate + this.hotSpot.hotspot_width / 2) /
          this._imageSize.width) *
        100;
      this.xHotSpot = String(x) + '%';
      this.hotSpotCircle.nativeElement.setAttribute('cx', this.xHotSpot);

      const y =
        ((this.hotSpot.y_coordinate + this.hotSpot.hotspot_height / 2) /
          this._imageSize.height) *
        100;
      this.yHotSpot = String(y) + '%';
      this.hotSpotCircle.nativeElement.setAttribute('cy', this.yHotSpot);
    }
  }

  public _imageSize!: { width: number; height: number };

  get imageSize(): { width: number; height: number } {
    return this._imageSize;
  }

  /**
   * HotSpot X座標 (%)
   */
  public xHotSpot = '0%';

  /**
   * HotSpot Y座標 (%)
   */
  public yHotSpot = '0%';

  /**
   * ViewChild ホットスポット
   */
  @ViewChild('hotSpotCircle', { static: true })
  private hotSpotCircle!: ElementRef;

  /**
   * 選択状態か否か
   */
  public isSelected!: boolean;

  /**
   * 選択アイテムリスト
   */
  public selectItemIndex$?: Observable<boolean[]>;

  /**
   * コンストラクタ
   */
  constructor(private selectItemService: SelectItemService) {
    this.selectItemIndex$ = this.selectItemService.valueChanges;

    this.selectItemIndex$.subscribe((index) => {
      // ホットスポットあるいはBOMが選択されたら、ホットスポットの選択状態を変更する
      if (this.hotSpot) {
        if (index[Number(this.hotSpot.item_no) - 1]) {
          this.isSelected = true;
        } else this.isSelected = false;
      }
    });
  }

  /**
   * 初期処理
   * EV画像サイズ、Hotspot位置をpxから%へ計算する
   */
  onClickHotspot(itemNo: string): void {
    this.selectItemService.changeItemIndex(itemNo);
  }
}
