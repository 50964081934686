import {
  Component,
  HostListener,
  OnInit,
  Injector,
  Input,
  LOCALE_ID,
} from '@angular/core';
import { Observable } from 'rxjs';

import { Dialog } from '@angular/cdk/dialog';

import { Hit } from '../result-item.service';

import { Bookmark, BookmarkService } from '../../shared/bookmark.service';

import { ConfimationDialogComponent } from '../../layout/confimation-dialog/confimation-dialog.component';

@Component({
  selector: 'app-search-result-item',
  templateUrl: './search-result-item.component.html',
  styleUrls: ['./search-result-item.component.scss'],
})
export class SearchResultItemComponent implements OnInit {
  /**
   * 検索結果アイテム
   */
  @Input() hit!: Hit;

  /**
   * インプットキーワード
   */
  @Input() keywords = '';

  /**
   * タグの表示
   */
  @Input() isShowTags = true;

  /**
   * リンクの設定
   */
  @Input() isPDFLink = false;

  /**
   * モバイル幅の判定
   */
  public isMobile = false;

  /**
   * エラーコードセットの数が５つ以上の時に目次をさらに表示する
   */
  public more = false;

  /**
   * ロケールid
   */
  public locale!: string;

  /**
   * show more 文字列
   */
  public showMore = $localize`show more`;

  /**
   * show less 文字列
   */
  public showLess = $localize`show less`;

  /**
   * ブックマーク
   */
  public bookmarks$?: Observable<Array<Bookmark>>;

  /**
   *
   */
  public isBookmarked = false;

  /**
   * constructor
   * @param injector
   */
  constructor(
    private injector: Injector,
    private dialog: Dialog,
    private bookmarkService: BookmarkService
  ) {
    this.locale = this.injector.get(LOCALE_ID);
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
    const viewportWidth = this.width;
    if (viewportWidth > 760) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }

    this.bookmarks$ = this.bookmarkService.bookmarksChanges;
    this.bookmarks$.subscribe((bookmarks) => {
      const index = bookmarks.findIndex(
        (bookmark) =>
          bookmark.locale === this.locale &&
          bookmark.doc_no === this.hit.doc_no &&
          bookmark.doc_type === this.hit.doc_type
      );

      if (index === undefined || index === -1) {
        this.isBookmarked = false;
      } else {
        this.isBookmarked = true;
      }
    });
  }

  /**
   * ウィンドウのリサイズでViewportScrollerのオフセットを変更する
   */
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const viewportWidth = this.width;
    if (viewportWidth > 760) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  /**
   * ドキュメントタイプの背景色の設定
   * @param docType
   * @returns
   */
  setDocTypeColor(docType: string): string {
    let color = '';
    switch (docType) {
      case 'DM':
        color = '#bd362f';
        break;
      case 'UM':
        color = '#51a351';
        break;
      case 'SI':
        color = '#005686';
        break;
      case 'EV':
        color = '#FD8C34';
        break;
      case 'QM':
        color = '#4aacc5';
        break;
      case 'SM':
        color = '#E6B8B7';
        break;
    }
    return color;
  }

  /**
   * PDFの表示でPageViewをカウントする
   * @param locale
   * @param docType
   * @param docNo
   * @param pdfPath
   */
  sendPageView(
    locale: string,
    docType: string,
    docNo: string,
    pdfPath: string
  ): void {}

  /**
   *
   * @param event
   */
  onClickAddBookmark(event: any, item: Hit): void {
    if (this.isBookmarked) {
      const dialogRef = this.dialog.open<string>(ConfimationDialogComponent, {
        width: '250px',
        data: {
          title: 'Confirm',
          message: 'Are you sure want to delete the bookmark from list?',
        },
        autoFocus: '__non_existing_element__',
      });

      dialogRef.closed.subscribe((result) => {
        if (result) {
          this.bookmarkService.remove(this.locale, item.doc_no, item.doc_type);
        }
      });
    } else {
      var now = new Date();
      this.bookmarkService.add({
        locale: this.locale,
        doc_type: item.doc_type.toUpperCase(),
        doc_no: item.doc_no,
        content_type: item.content_type,
        bookmarked_date: now.toISOString().split('T')[0],
        pdf_path: item.pdf_path,
        name: item.name,
      });
    }
  }

  public get reformattedKeywords() {
    return this.keywords
      ? this.keywords.replace(/("[^"]+")|[\s]+/g, (m: any, g1: any) =>
          g1 ? g1 : ','
        )
      : undefined;
  }

  public get width() {
    return window.innerWidth;
  }
}
