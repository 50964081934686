import { Injectable, Inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { Notify, NotifyService } from '../shared/notify.service';

export interface ScrollItem {
  element: Element;
  index: number;
  isIntersecting: boolean;
  isActive: boolean;
}

export class ScrollSpiedElement implements ScrollItem {
  constructor(
    public readonly element: Element,
    public readonly index: number,
    public isIntersecting: boolean,
    public isActive: boolean
  ) {}
}

export class ScrollSpiedElementGroup {
  public spiedElements: ScrollSpiedElement[];

  constructor(elements: Element[]) {
    this.spiedElements = elements?.map(
      (elem, i) => new ScrollSpiedElement(elem, i, false, false)
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ScrollSpyService {
  /**
   * アクティブ目次のインデックス
   */
  public valusSubject: ReplaySubject<number | null> = new ReplaySubject(1);

  /**
   * 監視対象の要素グループ
   */
  private spiedGroup!: ScrollSpiedElementGroup;

  /**
   * ページ目次のインターセクションオブザーバ
   */
  private observer!: IntersectionObserver;

  /**
   * 上位マージン
   * (ヘッダー、グローバルナビゲーション、コンテンツナビゲーション 、前のページ )
   */
  private rootMargin = this.scrollOffsetDesktop;

  /**
   * 通知オブジェクト
   */
  private notify: Notify;

  /**
   * Constructor
   */
  constructor(
    @Inject('SCROLL_OFFSET_DESKTOP') private scrollOffsetDesktop: number,
    private notifyService: NotifyService
  ) {
    this.notify = this.notifyService.getNotify();
  }

  /**
   * 監視を開始する
   * @param elements 監視対象要素(配列)
   */
  spyOn(elements: Element[]): void {
    this.spiedGroup = new ScrollSpiedElementGroup(elements);

    if (this.notify.show) {
      this.rootMargin += 40;
    }

    const options = {
      rootMargin: this.rootMargin + 'px',
      threshold: 1,
    };

    const isIntersecting = (entry: IntersectionObserverEntry) =>
      entry.isIntersecting || entry.intersectionRatio > 0;

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (isIntersecting(entry)) {
          this.onIntersection(entry);
        }
      });
    }, options);

    elements?.forEach((element) => {
      this.observer.observe(element);
    });
  }

  /**
   * インターセクションの発生時に再度アクティブインデックスを確認する
   * @param entry インターセクションオブザーバエントリー
   */
  onIntersection(entry: IntersectionObserverEntry): void {
    this.spy();
  }

  /**
   * エレメントを検査する
   */
  public spy(): void {
    const viewportHeight = this.height;

    let prevSpiedElem: ScrollSpiedElement | null;

    this.spiedGroup.spiedElements.forEach((spiedElem) => {
      spiedElem.isActive = false;

      if (
        spiedElem.element.getBoundingClientRect().top > this.rootMargin &&
        spiedElem.element.getBoundingClientRect().top < this.rootMargin + 5
      ) {
        spiedElem.isActive = true;
        this.valusSubject.next(spiedElem.index);
      }

      if (
        spiedElem.element.getBoundingClientRect().top >= this.rootMargin + 5 &&
        spiedElem.element.getBoundingClientRect().top <= viewportHeight
      ) {
        if (!prevSpiedElem) {
          spiedElem.isActive = true;
          this.valusSubject.next(spiedElem.index);
        }
        if (
          prevSpiedElem &&
          prevSpiedElem.element.getBoundingClientRect().top < this.rootMargin
        ) {
          prevSpiedElem.isActive = true;
          this.valusSubject.next(prevSpiedElem.index);
        }
        if (
          prevSpiedElem &&
          prevSpiedElem.element.getBoundingClientRect().top >= this.rootMargin
        ) {
          spiedElem.isActive = false;
        }
      } else if (
        spiedElem.element.getBoundingClientRect().top > viewportHeight
      ) {
        if (
          prevSpiedElem &&
          prevSpiedElem.element.getBoundingClientRect().top < this.rootMargin
        ) {
          prevSpiedElem.isActive = true;
          this.valusSubject.next(prevSpiedElem.index);
        }
      } else if (
        spiedElem.element.getBoundingClientRect().top < this.rootMargin
      ) {
        if (
          this.spiedGroup.spiedElements[
            this.spiedGroup.spiedElements.length - 1
          ] === spiedElem
        ) {
          spiedElem.isActive = true;
          this.valusSubject.next(spiedElem.index);
        }
      }
      prevSpiedElem = spiedElem;
    });
  }

  public get height() {
    return window.innerHeight;
  }
}
