import { MatPaginatorIntl } from '@angular/material/paginator';
import { NavService } from '../app/shared/nav.service';
import { PaginatorIntl } from '../app/shared/paginator-intl';

export const environment = {
  production: true,
  templatePath: "../app/app.component.public.html",
  apiServer: {
    baseUrl: "https://api.si.shimano.com/api/public/v1",
    xApiKey: "nq8880iivc9l7aeqhm38d6f85ese5t",
    local: false,
    auth: false,
  },
  supportUrl: "https://bike.shimano.com",
  providers: [
    { provide: "ANALYTICS_ID", useValue: "G-ZZXLPFF7W0" },
    { provide: "SCROLL_OFFSET_MOBILE", useValue: 50 },
    { provide: "SCROLL_OFFSET_DESKTOP", useValue: 145 },
    { provide: "NavService", useClass: NavService },
    { provide: MatPaginatorIntl, useValue: PaginatorIntl() },
    {
      provide: "BREADCRUMBS",
      useValue: [],
    },
  ],
};
