<aside class="flex w-full flex-col">
  <!-- Sidebar component, swap this element with another sidebar if you like -->
  <div class="flex flex-grow flex-col overflow-y-auto bg-white pb-4 pt-2">
    <div class="my-4 px-2">
      <div class="flex w-full md:ml-0">
        <label for="search_field" class="sr-only">Search</label>
        <div class="relative w-full text-gray-400 focus-within:text-gray-700">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2"
          >
            <app-search-solid-icon size="16"></app-search-solid-icon>
          </div>
          <input
            #filterRef
            id="search_field"
            class="h-full w-full border-b border-gray-300 py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 opacity-70 transition duration-500 ease-in-out focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-bluemine-500 sm:text-sm"
            placeholder="Search"
            type="text"
            name="filter"
            (keyup.enter)="onClickFilter($event)"
          />
        </div>
      </div>
    </div>
    <div class="mx-2 mb-36">
      <p *ngIf="!(nodes$ | async)?.length" class="text-center text-base">
        No results found
      </p>
      <nav class="pb-6">
        <app-manual-toc-item
          *ngFor="let node of nodes$ | async"
          [manual]="manual"
          [pageId]="pageId"
          [filter]="filter"
          [node]="node"
        >
        </app-manual-toc-item>
      </nav>
    </div>
  </div>
</aside>
