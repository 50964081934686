<ul
  class="grid grid-flow-row grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-1"
>
  <li
    *ngFor="let childItem of tags"
    class="px-2 py-2 h-full min-h-16 grid cursor-pointer"
    (click)="onClickTag(childItem.key, category)"
    [ngClass]="{
      'bg-gray-300 shadow': childItem.selected === true
    }"
  >
    <a href="" class="w-36 grid justify-items-center place-self-center"
      ><img
        *ngIf="childItem.icon !== ''"
        [ngClass]="{
          'w-28': category === 'series',
          'h-14': category === 'component'
        }"
        src="/images/common/manual/{{ childItem.icon }}"
        alt="{{ childItem.key }}"
      />
      <p
        *ngIf="
          (childItem.icon !== '' && category !== 'series') ||
          childItem.icon === ''
        "
        class="text-center capitalize pt-1"
        [ngClass]="{
          'text-xs': childItem.icon !== '',
          'text-lg font-normal tracking-wide text-gray-700':
            childItem.icon === '' && category !== 'component',
          'text-sm font-normal tracking-wide text-gray-700':
            childItem.icon === '' && category === 'component'
        }"
      >
        {{ childItem.key }}
      </p>
    </a>
  </li>
</ul>
