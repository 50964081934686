import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../shared/apiResponse.interface';

export interface packageMaterialInfo {
  ean_code: string;
  product?: string;
  product_code?: string;
  material_infos?: materialInfo[];
  status?: string;
}

export interface materialInfo {
  packaging_type: string;
  material_code: string;
  sorting_instruction: string;
}

@Injectable({
  providedIn: 'root',
})
export class PmiService {
  /**
   * PMI Subject
   */
  private pmiSubject = new ReplaySubject<packageMaterialInfo | null>(1);

  /**
   * PMI (コンテンツ)
   */
  private pmi!: packageMaterialInfo | null;

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /**
   * valueChanges
   */
  get valueChanges(): Observable<packageMaterialInfo | null> {
    return this.pmiSubject.asObservable();
  }

  /**
   * キーワードで絞り込まれたPMIを取得し、基本情報(Observable)を返す
   * @param keyword キーワード
   * @returns
   */
  getPmiWithKeyword(keyword: string) {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    this.http
      .get<ApiResponse<any>>(
        `${environment.apiServer.baseUrl}/compliance/pmis/${keyword}` +
          (environment.apiServer.local ? '.json' : ''),
        { headers }
      )
      .subscribe(
        (response) => {
          this.pmi = {
            ean_code: response.data.ean_code,
            product: response.data.product,
            product_code: response.data.product_code,
            material_infos: response.data.material_infos,
          };

          this.pmiSubject.next(this.pmi);
        },
        (error) => {
          this.pmi = {
            ean_code: keyword,
            status: 'not_found',
          };

          this.pmiSubject.next(this.pmi);
        }
      );
  }

  clear() {
    this.pmi = null;

    this.pmiSubject.next(this.pmi);
  }
}
