<div
  class="relative z-30 flex h-full min-h-72 border-t bg-bluemine-500 lg:hidden"
  role="dialog"
  aria-modal="true"
>
  <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
        From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
        -->
  <div
    [@SliderAnimation]="_level"
    class="relative flex w-full flex-col overflow-y-hidden overscroll-none transition ease-in-out"
  >
    <ng-container #tabComponentContainer></ng-container>
  </div>
</div>
