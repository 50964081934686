import { Injectable, Inject } from '@angular/core';
declare let gtag: any; // グローバル変数gtagを解決

@Injectable({
  providedIn: 'root',
})
export class GaService {
  constructor(@Inject('ANALYTICS_ID') private analyticsId: string) {}

  /**
   * useGA
   */
  private useGA(): boolean {
    return typeof gtag !== undefined;
  }

  /**
   * config
   * @returns
   */
  config(): void {
    if (!this.useGA()) {
      return;
    }

    //    gtag('config', this.analyticsId, { send_page_view: false });
    // gtag('config', 'UA-28948671-2', { send_page_view: false });
  }

  /**
   * sendPageView
   * @param url URLのパス。スラッシュで始まる必要がある。
   */
  sendPageView(url: string): void {
    if (!this.useGA()) {
      return;
    }
    if (!url.startsWith('/')) {
      url = `/${url}`;
    }

    // gtag('event', 'page_view', {
    //   send_to: this.analyticsId,
    //   page_path: url,
    // });

    // gtag('event', 'page_view', {
    //   send_to: 'UA-28948671-2',
    //   page_path: url,
    // });
  }
  /**
   * sendEvent
   * @param eventCategory (e.g. 'Video')
   * @param eventAction (e.g. 'play')
   * @param eventLabel (e.g. 'play campaign CM')
   */
  sendEvent(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: any
  ): void {
    if (!this.useGA()) {
      return;
    }
    gtag('event', eventName, {
      event_category: eventCategory,
      event_action: eventAction,
      event_label: eventLabel,
    });
  }
}
