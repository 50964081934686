import {
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

import { MenuNode } from '../menu-node.interface';
import { NavSubMenuListComponent } from '../nav-sub-menu-list/nav-sub-menu-list.component';
import { NavService } from '../nav.service';

const left = [
  group([
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
      optional: true,
    }),
    query(
      ':enter',
      [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('200ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

const right = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
    optional: true,
  }),
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('200ms', style({ transform: 'translateX(-100%)', opacity: 0 })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

@Component({
  selector: 'lib-nav-sub-menu',
  animations: [
    trigger('SliderAnimation', [
      transition(':increment', right),
      transition(':decrement', left),
    ]),
  ],
  templateUrl: './nav-sub-menu.component.html',
  styleUrls: ['./nav-sub-menu.component.scss'],
})
export class NavSubMenuComponent implements OnInit {
  /**
   * ホスト クラス
   */
  @HostBinding('class') class =
    'relative max-w-screen-xl w-full mt-12 flex flex-col bg-bluemine-500';

  /**
   * 通知領域が開いている状態であるか
   */
  @Input() set notify(value: boolean) {
    this._notify = value;
    if (this._notify) {
      this.class =
        'relative max-w-screen-xl w-full mt-24 flex flex-col bg-bluemine-500';
    } else {
      this.class =
        'relative max-w-screen-xl w-full mt-12 flex flex-col bg-bluemine-500';
    }
  }

  /**
   * 通知領域の開閉状態を保持する
   */
  public _notify = true;

  /**
   * Getter notify
   */
  get notify(): boolean {
    return this._notify;
  }

  /**
   * (ViewChild) 選択されたタブのコンテンツ表示域
   */
  @ViewChild('tabComponentContainer', { read: ViewContainerRef, static: true })
  public tabComponentContainer!: ViewContainerRef;

  /**
   * メニューの階層
   */
  public _level = 0;

  /**
   * メニューアイテム
   */
  public menuItems: Array<MenuNode>;

  /**
   * コンストラクタ
   * @param navService
   */
  constructor(@Inject('NavService') private navService: NavService) {
    this.menuItems = this.navService.getNav();
  }

  /**
   * ngOninit
   */
  ngOnInit(): void {
    const componentRef = this.tabComponentContainer.createComponent(
      NavSubMenuListComponent
    );
    componentRef.instance.current = this.menuItems;
    componentRef.instance.lower.subscribe((event) => this.onClick(event));
    componentRef.instance.upper.subscribe((event) => this.onClickBack(event));
  }

  /**
   * クリックで下の階層を表示する
   * @param node
   */
  public onClick(node: any): void {
    this.tabComponentContainer.clear();

    const componentRef = this.tabComponentContainer.createComponent(
      NavSubMenuListComponent
    );

    componentRef.instance.current = node.children;
    componentRef.instance.prev = node;
    componentRef.instance.lower.subscribe((event) => this.onClick(event));
    componentRef.instance.upper.subscribe((event) => this.onClickBack(event));

    this._level++;
  }

  /**
   * クリックで上の階層を表示する
   * @param nodes
   */
  public onClickBack(nodes: any): void {
    this.tabComponentContainer.clear();

    const componentRef = this.tabComponentContainer.createComponent(
      NavSubMenuListComponent
    );

    componentRef.instance.current = this.menuItems;
    componentRef.instance.lower.subscribe((event) => this.onClick(event));
    componentRef.instance.upper.subscribe((event) => this.onClickBack(event));

    this._level--;
  }
}
