import { Component } from '@angular/core';

import { BaseSolidIconComponent } from '../base-solid-icon.component';

@Component({
  selector: 'app-filter-solid-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M3 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-.293.707L12 11.414V15a1 1 0 0 1-.293.707l-2 2A1 1 0 0 1 8 17v-5.586L3.293 6.707A1 1 0 0 1 3 6V3z"
      clip-rule="evenodd"
    />
  </svg>`,
})
export class FilterSolidIconComponent extends BaseSolidIconComponent {}
