import { Component } from '@angular/core';

import { BaseOutlineIconComponent } from '../base-outline-icon.component';

@Component({
  selector: 'app-document-download-outline-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
    />
  </svg>`,
})
export class DocumentDownloadOutlineIconComponent extends BaseOutlineIconComponent {}
