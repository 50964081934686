import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  OnInit,
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  ViewChild,
} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

import { environment } from '../../environments/environment';

import { ErrorSetService } from './error-set.service';
import { Notify, NotifyService } from '../shared/notify.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  providers: [...environment.providers],
})
export class ErrorComponent implements OnInit, AfterViewInit {
  /**
   * フィルタ要素に対する参照 (ViewChild)
   */
  @ViewChild('filterRef') filterRef!: ElementRef;

  /**
   * エラーコードセット (Observable)
   */
  public errorSets$!: Observable<any>;

  /**
   * 要求されたフィルタのキーワードをセットする (query parameter)
   */
  public filter!: string;

  /**
   * エラーコードセットの数が５つ以上の時に目次をさらに表示する
   */
  public more = false;

  /**
   * id
   */
  public id = '';

  /**
   * 名前
   */
  public name = '';

  /**
   * 前付け
   */
  public infoStart = '';

  /**
   * 後付け
   */
  public infoEnd = '';

  /**
   * サイドバーが開いている状態であるか
   */
  public isSidebarOpen = false;

  /**
   * 前付けの開閉
   */
  public isInfoStartOpen = false;

  /**
   * 通知オブジェクト
   */
  public notify: Notify;

  /**
   * show more 文字列
   */
  public show_more = $localize`show more`;

  /**
   * show less 文字列
   */
  public show_less = $localize`show less`;

  /**
   * コンストラクタ
   * @param title Title
   * @param meta Meta
   * @param router Router
   * @param activateRoute ActivatedRoute
   * @param scrollOffsetMobile オフセット
   * @param scrollOffsetDesktop オフセット
   * @param errorSetService エラーセットサービス
   * @param notifyService 通知サービス
   */
  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    @Inject('SCROLL_OFFSET_MOBILE') private scrollOffsetMobile: number,
    @Inject('SCROLL_OFFSET_DESKTOP') private scrollOffsetDesktop: number,
    private errorSetService: ErrorSetService,
    private notifyService: NotifyService
  ) {
    this.notify = this.notifyService.getNotify();
    this.title.setTitle(`Error & Warning | Manuals & Technical Documents`);

    this.errorSets$ = this.errorSetService.valueChanges;
  }

  /**
   * OnInit
   */
  async ngOnInit(): Promise<void> {
    if (environment.apiServer.auth) {
      await this.errorSetService.init();
    }

    this.activateRoute.queryParams.subscribe((val) => {
      this.filter = this.activateRoute.snapshot.queryParams.q;
      this.setViewportScrollerOffset();

      if (this.filter) {
        this.errorSetService
          .getErrorSetsWithKeywords('stp0a', this.filter)
          .pipe(take(1))
          .subscribe((infoSubject) => {
            this.name = infoSubject.name;
            this.meta.addTag({
              name: 'description',
              content: infoSubject.description,
            });
          });
      } else {
        this.errorSetService
          .getErrorSets('stp0a')
          .pipe(take(1))
          .subscribe((infoSubject) => {
            this.id = infoSubject.id;
            this.name = infoSubject.name;
            this.meta.addTag({
              name: 'description',
              content: infoSubject.description,
            });
            this.infoStart = infoSubject.infoStart;
            this.infoEnd = infoSubject.infoEnd;
          });
      }
    });
  }

  /**
   * (AfterViewInit)
   */
  ngAfterViewInit(): void {
    if (this.filter) {
      this.filterRef.nativeElement.value = this.filter.replace(/,/g, ' ');
    }
  }

  /**
   * ウィンドウのリサイズでViewportScrollerのオフセットを変更する
   */
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setViewportScrollerOffset();
  }

  /**
   * ViewportScroller の オフセット を変更する
   */
  private setViewportScrollerOffset(): void {
    const viewportWidth = this.width;
    if (viewportWidth <= 760) {
      if (!this.notify.show) {
        this.viewportScroller.setOffset([0, this.scrollOffsetMobile]);
      } else {
        this.viewportScroller.setOffset([0, this.scrollOffsetMobile + 40]);
      }
    } else {
      if (!this.notify.show) {
        this.viewportScroller.setOffset([0, this.scrollOffsetDesktop]);
      } else {
        this.viewportScroller.setOffset([0, this.scrollOffsetDesktop + 40]);
      }
    }
  }

  /**
   * フィルタの入力でエラーセットを絞り込む
   * @param event イベント
   */
  onClickFilter(event: any): void {
    if (event.target.value) {
      this.router.navigate(['error'], {
        queryParams: { q: event.target.value.replace(/\s/g, ',') },
      });
    } else {
      this.router.navigate(['error']);
    }
  }

  /**
   * スマートフォン用サイドバーを開く
   */
  public openSidebar(): void {
    this.isSidebarOpen = true;
  }

  /**
   * スマートフォン用サイドバーを閉じる
   */
  public closeSidebar(): void {
    this.isSidebarOpen = false;
  }

  /**
   * 前付けを開閉する
   */
  public toggleInfoStart(): void {
    this.isInfoStartOpen = !this.isInfoStartOpen;
  }

  /**
   * 通知表示を停止する
   */
  closeNotify(): void {
    this.notify = this.notifyService.setNotifyDisable();
    this.setViewportScrollerOffset();
  }

  public get width() {
    return window.innerWidth;
  }
}
