import {
  AfterViewInit,
  AfterViewChecked,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription, zip } from 'rxjs';
import {
  ActivatedRoute,
  NavigationStart,
  NavigationEnd,
  Router,
  Scroll,
} from '@angular/router';

import { ViewportScroller } from '@angular/common';
import {
  animate,
  trigger,
  state,
  style,
  transition,
} from '@angular/animations';

import { PageContentService } from '../page-content.service';
import { TocItem, PageTocService } from '../page-toc.service';
import { ScrollSpyService } from '../scroll-spy.service';
import { Notify } from '../../shared/notify.service';
import { ModalService } from 'shared';

import { SvgViewerComponent } from '../svg-viewer/svg-viewer.component';
import { SafeHtml } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';

// Helpers
function querySelectorAll<K extends keyof HTMLElementTagNameMap>(
  parent: Element,
  selector: K
): HTMLElementTagNameMap[K][];
function querySelectorAll<K extends keyof SVGElementTagNameMap>(
  parent: Element,
  selector: K
): SVGElementTagNameMap[K][];
function querySelectorAll<E extends Element = Element>(
  parent: Element,
  selector: string
): E[];
function querySelectorAll(parent: Element, selector: string): Array<Element> {
  // Wrap the `NodeList` as a regular `Array` to have access to array methods.
  // NOTE: IE11 does not even support some methods of `NodeList`, such as
  //       [NodeList#forEach()](https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach).
  return Array.from(parent.querySelectorAll(selector));
}

export interface Manual {
  docType: string;
  docId: string;
}

@Component({
  selector: 'app-manual-page',
  templateUrl: './manual-page.component.html',
  styleUrls: ['./manual-page.component.scss'],
  animations: [
    trigger('PageAnimation', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0.2 })),
      transition('false => true', animate(100)),
      transition('true => false', animate('100ms 300ms')),
    ]),
  ],
})
export class ManualPageComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit, AfterViewChecked
{
  public _show: boolean = true;

  /**
   * ページコンテンツ View
   */
  @ViewChild('pageContent', { static: true })
  private pageContentContainer!: ElementRef;

  /**
   * ページ目次 View
   */
  @ViewChild('pageToc', { static: true }) private pageTocContainer!: ElementRef;

  /**
   * ページ本文 HTMLコンテンツ（API取得）
   */
  public content$?: Observable<SafeHtml>;

  /**
   * マニュアルタイプと記号
   */
  @Input() docType!: string;

  /**
   * マニュアルタイプと記号
   */
  @Input() docId!: string;

  /**
   * ページ本文のId
   */
  @Input() pageId!: string;

  /**
   * フィルタキーワード
   */
  @Input() filter!: string;

  /**
   * メニューが開いている状態であるか
   */
  @Input() set notify(value: Notify) {
    this._notify = value;
    this.setViewportScrollerOffset();
    this.pageTocService.resetScrollSpy();
  }

  @Input() previousPage: any;

  @Input() nextPage: any;

  /**
   * 通知領域の開閉状態を保持する
   */
  public _notify = { show: false, message: '' };

  /**
   * Getter notify
   */
  get notify(): Notify {
    return this._notify;
  }

  /**
   * ページ目次（ページ本文から生成）
   */
  public tocList$!: Observable<TocItem[]>;

  /**
   * 表示されているページ目次のインデックス
   */
  public activeItemIndex$?: Observable<number | null>;

  /**
   * ページ目次のサブスクリプション
   */
  private tocListSubscription!: Subscription;

  /**
   * モーダルコンポーネント(動的に注入する)
   */
  public modalComponent: any = null;

  /**
   * モーダルコンテンツ（HTMLノード）
   */
  public modalContent: any = null;

  /**
   * モーダルのサブスクリプション
   */
  private modalSubscription!: Subscription;

  private onChange: boolean = false;

  /**
   * Constractor
   * @param router: Router
   * @param renderer Renderer2
   * @param pageContentService ページコンテンツサービス
   * @param pageTocService ページ目次サービス
   * @param scrollSpyService スクロールスパイサービス
   * @param modalService モーダルサービス
   */
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private renderer: Renderer2,
    private viewportScroller: ViewportScroller,
    @Inject('SCROLL_OFFSET_MOBILE') private scrollOffsetMobile: number,
    @Inject('SCROLL_OFFSET_DESKTOP') private scrollOffsetDesktop: number,
    private pageContentService: PageContentService,
    private pageTocService: PageTocService,
    private scrollSpyService: ScrollSpyService,
    private modalService: ModalService
  ) {
    this.content$ = this.pageContentService.valueChanges;
    this.content$.subscribe(() => {
      this._show = true;
    });

    this.tocList$ = this.pageTocService.valueChanges;
    this.activeItemIndex$ = this.scrollSpyService.valusSubject;

    this.activateRoute.queryParams.subscribe((val) => {
      if (this.activateRoute.snapshot.queryParams.q) {
        this.filter = this.activateRoute.snapshot.queryParams.q;
      } else if (this.activateRoute.snapshot.firstChild?.queryParams.q) {
        this.filter = this.activateRoute.snapshot.firstChild?.queryParams.q;
      } else {
        this.filter = '';
      }
    });
  }

  /**
   * OnInit
   */
  async ngOnInit(): Promise<void> {
    if (environment.apiServer.auth) {
      await this.pageContentService.init();
    }

    if (this.pageContentContainer !== undefined) {
      this.pageTocService.initialize(this.pageContentContainer.nativeElement);
    }
    this.modalSubscription = this.modalService.closeEventObservable$.subscribe(
      () => {
        // プロパティ modal に null をセットすることでコンポーネントを破棄する
        // このタイミングで ModalComponent では ngOnDestroy が走る
        this.modalComponent = null;
      }
    );
  }

  /**
   * AfterViewInit
   */
  ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this._show = false;
      } else if (event instanceof NavigationEnd) {
      } else if (event instanceof Scroll) {
        if (event instanceof Scroll) {
          if (event.anchor !== null) {
            setTimeout(() => {
              this._show = true;
            }, 0);
          }
        }
      }
    });

    if (this.pageContentContainer !== undefined) {
      this.pageContentContainer.nativeElement
        .querySelectorAll('svg')
        .forEach((element: any) => {
          /**
           * ビューポートが小さい時に画像サイズを調整する
           */
          const viewportWidth = this.width;
          if (viewportWidth < 450) {
            if (
              element.parentNode.clientWidth > viewportWidth * 0.8 &&
              element.parentNode.clientWidth < viewportWidth * 1.2
            ) {
              this.renderer.setStyle(element.parentNode, 'width', `300px`);
            }
          }
          /**
           * svg画像をクリックした時に拡大できるようにする
           */
          element.addEventListener(
            'click',
            this.onClickSvg.bind(this, element)
          );
        });
    }

    this.tocListSubscription = this.tocList$.subscribe((val) => {
      if (val.length) {
        const pageElem =
          this.pageContentContainer.nativeElement.querySelector('h1');
        const childElem =
          this.pageContentContainer.nativeElement.querySelector('inpage-toc');
        if (childElem) {
          childElem.parentNode.removeChild(childElem);
        }
        pageElem.insertAdjacentHTML('afterend', '<inpage-toc></inpage-toc>');
      }
    });

    const content$ = this.pageContentService.valueChanges;
    const fragment$ = this.activateRoute.fragment;

    content$.subscribe(() => {
      this._show = true;
    });

    zip(content$, fragment$).subscribe(([content, fragment]) => {
      setTimeout(() => {
        this.setViewportScrollerOffset();
        if (fragment !== null) {
          this.viewportScroller.scrollToAnchor(fragment);
        }
      }, 300);
    });
  }

  ngAfterViewChecked(): void {
    if (this.pageContentContainer !== undefined) {
      this.pageContentContainer.nativeElement
        .querySelectorAll('svg')
        .forEach((element: any) => {
          element.querySelectorAll('mark').forEach((element: any) => {
            element.outerHTML = `<tspan filter="url(#solid)" id='svg_highlight'>${element.innerHTML}</tspan>`;
          });

          /**
           * ビューポートが小さい時に画像サイズを調整する
           */
          const viewportWidth = this.width;
          if (viewportWidth < 450) {
            if (
              element.parentNode.clientWidth > viewportWidth * 0.7 &&
              element.parentNode.clientWidth < viewportWidth
            ) {
              if (
                element.clientWidth > element.parentNode.clientWidth &&
                element.clientWidth < element.parentNode.clientWidth * 1.2
              )
                this.renderer.setStyle(
                  element,
                  'width',
                  element.parentNode.clientWidth
                );
            }
          }

          let svg = element;

          if (!element.querySelectorAll('#solid').length) {
            let defs = document.createElementNS(
              'http://www.w3.org/2000/svg',
              'defs'
            );

            let filter = document.createElementNS(
              'http://www.w3.org/2000/svg',
              'filter'
            );

            filter.setAttribute('x', '-0.1');
            filter.setAttribute('y', '-0.1');
            filter.setAttribute('width', '1.2');
            filter.setAttribute('id', 'solid');

            let feFlood = document.createElementNS(
              'http://www.w3.org/2000/svg',
              'feFlood'
            );

            feFlood.setAttribute('flood-color', 'rgba(255,255,0)');
            feFlood.setAttribute('flood-opacity', '0.3');
            feFlood.setAttribute('result', 'bg');

            let feComposite = document.createElementNS(
              'http://www.w3.org/2000/svg',
              'feComposite'
            );

            feComposite.setAttribute('in', 'SourceGraphic');
            feComposite.setAttribute('operator', 'xor');

            filter.appendChild(feFlood);
            filter.appendChild(feComposite);
            defs.appendChild(filter);
            svg.prepend(defs);
          }
        });
    }
  }

  ngDoCheck() {
    if (!this.onChange) {
      this._show = true;
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.onChange = true;

    if (environment.apiServer.auth) {
      await this.pageContentService.init();
    }

    if (this.filter) {
      this.pageContentService
        .getContentWithKeywords(
          { docType: this.docType, docId: this.docId },
          this.pageId,
          this.filter
        )
        .subscribe(
          (res: any) => {},
          (error: any) => {
            this.router.navigate([`${this.docType}/${this.docId}`], {
              queryParams: { q: this.filter },
            });
          }
        );
    } else {
      this.pageContentService
        .getContent({ docType: this.docType, docId: this.docId }, this.pageId)
        .subscribe();
    }

    if (this.pageContentContainer !== undefined) {
      this.pageTocService.initialize(this.pageContentContainer.nativeElement);
    }

    this.modalSubscription = this.modalService.closeEventObservable$.subscribe(
      () => {
        // プロパティ modal に null をセットすることでコンポーネントを破棄する
        // このタイミングで ModalComponent では ngOnDestroy が走る
        this.modalComponent = null;
      }
    );

    this.onChange = false;
  }

  /**
   * ViewportScroller の オフセット を変更する
   */
  private setViewportScrollerOffset(): void {
    const viewportWidth = this.width;
    if (viewportWidth <= 760) {
      if (!this.notify.show) {
        this.viewportScroller.setOffset([0, this.scrollOffsetMobile]);
      } else {
        this.viewportScroller.setOffset([0, this.scrollOffsetMobile + 40]);
      }
    } else {
      if (!this.notify.show) {
        this.viewportScroller.setOffset([0, this.scrollOffsetDesktop]);
      } else {
        this.viewportScroller.setOffset([0, this.scrollOffsetDesktop + 40]);
      }
    }
  }

  /**
   * OnDestory
   */
  ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
    this.tocListSubscription.unsubscribe();
  }

  /**
   * ページ目次のクリックで指定したid属性までスクロール遷移させる
   * @param id スクロール遷移先のid属性
   */
  public onClickPageToc(fragment: string): void {
    this.scrollSpyService.spy();
  }

  /**
   * SVG要素をのクリックでSVG要素をパネル表示させる
   * @param element SVG要素
   * @param event イベント
   */
  public onClickSvg(element: any, event: any): void {
    const clone = element.cloneNode(true);
    this.modalContent = [[clone]];
    this.setModal();
  }

  /**
   * モーダルコンポーネントをセットする
   */
  private setModal(): void {
    this.modalComponent = SvgViewerComponent;
  }

  public get width() {
    return window.innerWidth;
  }
}
