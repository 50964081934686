<!-- Start left sidebar for mobile -->
<app-left-sidenav [expanded]="_expanded" (close)="closeSidebar()">
  <div class="flex h-8 flex-shrink-0 items-center px-4">
    <div class="flex w-full md:ml-0">
      <label for="search_field" class="sr-only">Search</label>
      <div class="relative w-full text-gray-400 focus-within:text-gray-700">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2"
        >
          <app-search-solid-icon size="16"></app-search-solid-icon>
        </div>
        <input
          #filterRef
          id="search_field"
          class="h-full w-full border border-gray-300 py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 opacity-70 transition duration-500 ease-in-out focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-bluemine-500 sm:text-sm"
          placeholder="Search"
          type="text"
          name="filter"
          (keyup.enter)="onClickFilter($event)"
          autocapitalize="none"
        />
      </div>
    </div>
  </div>
  <nav class="mt-3 h-0 flex-1 overflow-y-auto">
    <app-manual-toc-item
      *ngFor="let node of nodes$ | async"
      [manual]="manual"
      [pageId]="pageId"
      [filter]="filter"
      [node]="node"
      (close)="closeSidebar()"
    >
    </app-manual-toc-item>
  </nav>
</app-left-sidenav>
<!-- End left sidebar for mobile -->
