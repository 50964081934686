import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ApiResponse } from '../shared/apiResponse.interface';

import { ExplodedView, Bom } from './exploded-view.interface';

import { fetchAuthSession } from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  public explodedView!: ExplodedView;

  public boms!: Array<any>;

  /**
   * Exploded View (コンテンツ) Subject
   */
  contentSubject = new ReplaySubject<ExplodedView>(1);

  /**
   * Exploded View BOM Subject
   */
  bomsSubject = new ReplaySubject<any>(1);

  /**
   * valueChanges
   */
  get valueChanges(): Observable<ExplodedView> {
    return this.contentSubject.asObservable();
  }

  /**
   * bom valueChanges
   */
  get bomsValueChanges(): Observable<ExplodedView> {
    return this.bomsSubject.asObservable();
  }

  /**
   * JWT Token
   */
  private token?: string;

  /**
   * コンストラクタ
   */
  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /**
   * サービスの初期化処理を行う
   * @returns Promise<void>
   */
  async init(): Promise<void> {
    if (environment.apiServer.auth) {
      this.token = (await fetchAuthSession()).tokens?.idToken?.toString();
    }
  }

  /**
   * Exploded Vewiの情報を取得する
   * @param docId
   */
  getEvContent(docId: string): void {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.auth) {
      headers = headers.set('Authorization', `Bearer ${this.token}`);
    }

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    this.http
      .get<ApiResponse<ExplodedView>>(
        `${environment.apiServer.baseUrl}/ev/docs/${docId}` +
          (environment.apiServer.local ? '.json' : ''),
        { headers }
      )
      .subscribe((response) => {
        this.explodedView = response.data;
        this.contentSubject.next(this.explodedView);
        this.boms = this.explodedView.boms;
        this.boms?.forEach((bom, index) => {
          bom.updateDate = Date.now();
        });

        this.bomsSubject.next(this.boms);
      });
  }

  /**
   * キーワードで絞り込まれたfaqドメインのエラーコードセットを取得し、基本情報(Observable)を返す
   * @param docId
   * @param keyword キーワード
   */
  getEvContentWithKeywords(docId: string, keyword: string): void {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.auth) {
      headers = headers.set('Authorization', `Bearer ${this.token}`);
    }

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    let params = new HttpParams().set('keywords', keyword);

    this.http
      .get<ApiResponse<ExplodedView>>(
        `${environment.apiServer.baseUrl}/ev/docs/${docId}` +
          (environment.apiServer.local ? '.json' : '') +
          '/filter',
        { headers, params }
      )
      .subscribe((response) => {
        this.explodedView = response.data;
        this.contentSubject.next(this.explodedView);
        this.boms = this.explodedView.boms;
        this.boms?.forEach((bom, index) => {
          bom.updateDate = Date.now();
        });
        this.bomsSubject.next(this.boms);
      });
  }

  /**
   * BOM、Hotspotのインデックス配列を取得する
   */
  getItemIndexList(): boolean[] {
    const boms: Bom[] = this.explodedView?.boms;
    const list: boolean[] = new Array();

    boms?.forEach((bom) => {
      if (bom.bom_index !== '-') {
        if (!list[Number(bom.bom_index) - 1]) {
          list[Number(bom.bom_index) - 1] = false;
        }
      }
    });

    return list;
  }

  notifySelectItemIndex(itemIndexList: any[]): void {
    this.boms?.forEach((bom, index) => {
      const bom_index = Number(bom.bom_index) - 1;

      if (bom_index !== -1 && itemIndexList[bom_index] === true) {
        if (!this.boms[index].isSelected) {
          this.boms[index].isSelected = true;
          this.boms[index].updateDate = Date.now();
        }

        if (
          bom.interchangeabilitys != null &&
          bom.interchangeabilitys.length > 0
        ) {
          this.boms[index].isOpenInterchange = true;
        }
      } else {
        if (this.boms[index].isSelected) {
          this.boms[index].isSelected = false;
          this.boms[index].updateDate = Date.now();
        } else {
          this.boms[index].isSelected = false;
        }

        if (
          bom.interchangeabilitys != null &&
          bom.interchangeabilitys.length > 0
        ) {
          this.boms[index].isOpenInterchange = false;
        }
      }
    });

    // boms をソートする
    this.boms?.sort((x: any, y: any) => {
      if (Number(y.isSelected) !== Number(x.isSelected)) {
        return Number(y.isSelected) - Number(x.isSelected);
      }

      if (y.updateDate !== x.updateDate) {
        return y.updateDate - x.updateDate;
      }

      return 0;
    });

    this.bomsSubject.next(this.boms);
  }
}
