import { Component, OnInit, Input, LOCALE_ID, Injector } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-header',
  animations: [
    trigger('openAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(
          '200ms ease-in',
          style({ transform: 'translateY(0)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(
          '150ms ease-out',
          style({ transform: 'translateY(-2%)', opacity: 0 })
        ),
      ]),
    ]),
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  /**
   * 開閉状態
   */
  public expanded = false;

  /**
   * メニューの開閉状態
   */
  public menuExpanded = false;

  public locale_name? = '';

  /**
   * ロケールの一覧
   */
  @Input() publicLocales = [
    { locale: 'en', name: 'English' },
    { locale: 'ja', name: '日本語' },
    { locale: 'zh-CN', name: '简体中文' },
    { locale: 'zh-TW', name: '繁體中文' },
    { locale: 'fr', name: 'français' },
    { locale: 'es', name: 'españoles' },
    { locale: 'de', name: 'Deutsch' },
    { locale: 'it', name: 'Italiano' },
    { locale: 'cs', name: 'Česky' },
    { locale: 'sk', name: 'Slovensky' },
    { locale: 'hu', name: 'Magyar' },
    { locale: 'ru', name: 'Русский' },
    { locale: 'nl', name: 'Nederlands' },
    { locale: 'sv', name: 'svenska' },
    { locale: 'fi', name: 'suomi' },
    { locale: 'no', name: 'Norsk' },
    { locale: 'pl', name: 'Polski' },
    { locale: 'da', name: 'dansk' },
    { locale: 'lt', name: 'Lietuvių kalba' },
    { locale: 'lv', name: 'Latviski' },
    { locale: 'et', name: 'Eesti' },
    { locale: 'el', name: 'Ελληνικά' },
    { locale: 'sl', name: 'Slovenščina' },
    { locale: 'ro', name: 'Română' },
    { locale: 'bg', name: 'Български' },
    { locale: 'tr', name: 'Türkçe' },
    { locale: 'sr', name: 'Српски' },
    { locale: 'hr', name: 'Hrvatski' },
    { locale: 'pt', name: 'Português' },
    { locale: 'ko', name: '한국어' },
    { locale: 'th', name: 'ไทย' },
    { locale: 'id', name: 'Bahasa Indonesia' },
  ];

  constructor(private injector: Injector, private router: Router) {
    const locale = this.injector.get(LOCALE_ID);

    this.locale_name = this.publicLocales.find(
      (localeObject) => localeObject.locale === locale
    )?.name;
  }

  ngOnInit(): void {}

  /**
   * 言語選択の開閉を切り替える
   */
  public onClick(): void {
    this.expanded = !this.expanded;
  }

  /**
   * メニュー選択の開閉を切り替える
   */
  public onClickMenu(): void {
    this.menuExpanded = !this.menuExpanded;
  }

  /**
   * ロケールを選択する
   * @param locale
   */
  public onClickLocale(locale: string): void {
    window.location.href = `${locale}/${this.router.url}`;
  }
}
