<div>
  <div class="sm:hidden">
    <label for="tabs" class="sr-only">Select a tab</label>
    <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
    <select
      #select
      id="tabs"
      name="tabs"
      (change)="onClick(select.value)"
      class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:border-transparent focus:outline-none focus:ring-1 focus:ring-bluemine-500 sm:text-sm"
    >
      <option [value]="i" *ngFor="let item of tabs; index as i">
        {{ item.label }}
      </option>
    </select>
  </div>
  <div class="hidden sm:block">
    <div class="border-b border-gray-200 px-2">
      <nav class="-mb-px flex" aria-label="Tabs">
        <!-- Current: "border-bluemine-500 text-bluemine-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
        <ul class="flex flex-row space-x-5">
          <li *ngFor="let item of tabs; index as i" class="">
            <a
              href="javascript:void(0)"
              (click)="onClick(i)"
              class="block py-2 px-3 relative whitespace-nowrap font-medium text-sm tracking-wider uppercase"
              [ngClass]="{
                'border-b-4 border-blue-500 text-bluemine-600 ':
                  selectedTab === i,
                'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                  selectedTab !== i
              }"
            >
              {{ item.label }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
