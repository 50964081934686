import { Component, Output, EventEmitter, Input } from '@angular/core';

import { MenuNode } from '../menu-node.interface';

@Component({
  selector: 'lib-nav-sub-menu-list',
  templateUrl: './nav-sub-menu-list.component.html',
  styleUrls: ['./nav-sub-menu-list.component.scss'],
})
export class NavSubMenuListComponent {
  /**
   * 現在表示しているメニューノード
   */
  @Input() current!: Array<MenuNode>;

  /**
   * 直前に表示していたメニューノード
   */
  @Input() prev!: Array<MenuNode>;

  /**
   * 下部へのリクエストを伝える
   */
  @Output() lower: EventEmitter<any> = new EventEmitter();

  /**
   * 上部へのリクエストを伝える
   */
  @Output() upper: EventEmitter<any> = new EventEmitter();

  /**
   * 次へのクリック
   * @param node
   */
  onClickNext(node: MenuNode): void {
    this.lower.emit(node);
  }

  /**
   * 前へのクリック
   * @param node
   */
  onClickPrev(node: Array<MenuNode>): void {
    this.upper.emit(this.prev);
  }
}
