import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import {
  RequestTag,
  ResultItemService,
  RequestTags,
} from '../result-item.service';

export interface Tag {
  name: string;
  category: string;
}

import { SearchScreenStatusService } from '../search-screen-status.service';

@Component({
  selector: 'app-search-result-tagset',
  templateUrl: './search-result-tagset.component.html',
  styleUrls: ['./search-result-tagset.component.scss'],
})
export class SearchResultTagsetComponent implements OnInit, AfterViewInit {
  /**
   * タグ分類
   */
  @Input() tagSetName!: string;

  /**
   * タグ名
   */
  @Input() set tags(value: Array<any>) {
    this._tags = value;
    if (this._tags.length === 0) {
      this.isOpen = false;
    }
  }

  @ViewChild('scrollViewport')
  cdkVirtualScrollViewport!: CdkVirtualScrollViewport;

  /**
   * アコーディオンの開閉状態
   */
  public isOpen = true;

  public isAnySelected = false;

  public isExpanded = false;

  public _tags!: Array<any>;

  public offset = 0;

  public isLoading = false;

  public viewportHeight = 28 * 4;

  /**
   * 選択タグ・オブザーバブル
   */
  public activeTags$: Observable<Array<RequestTag>>;

  public activeTags: Array<RequestTag> = [];

  public expandedState$ =
    this.searchScreenStatusService.expandedState$.asObservable();

  /**
   * コンストラクタ
   * @param resultItemService ResultItemService
   * @param router Router
   */
  constructor(
    private router: Router,
    private resultItemService: ResultItemService,
    private searchScreenStatusService: SearchScreenStatusService
  ) {
    this.activeTags$ = this.resultItemService.requestTagsChanges;
    this.activeTags$.subscribe((activeTags: Array<RequestTag>) => {
      this.activeTags = activeTags;
    });
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
    if (this._tags.filter((item) => item.selected === true).length > 0) {
      this.isAnySelected = true;
    }

    this.expandedState$.subscribe((state) => {
      this.isExpanded = state[this.tagSetName].isExpanded;
    });
  }

  /**
   * AfterViewInit
   */
  ngAfterViewInit(): void {
    this.viewportHeight = this.calculateContainerHeight();
  }

  /**
   * ファセット押下時に選択状態を保存しナビゲートする
   */
  onClickFacet(event: any, category: string, key: string): void {
    let params;
    let queryParams: any = {};

    params = event.target.checked
      ? this.resultItemService.addTag({
          category,
          name: key,
        })
      : this.resultItemService.removeTag({
          category,
          name: key,
        });

    queryParams.doctype = params.docTypes.length
      ? params.docTypes.join(',')
      : undefined;

    queryParams.contenttype = params.contentTypes.length
      ? params.contentTypes.join(',')
      : undefined;

    params.reqTagsMap.forEach((reqTags: RequestTags, category: string) => {
      if (reqTags.names?.length) {
        queryParams = {
          ...queryParams,
          [category]: reqTags.names.join(','),
        };
      }
    });

    queryParams.input_model = params.inputModel ? params.inputModel : undefined;

    queryParams.q = params.keywords
      ? params.keywords.replace(/("[^"]+")|[\s]+/g, (m: any, g1: any) =>
          g1 ? g1 : ','
        )
      : undefined;

    queryParams.sort =
      params.sortKey && params.sortOrder
        ? `${params.sortKey},${params.sortOrder}`
        : undefined;

    this.router.navigate(['manual/search'], {
      queryParams,
    });
  }

  /**
   * 選択したタグを全てクリアする
   */
  onClickClear(): void {
    let params: any = {};
    let queryParams: any = {};

    params = this.resultItemService.removeTags(this.tagSetName);

    queryParams.doctype = params.docTypes.length
      ? params.docTypes.join(',')
      : undefined;

    queryParams.contenttype = params.contentTypes.length
      ? params.contentTypes.join(',')
      : undefined;

    params.reqTagsMap.forEach((reqTags: RequestTags, category: string) => {
      if (reqTags.names?.length) {
        queryParams = {
          ...queryParams,
          [category]: reqTags.names.join(','),
        };
      }
    });

    queryParams.input_model = params.inputModel ? params.inputModel : undefined;

    queryParams.q = params.keywords
      ? params.keywords.replace(/("[^"]+")|[\s]+/g, (m: any, g1: any) =>
          g1 ? g1 : ','
        )
      : undefined;

    queryParams.sort =
      params.sortKey && params.sortOrder
        ? `${params.sortKey},${params.sortOrder}`
        : undefined;

    this.router.navigate(['manual/search'], {
      queryParams,
    });
  }

  /**
   * アコーディオンをクリックしたときにアコーディオンの開閉を反転させる
   */
  public onClick(): void {
    this.isOpen = !this.isOpen;
  }

  /**
   * タグの表示域を広げる
   */
  onClickExpand(): void {
    this.isExpanded = !this.isExpanded;

    this.searchScreenStatusService.setExpandedState(
      this.tagSetName,
      this.isExpanded
    );

    this.viewportHeight = this.calculateContainerHeight();
  }

  /**
   * 200を超えた時に次を取得する
   * @param e
   * @param tagSetName
   */
  onScrolledIndexChange(e: any, tagSetName: string): any {
    if (
      this._tags.length === 200 * (this.offset + 1) &&
      this._tags.length - e < 20 &&
      this.isLoading !== true
    ) {
      this.isLoading = true;
      this.resultItemService
        .fetchFacet(tagSetName, this.offset + 1)
        .subscribe((newTags: any) => {
          this._tags = [...this._tags, ...newTags];
          this.offset = this.offset + 1;
          this.isLoading = false;
        });
    }
  }

  /**
   * cdkVirutalScrollViewportの高さを計算する
   */
  calculateContainerHeight(): number {
    const numberOfItems = this._tags.length;
    const itemHeight = 28;
    const visibleItems = this.isExpanded ? 8 : 4;

    if (this.cdkVirtualScrollViewport !== undefined) {
      this.cdkVirtualScrollViewport.checkViewportSize();
    }

    if (numberOfItems <= visibleItems) {
      return itemHeight * numberOfItems;
    } else {
      return itemHeight * visibleItems;
    }
  }
}
