import { Component } from '@angular/core';

import { BaseOutlineIconComponent } from '../base-outline-icon.component';

@Component({
  selector: 'app-dots-vertical-outline-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
    />
  </svg>`,
})
export class DotsVerticalOutlineIconComponent extends BaseOutlineIconComponent {}
