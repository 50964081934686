import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { MenuNode } from './menu-node.interface';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public node1: Array<MenuNode> = [
    {
      label: "User's Manual",
      url: 'um',
      content:
        "For safety, be sure to read this user's manual throughly before use",
      icon: 'documentTextOutline',
      target: '_self',
    },
    {
      label: "Dealer's Manual",
      url: 'dm',
      content:
        "Dealer's manuals (DM) are intended primarily for use by professional bicycle mechanics.",
      icon: 'documentTextOutline',
      target: '_self',
    },
    {
      label: 'Service Instruction',
      url: 'si',
      content: '...',
      icon: 'documentTextOutline',
      target: '_self',
    },
    {
      label: 'Exploded View',
      url: 'ev',
      content: 'Complete parts assembly diagram needed for re-assembl',
      icon: 'documentTextOutline',
      target: '_self',
    },
    {
      label: 'Update Information',
      url: 'update',
      content: '...',
      target: '_self',
    },
  ];

  public node2: Array<MenuNode> = [
    {
      label: 'Line-up chart',
      url: 'https://productinfo.shimano.com/lineup',
      content:
        'The Line-up chart introduces the recommended products for each category.',
      icon: 'lineupChartOutline',
      target: '_blank',
    },
    {
      label: 'Specifications',
      url: 'https://productinfo.shimano.com/spec',
      content: '...',
      icon: 'specificationsOutline',
      target: '_blank',
    },
    {
      label: 'Compatibiity',
      url: 'https://productinfo.shimano.com/compatibility',
      content: '...',
      icon: 'technicalInformationOutline',
      target: '_blank',
    },
    {
      label: 'Archive',
      url: 'https://productinfo.shimano.com/archive',
      content: '...',
      target: '_blank',
    },
  ];

  public node3: Array<MenuNode> = [
    {
      label: 'SHIMANO E-BIKE SYSTEMS',
      url: 'https://support.itp-sys-service.com/faq/steps',
      content: '...',
      icon: 'stepLogo',
      target: '_self',
    },
    {
      label: 'Connect Tracker',
      url: 'https://support.itp-sys-service.com/faq/connect-tracker',
      content: '...',
      icon: 'stepLogo',
      target: '_self',
    },
  ];

  public node4: Array<MenuNode> = [
    {
      label: 'Regulatory Information',
      url: 'compliance/ri',
      target: '_self',
    },
    {
      label: 'Declaration of Conformity',
      url: 'compliance/doc',
      target: '_self',
    },
    {
      label: 'Safety Data Sheet',
      url: 'compliance/sds',
      target: '_self',
    },
    {
      label: 'Open Source Software',
      url: 'compliance/oss',
      target: '_self',
    },
    {
      label: $localize`RoHS`,
      url: 'compliance/rohs',
      target: '_self',
      locale: ['zh-CN'],
    },
  ];

  public menuItems: Array<MenuNode> = [
    {
      label: 'Manuals',
      url: '',
      target: '_self',
    },
    {
      label: 'Tech Docs',
      url: 'https://productinfo.shimano.com',
      children: this.node2,
      target: '_self',
    },
    {
      label: 'Faq',
      url: 'https://support.itp-sys-service.com/faq',
      children: this.node3,
      target: '_self',
    },
    {
      label: 'Error & Warning',
      url: 'error',
      target: '_self',
    },
    {
      label: 'Compliance',
      url: 'compliance',
      children: this.node4,
      target: '_self',
    },
    {
      label: 'CUES',
      url: 'cues',
      target: '_self',
    },
  ];

  public publicLocales = [
    { locale: 'en', name: 'English' },
    { locale: 'ja', name: '日本語' },
    { locale: 'zh-CN', name: '简体中文' },
    { locale: 'zh-TW', name: '繁體中文' },
    { locale: 'fr', name: 'français' },
    { locale: 'es', name: 'español' },
    { locale: 'de', name: 'Deutsch' },
    { locale: 'it', name: 'Italiano' },
    { locale: 'cs', name: 'Česky' },
    { locale: 'sk', name: 'Slovensky' },
    { locale: 'hu', name: 'Magyar' },
    { locale: 'ru', name: 'Русский' },
    { locale: 'nl', name: 'Nederlands' },
    { locale: 'sv', name: 'svenska' },
    { locale: 'fi', name: 'suomi' },
    { locale: 'no', name: 'Norsk' },
    { locale: 'pl', name: 'Polski' },
    { locale: 'da', name: 'dansk' },
    { locale: 'lt', name: 'Lietuvių kalba' },
    { locale: 'lv', name: 'Latviski' },
    { locale: 'et', name: 'Eesti' },
    { locale: 'el', name: 'Ελληνικά' },
    { locale: 'sl', name: 'Slovenščina' },
    { locale: 'ro', name: 'Română' },
    { locale: 'bg', name: 'Български' },
    { locale: 'tr', name: 'Türkçe' },
    { locale: 'sr', name: 'Српски' },
    { locale: 'hr', name: 'Hrvatski' },
    { locale: 'pt', name: 'Português' },
    { locale: 'ko', name: '한국어' },
    { locale: 'th', name: 'ไทย' },
    { locale: 'id', name: 'Bahasa Indonesia' },
  ];

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  getNav(): Array<MenuNode> {
    this.menuItems.map((menuItem) => {
      if (menuItem.children !== undefined) {
        menuItem.children = menuItem.children.filter((item) => {
          if (item.locale !== undefined) {
            if (item.locale.indexOf(this.locale) !== -1) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        });
      }
    });

    return this.menuItems;
  }

  getPublicLocales(): Array<any> {
    return this.publicLocales;
  }
}
