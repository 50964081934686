import { Component, OnInit, Input } from '@angular/core';

import { Router } from '@angular/router';

import {
  RequestTags,
  ResultItemService,
  Tag,
} from '../../search/result-item.service';

@Component({
  selector: 'app-docs-collection',
  templateUrl: './docs-collection.component.html',
  styleUrls: ['./docs-collection.component.scss'],
})
export class DocsCollectionComponent implements OnInit {
  /**
   * アコーディオンに表示するヘルプデータを指定する
   */
  @Input() tags?: Tag[];

  @Input() category!: string;

  constructor(
    private router: Router,
    private resultItemService: ResultItemService
  ) {}

  ngOnInit(): void {}

  onClickTag(key: string, category: string): boolean {
    this.resultItemService.addTag({ name: key, category: category });
    this.resultItemService.search(true);
    this.resultItemService.clearSuggest();

    // const params = this.resultItemService.getParams();

    // this.resultItemService.setRange(0, 10);

    // const queryParams: any = {};

    // params.reqTagsMap.forEach((reqTags: RequestTags, category: string) => {
    //   if (reqTags.names?.length) {
    //     queryParams[category] = reqTags.names.join(',');
    //   }
    // });

    // if (params.docTypes.length) {
    //   queryParams.doctype = params.docTypes.join(',');
    // }

    // this.router.navigate(['manual/search'], {
    //   queryParams,
    // });

    return false;
  }
}
