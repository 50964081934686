import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manual-list',
  templateUrl: './manual-list.component.html',
  styleUrls: ['./manual-list.component.scss'],
})
export class ManualListComponent implements OnInit {
  public nodes = [
    {
      title: 'XTR',
      children: [
        {
          title: 'Shifting Lever',
        },
        {
          title: 'Seat Post Lever',
        },
        {
          title: 'Disc Brake',
        },
      ],
    },
  ];

  /**
   * サイドバーが開いている状態であるか
   */
  public isSidebarOpen = false;

  constructor(private router: Router) {
    this.router.navigate(['/']);
  }

  ngOnInit(): void {}

  /**
   * スマートフォン用サイドバーを開く
   */
  public openSidebar(): void {
    this.isSidebarOpen = true;
  }

  /**
   * スマートフォン用サイドバーを閉じる
   */
  public closeSidebar(): void {
    this.isSidebarOpen = false;
  }
}
