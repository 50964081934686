import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Error } from '../error-set.interface';

@Component({
  selector: 'app-error-item',
  templateUrl: './error-item.component.html',
  styleUrls: ['./error-item.component.scss'],
})
export class ErrorItemComponent implements OnInit {
  /**
   * アコーディオンに表示するエラーコードデータを指定する
   */
  @Input() error!: Error;

  /**
   * アコーディオンの開閉状態
   */
  public isOpen = false;

  /**
   * コンストラクタ
   * @param sanitizer DomSanitizer
   */
  constructor(private sanitizer: DomSanitizer) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {}

  /**
   * アコーディオンをクリックしたときにアコーディオンの開閉を反転させる
   */
  public onClick(): void {
    this.isOpen = !this.isOpen;
  }
}
