import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NavigationNode, ManualTocService } from '../manual-toc.service';
import { Observable } from 'rxjs';

export interface Manual {
  docType: string;
  docId: string;
}

@Component({
  selector: 'app-manual-toc-mobile',
  templateUrl: './manual-toc-mobile.component.html',
  styleUrls: ['./manual-toc-mobile.component.scss'],
})
export class ManualTocMobileComponent implements OnInit, AfterViewInit {
  /**
   * サイドバーが開いている状態であるか
   */
  @Input() set expanded(value: boolean) {
    this._expanded = value;
  }

  get expanded(): boolean {
    return this._expanded;
  }

  public _expanded = false;

  /**
   * サイドバーのクローズを伝える
   */
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  /**
   * 現在のレベル
   */
  @Input() manual!: Manual;

  /**
   * 現在のレベル
   */
  @Input() pageId!: string;

  /**
   * フィルタキーワード
   */
  public filter!: string | undefined;

  /**
   * フィルタ要素に対する参照 (ViewChild)
   */
  @ViewChild('filterRef') filterRef!: ElementRef;

  /**
   * ドキュメントの目次
   */
  public nodes$?: Observable<NavigationNode[]>;

  /**
   * コンストラクタ
   * @param manualTocService ManualTocService
   */
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private manualTocService: ManualTocService
  ) {
    activateRoute.queryParams.subscribe((val) => {
      if (this.activateRoute.snapshot.queryParams.q) {
        this.filter = this.activateRoute.snapshot.queryParams.q;
        if (this.filter)
          this.filterRef.nativeElement.value = this.filter.replace(
            /("[^"]+")|[,]+/g,
            (m: any, g1: any) => (g1 ? g1 : ' ')
          );
      } else if (this.activateRoute.snapshot.firstChild?.queryParams.q) {
        this.filter = this.activateRoute.snapshot.firstChild?.queryParams.q;
        if (this.filter)
          this.filterRef.nativeElement.value = this.filter.replace(
            /("[^"]+")|[,]+/g,
            (m: any, g1: any) => (g1 ? g1 : ' ')
          );
      }
    });
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
    this.nodes$ = this.manualTocService.docTocChanges;
  }

  /**
   * ngAfterViewChecked
   */
  ngAfterViewInit(): void {
    if (this.filter) {
      this.filterRef.nativeElement.value = this.filter.replace(
        /("[^"]+")|[,]+/g,
        (m: any, g1: any) => (g1 ? g1 : ' ')
      );
    }
  }

  /**
   * フィルタの入力で目次を絞り込む
   * @param event イベント
   */
  onClickFilter(event: any): void {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};
    urlTree.fragment = null;

    if (event.target.value) {
      this.filter = event.target.value
        .trim()
        .replace(/("[^"]+")|[\s]+/g, (m: any, g1: any) => (g1 ? g1 : ','));
    } else {
      this.filter = undefined;
    }

    this.router.navigate([urlTree.toString()], {
      queryParams: { q: this.filter },
    });
  }

  /**
   * サイドバーを閉じる
   */
  closeSidebar(): void {
    this._expanded = false;
    this.close.emit(false);
  }
}
