import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { ApiResponse } from '../shared/apiResponse.interface';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  /**
   * コンストラクタ
   * @param http
   * @param locale
   */
  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /** ページ情報を取得する */
  getPage(url: string): Observable<any> {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    return this.http
      .get<
        ApiResponse<{
          doc_type: string;
          doc_no: string;
          meta_title: string;
          meta_description: string;
          topic_path: string;
          content_type: string;
        }>
      >(
        `${environment.apiServer.baseUrl}/docs${url}` +
          (environment.apiServer.local ? '.json' : ''),
        { headers }
      )
      .pipe(
        map((response) => {
          return {
            breadcrumb: response.data.topic_path,
            contentType: response.data.content_type,
            title: response.data.meta_title,
            description: response.data.meta_description,
          };
        })
      );
  }
}
