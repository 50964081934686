import { Injectable } from '@angular/core';

export interface Notify {
  show: boolean;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private notify: Notify = {
    show: true,
    message: $localize`The website has been renewed. <a class="underline" href="./information/other/quick-guide">Learn more.</a>`,
  };

  constructor() {
    const value = localStorage.getItem('notify_status');
    if (value) {
      this.notify.show = this.notify.show && JSON.parse(value);
    }
  }

  getNotify(): Notify {
    return {
      show: this.notify.show,
      message: this.notify.message,
    };
  }

  setNotifyEnable(): Notify {
    localStorage.setItem('notify_status', JSON.stringify(true));
    this.notify.show = true;
    return {
      show: this.notify.show,
      message: this.notify.message,
    };
  }

  setNotifyDisable(): Notify {
    localStorage.setItem('notify_status', JSON.stringify(false));
    this.notify.show = false;
    return {
      show: this.notify.show,
      message: this.notify.message,
    };
  }
}
