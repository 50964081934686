import {
  Component,
  EventEmitter,
  Injector,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';

import { Dialog } from '@angular/cdk/dialog';

import { Bookmark, BookmarkService } from '../../shared/bookmark.service';

import { ConfimationDialogComponent } from '../../layout/confimation-dialog/confimation-dialog.component';

@Component({
  selector: 'app-search-bookmark',
  templateUrl: './search-bookmark.component.html',
  styleUrls: ['./search-bookmark.component.scss'],
})
export class SearchBookmarkComponent implements OnInit {
  /**
   * パネルのクローズを伝える
   */
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  public title = 'Bookmark';

  /**
   * タグの表示
   */
  public isShowTags = true;

  /**
   * リンクセレクト
   */
  public isFormatSelectOpen = false;

  /**
   * PDFリンク
   */
  @Input() isPDFLink = false;

  /**
   * モバイル幅の判定
   */
  public isMobile = false;

  /**
   * パネルの開閉状態
   */
  public isOpen = true;

  /**
   * ブックマーク
   */
  public bookmarks$?: Observable<Array<Bookmark>>;

  /**
   * ブックーマーク数
   */
  public bookmarkTotal = 0;

  /**
   * ロケールid
   */
  public locale!: string;

  constructor(
    private injector: Injector,
    private dialog: Dialog,
    private bookmarkService: BookmarkService
  ) {
    this.locale = this.injector.get(LOCALE_ID);

    this.bookmarks$ = this.bookmarkService.bookmarksChanges;

    this.bookmarks$.subscribe((bookmark) => {
      this.bookmarkTotal = bookmark.length;
    });
  }

  ngOnInit(): void {
    this.bookmarkService.refresh();
  }

  /**
   * タグ表示を切り替える
   */
  public onToggleShowTag(): void {
    this.isShowTags = !this.isShowTags;
  }

  /**
   * リンク条件セレクションを開く
   */
  public wideFormatButton(): void {
    this.isFormatSelectOpen = true;
  }

  /**
   * リンク条件セレクションを閉じる
   */
  public narrowFormatButton(): void {
    this.isFormatSelectOpen = false;
  }

  /**
   * リンクの条件を変更するボタンを押下する
   */
  public onClickFormatButton($event: any): void {
    this.isFormatSelectOpen = !this.isFormatSelectOpen;
    $event.stopPropagation();
  }

  /**
   * リンクの条件を変更する
   */
  public onClickSelectFormat($event: any): void {
    this.isPDFLink = !this.isPDFLink;

    localStorage.setItem('is_pdf_link', JSON.stringify(this.isPDFLink));
  }
  /**
   * パネルをクローズする
   */
  closePanel(): void {
    this.isOpen = false;
    this.close.emit(true);
  }

  public onClickAllClear(): void {
    const dialogRef = this.dialog.open<string>(ConfimationDialogComponent, {
      width: '250px',
      data: {
        title: 'Confirm',
        message: 'Are you sure want to delete all bookmarks from list?',
      },
      autoFocus: '__non_existing_element__',
    });

    dialogRef.closed.subscribe((result) => {
      if (result) {
        this.bookmarkService.clear();
      }
    });
  }
}
