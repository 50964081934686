<!-- Panel -->
<section
  class="fixed overflow-hidden"
  aria-labelledby="slide-over-title"
  role="dialog"
  aria-modal="true"
>
  <div class="absolute inset-0 overflow-hidden">
    <!-- Background overlay, show/hide based on slide-over state. -->
    <div class="absolute inset-0" aria-hidden="true"></div>

    <div class="top-11 md:top-36 fixed inset-y-0 right-0 pl-10 max-w-full flex">
      <div [@sideOverAnimation] class="max-w-full">
        <div
          class="
            h-full
            flex flex-col
            py-12
            md:py-0
            bg-white
            shadow-xl
            overflow-y-scroll
          "
        >
          <div class="px-4 sm:px-6">
            <div class="flex items-start justify-between">
              <h2
                class="text-lg font-medium text-gray-900"
                id="slide-over-title"
              ></h2>
              <div class="ml-0 h-7 flex">
                <button
                  class="bg-white rounded-md text-gray-400 hover:text-gray-500"
                  (click)="onClick($event)"
                >
                  <span class="sr-only">Close panel</span>
                  <app-x-outline-icon
                    svgClass="h-6 w-6"
                    stroke="2"
                  ></app-x-outline-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 relative px-4 sm:px-6">
            <!-- Replace with your content -->
            <div #panelContent class="flex flex-row">
              <ng-content></ng-content>
              <div class="flex items-center h-60">
                <!-- TODO: The 'vertical' property no longer exists -->
                <mat-slider
                  [max]="2.5"
                  [min]="1"
                  [step]="0.1"
                  aria-label="unit(s)"
                 #ngSlider><input matSliderThumb (input)="onSlide({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" /></mat-slider>
              </div>
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
