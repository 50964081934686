import { Component, OnInit } from '@angular/core';

export interface Node {
  title: string;
  url: string;
  children?: Node[];
}

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public node1Children: Node[] = [
    {
      title: `Link Title`,
      url: '',
    },
    {
      title: 'Link Title',
      url: '',
    },
    {
      title: 'Link Title',
      url: '',
    },
  ];

  public node2Children: Node[] = [
    {
      title: 'Link Title',
      url: '',
    },
    {
      title: 'Link Title',
      url: '',
    },
  ];

  public node3Children: Node[] = [
    {
      title: 'Link Title',
      url: '',
    },
    {
      title: 'Link Title',
      url: '',
    },
  ];

  public nodes: Node[] = [
    {
      title: 'Link Group',
      url: '',
      children: this.node1Children,
    },
    {
      title: 'Link Group',
      url: '',
      children: this.node2Children,
    },
    {
      title: 'Link Group',
      url: '',
      children: this.node3Children,
    },
  ];

  public show = false;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => (this.show = true), 1000);
  }
}
