import {
  Component,
  HostListener,
  Injector,
  LOCALE_ID,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { SearchNavDesktopComponent } from './search-nav-desktop/search-nav-desktop.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { ResultItemService } from './result-item.service';

export interface Facet {
  name: string;
  category: string;
}

import { SearchBookmarkComponent } from './search-bookmark/search-bookmark.component';

import { Notify, NotifyService } from '../shared/notify.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  /**
   * ロケールid
   */
  public locale!: string;

  /**
   * インプットキーワード
   */
  public keywords = '';

  /**
   * インプットモデル
   */
  public inputModel = '';

  /**
   * 選択されたファセット
   */
  public facets: Facet[] = new Array<Facet>();

  /**
   * 選択されたドキュメントタイプ
   */
  public docTypes: string[] = [];

  /**
   * 選択されたコンテンツタイプ
   */
  public contentTypes: string[] = [];

  /**
   *
   */
  public sortKey: string = '';

  /**
   *
   */
  public sortOrder: string = '';

  /**
   * 検索結果の表示開始インデックス
   */
  public from = 0;

  /**
   * 検索結果の表示数
   */
  public size = 10;

  /**
   * サイドバーが開いている状態であるか
   */
  public isSidebarOpen = false;

  /**
   * パネルが開いている状態であるか
   */
  public isPanelOpen = false;

  /**
   * 通知オブジェクト
   */
  public notify: Notify;

  /**
   * ガイド表示の状態
   */
  public showGuide = false;

  /**
   * 子コンポーネント
   */
  @ViewChild(SearchNavDesktopComponent)
  searchNavDesktopComp!: SearchNavDesktopComponent;

  /**
   * 子コンポーネント
   */
  @ViewChild(SearchResultComponent)
  searchResultComp!: SearchResultComponent;

  /**
   *
   */
  componentRef: any;

  /**
   * 情報パネル・コンテナ
   */
  @ViewChild('bookmarkPanelContainer', { read: ViewContainerRef })
  public bookmarkPanelContainer!: ViewContainerRef;

  /**
   *
   */
  public guideRect: any;

  /**
   * コンストラクタ
   * @param injector Injector
   * @param resultItemService ResultItemService
   * @param activateRoute ActivateRoute
   * @param router Router
   */
  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private title: Title,
    private notifyService: NotifyService,
    private resultItemService: ResultItemService
  ) {
    this.title.setTitle(`Manuals Search | Manuals & Technical Documents`);

    this.locale = this.injector.get(LOCALE_ID);

    this.notify = this.notifyService.getNotify();

    const value = localStorage.getItem('size_per_page');
    if (value) {
      this.size = JSON.parse(value);
    }

    this.resultItemService.setRange(this.from, this.size);

    activateRoute.queryParams.subscribe((val) => {
      this.keywords = this.activateRoute.snapshot.queryParams.q
        ? this.activateRoute.snapshot.queryParams.q.replace(
            /("[^"]+")|[,]+/g,
            (m: any, g1: any) => (g1 ? g1 : ' ')
          )
        : undefined;

      this.facets = [];
      const facetTypes = ['series', 'generation', 'component', 'model'];
      facetTypes.forEach((facetType: string) => {
        const facetsString = this.activateRoute.snapshot.queryParams[facetType];
        if (facetsString) {
          const facetStrings = facetsString.split(',');
          facetStrings.forEach((facet: any) => {
            this.facets.push({
              name: facet,
              category: facetType,
            });
          });
        }
      });

      this.docTypes = [];
      const doctypesString = this.activateRoute.snapshot.queryParams.doctype;
      if (doctypesString) {
        const doctypeStrings = doctypesString.split(',');
        doctypeStrings.forEach((docType: any) => {
          this.docTypes.push(docType);
        });
      }

      this.contentTypes = [];
      const contenttypesString =
        this.activateRoute.snapshot.queryParams.contenttype;
      if (contenttypesString) {
        const contenttypeStrings = contenttypesString.split(',');
        contenttypeStrings.forEach((contentType: any) => {
          this.contentTypes.push(contentType);
        });
      }

      const sortString = this.activateRoute.snapshot.queryParams.sort;
      if (sortString) {
        const sortStrings = sortString.split(',');
        this.sortKey = sortStrings[0];
        this.sortOrder = sortStrings[1];
      }

      this.inputModel = '';
      const modelString = this.activateRoute.snapshot.queryParams.input_model;
      if (modelString) {
        this.inputModel = modelString;
      }

      this.from = Number(this.activateRoute.snapshot.queryParams.from)
        ? Number(this.activateRoute.snapshot.queryParams.from)
        : 0;

      const value = localStorage.getItem('size_per_page');

      this.size = Number(this.activateRoute.snapshot.queryParams.size)
        ? Number(this.activateRoute.snapshot.queryParams.size)
        : value
        ? JSON.parse(value)
        : 10;

      localStorage.setItem('size_per_page', JSON.stringify(this.size));

      this.resultItemService.setRange(this.from, this.size);
      this.resultItemService.setKeywords(this.keywords);
      this.resultItemService.setTags(this.facets);
      this.resultItemService.setDocTypes(this.docTypes);
      this.resultItemService.setContentTypes(this.contentTypes);
      this.resultItemService.setModel(this.inputModel);
      this.resultItemService.setSort(this.sortKey, this.sortOrder);

      this.resultItemService.clearSuggest();

      this.resultItemService.search();
    });
  }

  /**
   * (AfterViewInit)
   */
  ngAfterViewInit() {
    this.guideRect = this.searchNavDesktopComp.guideRect;
  }

  /**
   * (HostListner) ウィンドウスクロールに対する動作をキャンセルする
   * @param $event any
   */
  @HostListener('window:scroll', ['$event'])
  onScroll($event: any): void {
    if (this.showGuide === true) {
      this.showGuide = false;
    }
    if (this.searchResultComp.isSortSelectOpen === true) {
      this.searchResultComp.isSortSelectOpen = false;
    }
    if (this.searchResultComp.isFormatSelectOpen === true) {
      this.searchResultComp.isFormatSelectOpen = false;
    }
  }

  /**
   * (HostListner) ドキュメントのクリックで行う処理
   */
  @HostListener('document:click')
  onClickout() {
    if (this.showGuide === true) {
      this.showGuide = false;
    }
    if (this.searchResultComp.isSortSelectOpen === true) {
      this.searchResultComp.isSortSelectOpen = false;
    }
    if (this.searchResultComp.isFormatSelectOpen === true) {
      this.searchResultComp.isFormatSelectOpen = false;
    }
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
    //this.resultItemService.search();
  }

  /**
   * スマートフォン用サイドバーを開く
   */
  public openSidebar(): void {
    this.isSidebarOpen = true;
  }

  /**
   * スマートフォン用サイドバーを閉じる
   */
  public closeSidebar(): void {
    this.isSidebarOpen = false;
  }

  /**
   * 通知表示を停止する
   */
  closeNotify(): void {
    this.notify = this.notifyService.setNotifyDisable();
  }

  /**
   * ガイドの表示をリクエストする
   * @param event
   */
  clickGuide(event: any): void {
    this.guideRect = this.searchNavDesktopComp.guideRect;

    if (event === true) {
      this.showGuide = true;
    } else {
      this.showGuide = false;
    }
  }

  /**
   * ブックマークパネルを開く
   */
  public openBookmarkPanel(value: any): void {
    if (!this.isPanelOpen && value) {
      this.componentRef = this.bookmarkPanelContainer.createComponent(
        SearchBookmarkComponent
      );

      this.isPanelOpen = true;

      this.componentRef.setInput('isPDFLink', this.searchResultComp.isPDFLink);
      this.componentRef.instance.close.subscribe(
        () => (this.isPanelOpen = false)
      );
    } else if (!value) {
      this.isPanelOpen = false;
      this.bookmarkPanelContainer.clear();
    }
  }
}
