import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BreadcrumbsComponent } from './layout/breadcrumbs/breadcrumbs.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { NavComponent } from './layout/nav/nav.component';
import { NavItemComponent } from './layout/nav/nav-item/nav-item.component';
import { NavPopoverComponent } from './layout/nav/nav-popover/nav-popover.component';
import { NavPopoverItemComponent } from './layout/nav/nav-popover-item/nav-popover-item.component';
import { NavSubMenuComponent } from './layout/nav/nav-sub-menu/nav-sub-menu.component';
import { NavSubMenuListComponent } from './layout/nav/nav-sub-menu-list/nav-sub-menu-list.component';
import { ToolbarModalComponent } from './layout/toolbar-modal/toolbar-modal.component';

import { AdobePdfOutlineIconComponent } from './icons/outline/AdobePdfOutlineIcon.component';
import { AdjustmentsHorizonOutlineIconComponent } from './icons/outline/AdjustmentsHorizonOutlineIcon.component';
import { BookmarkOutlineIconComponent } from './icons/outline/BookmarkOutlineIcon.component';
import { ChevronDownOutlineIconComponent } from './icons/outline/ChevronDownOutlineIcon.component';
import { ChevronLeftOutlineIconComponent } from './icons/outline/ChevronLeftOutlineIcon.component';
import { ChevronRightOutlineIconComponent } from './icons/outline/ChevronRightOutlineIcon.component';
import { CubeOutlineIconComponent } from './icons/outline/CubeOutlineIcon.component';
import { DocumentDownloadOutlineIconComponent } from './icons/outline/DocumentDownloadOutlineIcon.component';
import { DocumentTextOutlineIconComponent } from './icons/outline/DocumentTextOutlineIcon.component';
import { DotsVerticalOutlineIconComponent } from './icons/outline/DotsVerticalOutlineIcon.component';
import { ExternalLinkOutlineIconComponent } from './icons/outline/ExternalLinkOutlineIcon.component';
import { FilterOutlineIconComponent } from './icons/outline/FilterOutlineIcon.component';
import { InformationCircleOutlineIconComponent } from './icons/outline/InformationCircleOutlineIcon.component';
import { LineupChartOutlineIconComponent } from './icons/outline/LineupChartOutlineIcon.component';
import { MenuAlt2OutlineIconComponent } from './icons/outline/MenuAlt2OutlineIcon.component';
import { PrinterOutlineIconComponent } from './icons/outline/PrinterOutlineIcon.component';
import { QuestionMarkCircleOutlineIconComponent } from './icons/outline/QuestionMarkCircleOutlineIcon.component';
import { RssOutlineIconComponent } from './icons/outline/RssOutlineIcon.component';
import { ShareOutlineIconComponent } from './icons/outline/ShareOutlineIcon.component';
import { SpecificationsOutlineIconComponent } from './icons/outline/SpecificationsOutlineIcon.component';
import { TagOutlineIconComponent } from './icons/outline/TagOutlineIcon.component';
import { TechnicalInformationOutlineIconComponent } from './icons/outline/TechnicalInformationOutlineIcon.component';
import { WordDefinitionOutlineIconComponent } from './icons/outline/WordDefinitionOutlineIcon.component';
import { XOutlineIconComponent } from './icons/outline/XOutlineIcon.component';

import { ArrowNarrowRightSolidIconComponent } from './icons/solid/ArrowNarrowRightSolidIcon.component';
import { ArrowsUpDownSolidIconComponent } from './icons/solid/ArrowsUpDownSolidIcon.component';
import { BarsArrowDownSolidIconComponent } from './icons/solid/BarsArrowDownSolidIcon.component';
import { CalendarSolidIconComponent } from './icons/solid/CalendarSolidIcon.component';
import { CheckSolidIconComponent } from './icons/solid/CheckSolidIcon.component';
import { ChevronLeftSolidIconComponent } from './icons/solid/ChevronLeftSolidIcon.component';
import { ChevronRightSolidIconComponent } from './icons/solid/ChevronRightSolidIcon.component';
import { ChevronDownSolidIconComponent } from './icons/solid/ChevronDownSolidIcon.component';
import { FilterSolidIconComponent } from './icons/solid/FilterSolidIcon.component';
import { HomeSolidIconComponent } from './icons/solid/HomeSolidIcon.component';
import { LinkSolidIconComponent } from './icons/solid/LinkSolidIcon.component';
import { SearchSolidIconComponent } from './icons/solid/SearchSolidIcon.component';
import { SlashSolidIconComponent } from './icons/solid/SlashSolidIcon.component';
import { StarSolidIconComponent } from './icons/solid/StarSolidIcon.component';
import { TagSolidIconComponent } from './icons/solid/TagSolidIcon.component';

import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    FooterComponent,
    HeaderComponent,
    NavComponent,
    NavItemComponent,
    NavPopoverComponent,
    NavPopoverItemComponent,
    NavSubMenuComponent,
    NavSubMenuListComponent,
    ToolbarModalComponent,
    AdobePdfOutlineIconComponent,
    AdjustmentsHorizonOutlineIconComponent,
    BookmarkOutlineIconComponent,
    ChevronDownOutlineIconComponent,
    ChevronLeftOutlineIconComponent,
    ChevronRightOutlineIconComponent,
    CubeOutlineIconComponent,
    DocumentDownloadOutlineIconComponent,
    DocumentTextOutlineIconComponent,
    DotsVerticalOutlineIconComponent,
    ExternalLinkOutlineIconComponent,
    FilterOutlineIconComponent,
    InformationCircleOutlineIconComponent,
    LineupChartOutlineIconComponent,
    MenuAlt2OutlineIconComponent,
    PrinterOutlineIconComponent,
    QuestionMarkCircleOutlineIconComponent,
    RssOutlineIconComponent,
    ShareOutlineIconComponent,
    SpecificationsOutlineIconComponent,
    TagOutlineIconComponent,
    TechnicalInformationOutlineIconComponent,
    WordDefinitionOutlineIconComponent,
    XOutlineIconComponent,
    ArrowNarrowRightSolidIconComponent,
    ArrowsUpDownSolidIconComponent,
    BarsArrowDownSolidIconComponent,
    CalendarSolidIconComponent,
    CheckSolidIconComponent,
    ChevronLeftSolidIconComponent,
    ChevronRightSolidIconComponent,
    ChevronDownSolidIconComponent,
    FilterSolidIconComponent,
    HomeSolidIconComponent,
    LinkSolidIconComponent,
    SearchSolidIconComponent,
    SlashSolidIconComponent,
    SanitizeHtmlPipe,
    StarSolidIconComponent,
    TagSolidIconComponent,
  ],
  imports: [CommonModule, BrowserModule, BrowserAnimationsModule],
  exports: [
    BreadcrumbsComponent,
    FooterComponent,
    HeaderComponent,
    NavComponent,
    NavItemComponent,
    NavPopoverComponent,
    NavSubMenuComponent,
    NavSubMenuListComponent,
    ToolbarModalComponent,
    AdobePdfOutlineIconComponent,
    AdjustmentsHorizonOutlineIconComponent,
    BookmarkOutlineIconComponent,
    ChevronDownOutlineIconComponent,
    ChevronLeftOutlineIconComponent,
    ChevronRightOutlineIconComponent,
    CubeOutlineIconComponent,
    DocumentDownloadOutlineIconComponent,
    DocumentTextOutlineIconComponent,
    DotsVerticalOutlineIconComponent,
    ExternalLinkOutlineIconComponent,
    FilterOutlineIconComponent,
    InformationCircleOutlineIconComponent,
    LineupChartOutlineIconComponent,
    MenuAlt2OutlineIconComponent,
    PrinterOutlineIconComponent,
    QuestionMarkCircleOutlineIconComponent,
    RssOutlineIconComponent,
    ShareOutlineIconComponent,
    SpecificationsOutlineIconComponent,
    TagOutlineIconComponent,
    TechnicalInformationOutlineIconComponent,
    WordDefinitionOutlineIconComponent,
    XOutlineIconComponent,
    FilterSolidIconComponent,
    ArrowNarrowRightSolidIconComponent,
    ArrowsUpDownSolidIconComponent,
    BarsArrowDownSolidIconComponent,
    CalendarSolidIconComponent,
    CheckSolidIconComponent,
    ChevronLeftSolidIconComponent,
    ChevronRightSolidIconComponent,
    ChevronDownSolidIconComponent,
    LinkSolidIconComponent,
    FilterSolidIconComponent,
    HomeSolidIconComponent,
    SearchSolidIconComponent,
    StarSolidIconComponent,
    TagSolidIconComponent,
  ],
})
export class SharedModule {}
