<div
  class="fixed right-1 z-10 inline-block"
  [ngClass]="{
    'top-24': _notify,
    'top-12': !_notify
  }"
>
  <div>
    <button
      (click)="onClickOpen()"
      class="md:hidden bg-transparent p-1 m-1 text-gray-900 shadow-sm hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-bluemine-500"
    >
      <span class="sr-only">tools</span>
      <app-dots-vertical-outline-icon
        svgClass="h-6 w-6"
      ></app-dots-vertical-outline-icon>
    </button>
  </div>

  <!--
    Dropdown menu, show/hide based on menu state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  -->
  <div
    *ngIf="expanded"
    (click)="onClickClose()"
    class="origin-top-right absolute right-1 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div class="py-1" role="none">
      <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
      <div class="flex items-start justify-between">
        <div class="w-full"><ng-content></ng-content></div>
      </div>
    </div>
  </div>
</div>
