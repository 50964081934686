<li class="border border-l-0 border-r-0 border-t-0 border-gray-200">
  <div class="flex py-0.5 pl-1 pr-0">
    <!-- Expand/collapse button -->
    <button
      type="button"
      (click)="onClick()"
      class="inline-flex w-full items-center justify-between text-left text-gray-700 focus:border-transparent focus:outline-none"
      [attr.aria-controls]=""
      [attr.aria-expanded]="isOpen"
    >
      <span class="flex w-full">
        <span
          class="inline-flex items-center pr-2 text-sm font-semibold capitalize tracking-wide"
        >
          {{ tagSetName }}
        </span>
        <span class="inline-flex h-8 w-5 items-center">
          <span
            class="mr-1 inline-flex h-3 w-3 items-center justify-center rounded-full border border-gray-400"
            [ngClass]="{
              'series-color': tagSetName === 'series',
              'bg-blue-500': tagSetName === 'generation',
              'bg-blue-100': tagSetName === 'model',
              'bg-green-500': tagSetName === 'component'
            }"
          ></span>
        </span>
      </span>
      <span class="ml-1 flex h-7 items-center">
        <app-chevron-down-outline-icon
          size="18"
          class="transition duration-300 ease-in-out"
          [ngClass]="{
            'rotate-180 transform': isOpen,
            '': !isOpen
          }"
          svgClass="h-3 w-3"
        ></app-chevron-down-outline-icon>
      </span>
    </button>

    <!-- Clear button -->
    <button
      *ngIf="isAnySelected"
      type="button"
      (click)="onClickClear()"
      class="inline-flex w-6 items-center justify-between text-left text-gray-700 focus:border-transparent focus:outline-none"
      [attr.aria-controls]=""
      [attr.aria-expanded]="isOpen"
    >
      <span class="ml-1 flex h-7 items-center">
        <app-x-outline-icon
          size="20"
          class="transition duration-300 ease-in-out"
          svgClass="h-6 w-6"
        ></app-x-outline-icon>
      </span>
    </button>
  </div>

  <ul>
    <cdk-virtual-scroll-viewport
      #scrollViewport
      itemSize="20"
      (scrolledIndexChange)="onScrolledIndexChange($event, tagSetName)"
      [style.height.px]="isOpen ? viewportHeight : 0"
      class="transition-[height] duration-500 ease-in-out"
    >
      <li
        *cdkVirtualFor="let tag of isOpen ? _tags : []"
        class="flex h-7 items-center truncate px-1 py-1 text-sm text-gray-500"
      >
        <input
          id="viewSelect"
          name="viewSelect"
          type="checkbox"
          [disabled]="!tag.change && tag.change != null"
          [checked]="tag.selected"
          class="z-10 h-5 w-5 rounded-sm border-gray-200 text-bluemine-600 focus:rounded-none focus:ring-bluemine-500"
          [ngClass]="{
            'bg-gray-300': '!tag.change && tag.change != null',
          }"
          (click)="onClickFacet($event, tagSetName, tag.key)"
        />
        <span matTooltip="{{ tag.key }}" class="pl-1"> {{ tag.key }}</span>
      </li>
    </cdk-virtual-scroll-viewport>
  </ul>

  <div
    class="flex justify-center bg-gradient-to-b from-white to-gray-100"
    [ngClass]="{
      'pb-2': !(_tags.length > 4)
    }"
    (click)="onClickExpand()"
  >
    <button
      *ngIf="isOpen && _tags.length > 4"
      type="button"
      class="group inline-flex text-gray-500"
    >
      <app-chevron-down-outline-icon
        size="14"
        svgClass="text-gray-700 group-hover:text-gray-900"
        class="transition duration-700 ease-in-out"
        [ngClass]="{
          'rotate-180 transform': isExpanded,
          '': !isExpanded
        }"
      ></app-chevron-down-outline-icon>
    </button>
  </div>
</li>
