import { Component, OnInit, LOCALE_ID, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { fadeAnimation } from './animations';

import { CookieService } from 'ngx-cookie-service';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: environment.templatePath
    ? environment.templatePath
    : '../app/app.component.public.html',
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit {
  constructor(
    private injector: Injector,
    private router: Router,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    const locale = this.injector.get(LOCALE_ID);
    this.cookieService.delete('lang');
    this.cookieService.set('lang', locale, { expires: 10 * 365, path: '/' });
  }
}
