import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { ApiResponse } from '../shared/apiResponse.interface';

import { fetchAuthSession } from 'aws-amplify/auth';

export interface Document extends DocumentBasic {
  toc: NavigationNode[];
}

export interface DocumentBasic {
  title: string;
  description: string;
  description_logo: string;
  copyright: string;
  disclaimer: string;
  pdf_file_path: string;
  name: string;
  type: string;
  type_name?: string;
  category: string;
  publish_date: string;
  doc_no: string;
  models: any[];
  toc_array: string[];
}

export interface NavigationNode {
  title: string;
  url?: string;
  important?: boolean;
  children?: NavigationNode[];
  count?: number;
  last?: boolean;
}

export interface Manual {
  docType: string;
  docId: string;
}

const flatten = (tree: any) => {
  const result: any = [];
  tree.forEach((item: any) => {
    const { id, title, url, children } = item;
    if (children) result.push(...flatten(children));
    else {
      result.push({ id: id, title: title, url: url });
    }
  });

  return result;
};

@Injectable({
  providedIn: 'root',
})
export class ManualTocService {
  /**
   * ドキュメントid
   */
  private manual!: Manual;

  /**
   * ドキュメント目次 Subject
   */
  docTocSubject = new ReplaySubject<NavigationNode[]>(1);

  /**
   * ドキュメント基本情報 Subject
   */
  docBasicSubject = new ReplaySubject<DocumentBasic>(1);

  /**
   * valueChanges
   */
  get docTocChanges(): Observable<NavigationNode[]> {
    return this.docTocSubject;
  }

  /**
   * docBasicChanges
   */
  get docBasicChanges(): Observable<DocumentBasic> {
    return this.docBasicSubject;
  }

  /**
   * JWT Token
   */
  private token?: string;

  /**
   * コンストラクタ
   * @param http HttpClient
   */
  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /**
   * サービスの初期化処理を行う
   * @returns Promise<void>
   */
  async init(): Promise<void> {
    if (environment.apiServer.auth) {
      this.token = (await fetchAuthSession()).tokens?.idToken?.toString();
    }
  }

  /**
   * ドキュメント目次を取得する
   * @param manual Manual
   */
  getToc(manual: Manual): void {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.auth) {
      headers = headers.set('Authorization', `Bearer ${this.token}`);
    }

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    if (manual !== undefined) {
      this.manual = {
        docType: manual.docType,
        docId: manual.docId,
      };

      this.http
        .get<ApiResponse<Document>>(
          `${environment.apiServer.baseUrl}/man/manuals/${manual.docType}/${manual.docId}` +
            (environment.apiServer.local ? '.json' : ''),
          { headers }
        )
        .subscribe((response) => {
          switch (response.data.type) {
            case 'UM':
              response.data.type_name = "User's Manual";
              break;
            case 'DM':
              response.data.type_name = "Dealer's Manual";
              break;
          }

          if (
            response.data.toc.length &&
            typeof response.data.toc[response.data.toc.length - 1].children ===
              'undefined'
          ) {
            let index = 0;
            while (
              response.data.toc.length > index &&
              typeof response.data.toc[response.data.toc.length - 1 - index]
                .children === 'undefined'
            ) {
              response.data.toc[response.data.toc.length - 1 - index].last =
                true;
              index++;
            }
          }
          this.docTocSubject.next(response.data.toc);
          this.docBasicSubject.next({
            ...response.data,
            toc_array: flatten(response.data.toc),
          });
        });
    }
  }

  /**
   * キーワードで絞り込まれたドキュメント目次を取得する
   * @param manual Manual
   */
  getTocWidthKeywords(manual: Manual, keyword: string): void {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Accept-Language', this.locale);

    if (environment.apiServer.auth) {
      headers = headers.set('Authorization', `Bearer ${this.token}`);
    }

    if (environment.apiServer.xApiKey) {
      headers = headers.set('X-API-KEY', environment.apiServer.xApiKey);
    }

    let params = new HttpParams().set('keywords', keyword);

    if (manual !== undefined) {
      this.manual = {
        docType: manual.docType,
        docId: manual.docId,
      };

      this.http
        .get<ApiResponse<Document>>(
          `${environment.apiServer.baseUrl}/man/manuals/${manual.docType}/${manual.docId}` +
            (environment.apiServer.local ? '.json' : '') +
            '/filter',
          { headers, params }
        )
        .subscribe((response) => {
          switch (response.data.type) {
            case 'UM':
              response.data.type_name = "User's Manual";
              break;
            case 'DM':
              response.data.type_name = "Dealer's Manual";
              break;
          }

          this.docTocSubject.next(response.data.toc);
          this.docBasicSubject.next({
            ...response.data,
            toc_array: flatten(response.data.toc),
          });
        });
    }
  }
}
