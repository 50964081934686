<!-- Start search keyword -->
<div class="flex justify-center py-1">
  <div
    class="relative mx-1 mt-4 flex w-full flex-row items-center rounded-sm border border-gray-300 p-1 shadow-sm md:w-min md:px-1"
    action="docs/search"
  >
    <div
      class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-3 text-gray-400"
    >
      <app-search-solid-icon svgClass="h-6 w-6"></app-search-solid-icon>
    </div>
    <div
      class="relative ml-8 w-44 text-gray-400 focus-within:text-gray-700 md:w-52"
    >
      <input
        id="model_field"
        class="ronded-none h-full w-full border border-transparent py-3 pr-3 leading-6 text-gray-900 placeholder-gray-500 opacity-70 transition duration-500 ease-in-out focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-bluemine-500 sm:text-sm"
        placeholder="Model No. only"
        type="text"
        name="model"
        autocapitalize="none"
        i18n-placeholder
        [(ngModel)]="inputModel"
        [matAutocomplete]="auto"
        (keyup.enter)="onEnterModel()"
        (ngModelChange)="inputModelChange()"
      />
    </div>
    <div class="relative mx-1 h-6 border-r border-gray-300"></div>
    <div
      class="relative w-full text-gray-400 focus-within:text-gray-700 md:w-96"
    >
      <input
        #keywordRef
        id="search_field"
        class="h-full w-full border border-transparent py-3 pl-2 pr-3 text-gray-900 placeholder-gray-500 opacity-70 transition duration-500 ease-in-out focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-bluemine-500 sm:text-sm"
        placeholder="Full text search in manual"
        type="text"
        name="search"
        autocapitalize="none"
        i18n-placeholder
        [(ngModel)]="keywords"
        (keyup.enter)="search($event)"
        (ngModelChange)="keywordsChange()"
      />
    </div>
    <mat-autocomplete #auto="matAutocomplete" panelWidth="auto">
      <mat-option
        *ngFor="let tag of modelTagSets$ | async"
        (onSelectionChange)="addModelTag(tag.key)"
      >
        {{ tag.key }}
      </mat-option>
    </mat-autocomplete>
  </div>
</div>

<div class="flex items-center space-x-1 px-2 py-1">
  <!-- Filter -->
  <button
    class="flex cursor-pointer items-center border border-white px-1 py-1 text-xs font-normal tracking-wider hover:rounded-md hover:border-gray-200 hover:shadow-sm sm:text-sm xl:hidden"
    (click)="onClickSidebar()"
  >
    <app-adjustments-horizon-outline-icon svgClass="h-4 w-4">
    </app-adjustments-horizon-outline-icon>
    <span class="select-none px-0.5"> Filter </span>
  </button>

  <!-- Show Tag -->
  <button
    class="relative flex cursor-pointer items-center border border-transparent px-1 py-1 text-xs font-normal tracking-wider hover:rounded-md hover:border-gray-200 hover:shadow-sm sm:text-sm"
    (click)="onToggleShowTag()"
  >
    <app-tag-solid-icon svgClass="h-4 w-4 text-gray-500"></app-tag-solid-icon>
    <span class="select-none whitespace-nowrap">
      <span class="select-none" i18n>Tag</span>
    </span>
    <button
      type="button"
      class="group relative inline-flex h-5 w-8 flex-shrink-0 cursor-pointer items-center justify-center rounded-full border-bluemine-500 focus:outline-none"
      role="switch"
      [attr.aria-checked]="isShowTags"
    >
      <span class="sr-only">Tag</span>
      <span
        aria-hidden="true"
        class="pointer-events-none absolute h-full w-full rounded-md bg-white"
      ></span>
      <span
        aria-hidden="true"
        class="pointer-events-none absolute mx-auto h-3 w-7 rounded-full transition-colors duration-200 ease-in-out"
        [ngClass]="{
          'bg-bluemine-600': isShowTags,
          'bg-gray-200': !isShowTags
        }"
      ></span>
      <span
        aria-hidden="true"
        class="pointer-events-none absolute left-4 inline-block h-4 w-4 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
        [ngClass]="{
          'translate-x-0': isShowTags,
          '-translate-x-4': !isShowTags
        }"
      ></span>
    </button>
  </button>

  <!-- Sort -->
  <button
    class="relative flex cursor-pointer items-center border border-transparent px-1 py-1 text-xs font-normal tracking-wider sm:text-sm"
    (mouseover)="wideSortButton()"
    (mouseleave)="narrowSortButton()"
    (click)="onClickSortButton($event)"
  >
    <span class="flex h-5 items-center">
      <app-bars-arrow-down-solid-icon
        svgClass="h-4 w-4 text-gray-500"
      ></app-bars-arrow-down-solid-icon>
      <span class="select-none">
        <span class="select-none" i18n>Sort</span>
      </span>
      <app-chevron-down-outline-icon
        size="12"
        class="transition duration-300 ease-in-out"
        [ngClass]="{
          'rotate-180 transform': isSortSelectOpen,
          '': !isSortSelectOpen
        }"
      ></app-chevron-down-outline-icon>
    </span>

    <span
      *ngIf="isSortSelectOpen"
      class="absolute left-[-5px] top-[-1px] z-10 origin-top-right overflow-hidden rounded-md border border-gray-200 bg-white shadow-sm focus:outline-none"
    >
      <span class="flex items-center px-2 py-1">
        <span class="h-5"></span>
        <app-bars-arrow-down-solid-icon
          svgClass="h-4 w-4 text-gray-500"
        ></app-bars-arrow-down-solid-icon>
        <span class="select-none text-xs sm:text-sm" i18n>Sort</span>
        <app-chevron-down-outline-icon
          size="12"
          class="transition duration-300 ease-in-out"
          [ngClass]="{
            'rotate-180 transform': isSortSelectOpen,
            '': !isSortSelectOpen
          }"
        ></app-chevron-down-outline-icon>
      </span>
      <ul
        class="border-0 border-t"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
      >
        <li
          class="flex cursor-pointer select-none flex-col px-2.5 py-1.5 text-gray-900"
          id="listbox-option-0"
          role="option"
          (click)="onClickSelectSort($event, 'doc_type', 'asc')"
          [ngClass]="{
            'bg-gray-200 text-blue-700': sortKey === 'doc_type',
            'hover:bg-gray-100': sortKey !== 'doc_type'
          }"
        >
          <span class="flex whitespace-pre">
            <span class="select-none" i18n>Default</span>
            <app-check-solid-icon
              *ngIf="sortKey === 'doc_type'"
              svgClass="h-3.5 w-3.5 text-bluemine-500"
            ></app-check-solid-icon>
          </span>
        </li>

        <li
          class="flex cursor-pointer select-none flex-col px-2.5 py-1.5 text-gray-900"
          id="listbox-option-1"
          role="option"
          (click)="onClickSelectSort($event, 'publish_date', 'desc')"
          [ngClass]="{
            'bg-gray-200 text-blue-700': sortKey === 'publish_date',
            'hover:bg-gray-100': sortKey !== 'publish_date'
          }"
        >
          <span class="flex whitespace-pre">
            <span class="select-none" i18n>Update Date</span>
            <app-check-solid-icon
              *ngIf="sortKey === 'publish_date'"
              svgClass="h-3.5 w-3.5 text-bluemine-500"
            ></app-check-solid-icon>
          </span>
        </li>
      </ul>
    </span>
  </button>

  <button
    *ngIf="!isMobile"
    class="relative flex cursor-pointer items-center border border-transparent px-1 py-1 text-xs font-normal tracking-wider sm:text-sm"
    (mouseover)="wideFormatButton()"
    (mouseleave)="narrowFormatButton()"
    (click)="onClickFormatButton($event)"
  >
    <span class="flex h-5 items-center">
      <span class="select-none">Format</span>
      <app-chevron-down-outline-icon
        size="12"
        class="transition duration-300 ease-in-out"
        [ngClass]="{
          'rotate-180 transform': isFormatSelectOpen,
          '': !isFormatSelectOpen
        }"
      ></app-chevron-down-outline-icon>
    </span>

    <span
      *ngIf="isFormatSelectOpen"
      class="absolute left-[-5px] top-[-1px] z-10 origin-top-right overflow-hidden rounded-md border border-gray-200 bg-white shadow-sm focus:outline-none"
    >
      <span class="flex items-center px-2 py-1">
        <span class="h-5"></span>
        <span class="select-none" i18n>Format</span>
        <app-chevron-down-outline-icon
          size="12"
          class="transition duration-300 ease-in-out"
          [ngClass]="{
            'rotate-180 transform': isFormatSelectOpen,
            '': !isFormatSelectOpen
          }"
        ></app-chevron-down-outline-icon>
      </span>
      <ul
        class="border-0 border-t"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
      >
        <li
          class="flex cursor-pointer select-none flex-col px-2.5 py-1.5 text-gray-900"
          id="listbox-option-0"
          role="option"
          (click)="onClickSelectFormat($event)"
          [ngClass]="{
            'bg-gray-200 text-blue-700': !isPDFLink,
            'hover:bg-gray-100': isPDFLink
          }"
        >
          <span class="flex whitespace-pre">
            <span class="select-none" i18n>Web + PDF</span>
            <app-check-solid-icon
              *ngIf="!isPDFLink"
              svgClass="h-3.5 w-3.5 text-bluemine-500"
            ></app-check-solid-icon>
          </span>
        </li>

        <li
          class="flex cursor-pointer select-none flex-col px-2.5 py-1.5 text-gray-900"
          id="listbox-option-1"
          role="option"
          (click)="onClickSelectFormat($event)"
          [ngClass]="{
            'bg-gray-200 text-blue-700': isPDFLink,
            'hover:bg-gray-100': !isPDFLink
          }"
        >
          <span class="flex whitespace-pre">
            <span class="select-none" i18n>PDF only</span>
            <app-check-solid-icon
              *ngIf="isPDFLink"
              svgClass="h-3.5 w-3.5 text-bluemine-500"
            ></app-check-solid-icon>
          </span>
        </li>
      </ul>
    </span>
  </button>

  <button
    class="relative flex cursor-pointer items-center border border-transparent px-1 py-1 text-xs font-normal tracking-wider hover:rounded-md hover:border-gray-200 hover:shadow-sm sm:text-sm"
    (click)="onClickBookmark($event)"
  >
    <app-star-solid-icon svgClass="h-4 w-4 text-blue-900"></app-star-solid-icon>
    <span class="select-none" i18n> Bookmark </span>
  </button>
</div>
<!-- End search keyword -->

<div
  *ngIf="(activeTags$ | async)?.length > 0"
  class="flex flex-col items-center px-2 py-1 sm:px-2"
>
  <!-- selected tags / tag selector -->
  <div class="flex w-full flex-col">
    <!-- selected tags -->
    <div class="mx-auto flex w-full text-left">
      <div class="flex flex-wrap items-center">
        <span>
          <app-tag-solid-icon size="22"></app-tag-solid-icon>
        </span>
        <span
          *ngFor="let tag of activeTags$ | async"
          class="mx-1 my-1 inline-flex items-center rounded-full border border-gray-300 py-0.5 pl-2 pr-1 text-sm font-normal uppercase tracking-wider"
        >
          <div
            class="mr-0.5 inline-flex h-3 w-3 flex-shrink-0 items-center justify-center rounded-full border border-gray-300"
            [ngClass]="{
              'series-color': tag.category === 'series',
              'bg-blue-500': tag.category === 'generation',
              'bg-blue-100': tag.category === 'model',
              'bg-green-500': tag.category === 'component'
            }"
          ></div>
          {{ tag.name }}
          <button
            (click)="removeTag(tag)"
            type="button"
            class="ml-0.5 inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full text-bluemine-400 hover:bg-bluemine-200 hover:text-bluemine-500 focus:bg-bluemine-500 focus:text-white focus:outline-none"
          >
            <span class="sr-only">Remove large option</span>
            <app-x-outline-icon svgClass="h-3 w-3"></app-x-outline-icon>
          </button>
        </span>
        <span class="pl-1 text-gray-500 hover:text-blue-500">
          <a
            (click)="onClickAllClear()"
            href="javascript:void(0)"
            class="text-sm"
          >
            Clear All
          </a>
        </span>
      </div>
    </div>
  </div>
</div>

<!-- Start search result -->
<div class="relative flex-1 px-1">
  <!-- Start top pagination -->
  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="hitTotal$ | async"
    [pageIndex]="divide()"
    [pageSize]="size"
    [pageSizeOptions]="pageSizeOptions"
    aria-label="paginator"
  >
  </mat-paginator>
  <!-- End top pagination -->

  <p *ngIf="!(hits$ | async)?.length" class="my-8 text-center text-base" i18n>
    No results found
  </p>

  <ul class="grid grid-cols-1 gap-y-1">
    <app-search-result-item
      *ngFor="let hit of hits$ | async; index as i"
      [hit]="hit"
      [keywords]="keywords"
      [isShowTags]="isShowTags"
      [isPDFLink]="isPDFLink"
    ></app-search-result-item>
  </ul>

  <!-- Start bottom pagination -->
  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="hitTotal$ | async"
    [pageSize]="size"
    [pageIndex]="divide()"
  >
  </mat-paginator>
  <!-- End bottom pagination -->
</div>
<!-- End search result -->
