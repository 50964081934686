<!-- Start header -->
<div id="top-of-page"></div>
<lib-nav (openSidebar)="openSidebar()"> </lib-nav>
<!-- End header -->

<div class="relative pt-6 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
  <div class="relative mx-auto">
    <h1 class="text-3xl text-bluemine-500 pb-4">Dealer's Manual</h1>
    <div class="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
      <div
        *ngFor="let node of nodes"
        class="flex flex-col rounded-md shadow-md border overflow-hidden"
      >
        <div class="flex-1 bg-white py-2 flex flex-col justify-between">
          <div class="flex-1">
            <p class="text font-medium border-b px-6 py-4 text-bluemine-500">
              {{ node.title }}
            </p>
            <a
              *ngFor="let nodeChildren of node.children"
              href="#"
              class="block mt-2"
            >
              <p
                class="text font-normal px-6 py-1 text-blue-500 hover:text-bluemine-500"
              >
                <a class="">
                  {{ nodeChildren.title }}
                </a>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
