import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

import { environment } from '../../../environments/environment';

import { PmiService } from './pmi.service';
import { Notify, NotifyService } from '../../shared/notify.service';

@Component({
  selector: 'app-compliance-pmi',
  templateUrl: './compliance-pmi.component.html',
  styleUrls: ['./compliance-pmi.component.scss'],
})
export class CompliancePmiComponent {
  /**
   * フィルタ要素に対する参照 (ViewChild)
   */
  @ViewChild('filterRef') filterRef!: ElementRef;

  /**
   * PMI (Observable)
   */
  public pmi$!: Observable<any>;

  /**
   * 要求されたフィルタのキーワードをセットする (query parameter)
   */
  public filter!: string;

  /**
   * 通知オブジェクト
   */
  public notify: Notify;

  /**
   * サイドバーが開いている状態であるか
   */
  public isSidebarOpen = false;

  /**
   * 凡例の表示状態
   */
  public showGuide = false;

  /**
   * ガイドエリア
   */
  public guideRect: any = {};

  /**
   * 凡例要素に対する参照 (ViewChild)
   */
  @ViewChild('guideRef') guideRef!: ElementRef;

  constructor(
    private injector: Injector,
    private title: Title,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    @Inject('SCROLL_OFFSET_MOBILE') private scrollOffsetMobile: number,
    @Inject('SCROLL_OFFSET_DESKTOP') private scrollOffsetDesktop: number,
    private pmiService: PmiService,
    private notifyService: NotifyService
  ) {
    const locale = this.injector.get(LOCALE_ID);
    if (locale !== 'it') {
      this.router.navigateByUrl('/NotFound', { skipLocationChange: true });
    }

    this.notify = this.notifyService.getNotify();
    this.title.setTitle(`Compliance | Package Material Information`);

    this.pmi$ = this.pmiService.valueChanges;
  }

  /**
   * (AfterViewInit)
   */
  ngAfterViewInit(): void {
    if (this.filter) {
      this.filterRef.nativeElement.value = this.filter.replace(/,/g, ' ');
    }
    setTimeout(() => {
      this.guideRect = this.guideRef.nativeElement.getBoundingClientRect();
    }, 300);
  }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((val) => {
      this.filter = this.activateRoute.snapshot.queryParams.q;
      this.setViewportScrollerOffset();

      if (this.filter) {
        this.pmiService.getPmiWithKeyword(this.filter);
      } else {
        this.pmiService.clear();
      }
    });
  }

  /**
   * ウィンドウのリサイズでViewportScrollerのオフセットを変更する
   */
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setViewportScrollerOffset();
    this.guideRect = this.guideRef.nativeElement.getBoundingClientRect();
  }

  /**
   * (HostListner) ウィンドウスクロールに対する動作をキャンセルする
   * @param $event any
   */
  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.guideRect = this.guideRef.nativeElement.getBoundingClientRect();
  }

  /**
   * (HostListner) ドキュメントのクリックで行う処理
   */
  @HostListener('document:click')
  onClickout() {
    if (this.showGuide === true) {
      this.showGuide = false;
    }
  }

  /**
   * ViewportScroller の オフセット を変更する
   */
  private setViewportScrollerOffset(): void {
    const viewportWidth = this.width;
    if (viewportWidth <= 760) {
      if (!this.notify.show) {
        this.viewportScroller.setOffset([0, this.scrollOffsetMobile]);
      } else {
        this.viewportScroller.setOffset([0, this.scrollOffsetMobile + 40]);
      }
    } else {
      if (!this.notify.show) {
        this.viewportScroller.setOffset([0, this.scrollOffsetDesktop]);
      } else {
        this.viewportScroller.setOffset([0, this.scrollOffsetDesktop + 40]);
      }
    }
  }

  /**
   * フィルタの入力でエラーセットを絞り込む
   * @param event イベント
   */
  onClickFilter(event: any): void {
    this.filterRef.nativeElement.blur();

    if (event.target.value) {
      this.router.navigate(['compliance-search/pmi'], {
        queryParams: { q: event.target.value.replace(/\s/g, ',') },
      });
    } else {
      this.router.navigate(['compliance-search/pmi']);
    }
  }

  /**
   * スマートフォン用サイドバーを開く
   */
  public openSidebar(): void {
    this.isSidebarOpen = true;
  }

  /**
   * スマートフォン用サイドバーを閉じる
   */
  public closeSidebar(): void {
    this.isSidebarOpen = false;
  }

  /**
   * 通知表示を停止する
   */
  closeNotify(): void {
    this.notify = this.notifyService.setNotifyDisable();
  }

  /**
   * ガイドの表示をリクエストする
   * @param $event
   */
  onClickGuide($event: any): void {
    this.showGuide = true;
    $event.stopPropagation();
  }

  public get width() {
    return window.innerWidth;
  }
}
