import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-base-solid-icon',
  template: '.',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      svg {
        height: inherit;
        width: inherit;
        color: inherit;
      }
    `,
  ],
})
export class BaseSolidIconComponent implements OnInit, OnChanges {
  @Input() size?: string;
  @Input() color = '';
  @Input() class = '';
  @Input() svgClass = '';
  @Input() svgStyle = '';

  public style = '';

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const colorHasChanged =
      changes.color?.previousValue !== changes.color?.currentValue;
    const sizeHasChanged =
      changes.size?.previousValue !== changes.size?.currentValue;

    if (colorHasChanged || sizeHasChanged) {
      this.style = '';
      this.renderStyle();
    }
  }

  ngOnInit(): void {
    this.renderStyle();
  }

  renderStyle(): void {
    const style: string[] = [];
    if (this.size) {
      style.push(`width: ${this.size}px; height: ${this.size}px;`);
    }
    if (this.color) {
      style.push(`color: ${this.color};`);
    }

    this.style = this.svgStyle + style.join(' ');
  }
}
