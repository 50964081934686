<div id="top-of-page"></div>
<lib-nav
  [sidebar]="false"
  [notify]="notify"
  [beneath_header]="false"
  (closeNotify)="closeNotify()"
>
</lib-nav>

<!-- Start Mobile Sidebar -->
<app-search-nav-mobile [expanded]="isSidebarOpen" (close)="closeSidebar()">
</app-search-nav-mobile>
<!-- End Mobile Sidebar -->

<!-- Start Contents -->
<div class="flex justify-center">
  <div class="relative flex min-h-screen w-full max-w-screen-xl flex-col">
    <div class="flex w-auto justify-center">
      <!-- Start left sidebar for desktop -->
      <app-search-nav-desktop
        (clickGuide)="clickGuide($event)"
      ></app-search-nav-desktop>
      <!-- End left sidebar for desktop -->

      <!-- Start search area -->
      <section class="w-full lg:w-5/6 xl:w-full">
        <app-search-result
          (openSidebar)="openSidebar()"
          (openBookmark)="openBookmarkPanel($event)"
        ></app-search-result>
      </section>
      <!-- End search area -->
    </div>
  </div>
  <ng-container #bookmarkPanelContainer></ng-container>
</div>
<!-- End Contents -->

<app-search-guide
  *ngIf="showGuide"
  [left]="guideRect.left"
  [top]="guideRect.top"
></app-search-guide>

<lib-footer></lib-footer>
