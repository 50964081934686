<div
  *ngIf="node.children"
  class=""
  [ngClass]="{
    'ml-3': level > 1
  }"
>
  <button
    *ngIf="node.url == null"
    type="button"
    (click)="onClickButton()"
    class="flex w-full flex-nowrap items-center px-2 py-1 text-sm hover:text-blue-900"
    [attr.aria-pressed]="isExpanded"
  >
    <span class="inline-block w-5 align-middle">
      <app-chevron-right-outline-icon
        class="block transition duration-300 ease-in-out"
        [ngClass]="{
          'rotate-90 transform': isExpanded,
          '': !isExpanded
        }"
        svgClass="h-4 w-4"
      ></app-chevron-right-outline-icon>
    </span>
    <span
      class="inline-block break-all text-left align-middle text-gray-700 antialiased hover:text-blue-900"
    >
      <p [innerHTML]="node.title | sanitizeHtml"></p>
    </span>
  </button>

  <div *ngIf="isExpanded" [attr.aria-pressed]="isExpanded">
    <app-manual-toc-item
      *ngFor="let node of nodeChildren"
      [level]="level + 1"
      [isParentExpanded]="isExpanded"
      [manual]="manual"
      [pageId]="pageId"
      [filter]="filter"
      [node]="node"
      [selectedNodes]="selectedNodes"
      (close)="emitClose()"
    ></app-manual-toc-item>
  </div>
</div>
<div
  *ngIf="!node.children"
  class="text-sm"
  [ngClass]="{
    'ml-3': level > 1,
    'bg-red-50': node.important === true
  }"
>
  <a
    *ngIf="node.url != null"
    routerLink="/{{ manual.docType }}/{{ manual.docId }}/{{ node.url }}"
    [queryParams]="{ q: filter }"
    (click)="onClickAnker()"
    class="flex flex-nowrap px-2 py-1"
  >
    <span class="inline-block w-5 flex-shrink-0"> </span>
    <span
      class="inline-block break-words px-1 text-left align-middle antialiased hover:text-blue-900 hover:underline"
      [ngClass]="{
        'bg-gray-50 font-medium text-blue-700': node.url === pageId,
        'text-gray-700': node.url !== pageId
      }"
    >
      <p [innerHTML]="node.title | sanitizeHtml"></p>
    </span>
  </a>
</div>
