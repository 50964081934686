import {
  Component,
  HostListener,
  OnInit,
  Injector,
  Input,
  LOCALE_ID,
} from '@angular/core';

import { Dialog } from '@angular/cdk/dialog';

import { Bookmark, BookmarkService } from '../../shared/bookmark.service';

import { ConfimationDialogComponent } from '../../layout/confimation-dialog/confimation-dialog.component';

@Component({
  selector: 'app-search-bookmark-item',
  templateUrl: './search-bookmark-item.component.html',
  styleUrls: ['./search-bookmark-item.component.scss'],
})
export class SearchBookmarkItemComponent implements OnInit {
  /**
   * 検索結果アイテム
   */
  @Input() bookmark!: Bookmark;

  /**
   * タグの表示
   */
  @Input() isShowTags = true;

  /**
   * リンクの設定
   */
  @Input() isPDFLink = false;

  /**
   * モバイル幅の判定
   */
  public isMobile = false;

  /**
   * エラーコードセットの数が５つ以上の時に目次をさらに表示する
   */
  public more = false;

  /**
   * ロケールid
   */
  public locale!: string;

  /**
   * show more 文字列
   */
  public show_more = $localize`show more`;

  /**
   * show less 文字列
   */
  public show_less = $localize`show less`;

  /**
   *
   */
  public isBookmarked = false;

  /**
   *
   */
  public stroke = 1;

  public publicLocales: { [key: string]: string } = {
    en: 'English',
    ja: '日本語',
    'zh-CN': '简体中文',
    'zh-TW': '繁體中文',
    fr: 'français',
    es: 'españoles',
    de: 'Deutsch',
    it: 'Italiano',
    cs: 'Česky',
    sk: 'Slovensky',
    hu: 'Magyar',
    ru: 'Русский',
    nl: 'Nederlands',
    sv: 'svenska',
    fi: 'suomi',
    no: 'Norsk',
    pl: 'Polski',
    da: 'dansk',
    lt: 'Lietuvių kalba',
    lv: 'Latviski',
    et: 'Eesti',
    el: 'Ελληνικά',
    sl: 'Slovenščina',
    ro: 'Română',
    bg: 'Български',
    tr: 'Türkçe',
    sr: 'Српски',
    hr: 'Hrvatski',
    pt: 'Português',
    ko: '한국어',
    th: 'ไทย',
    id: 'Bahasa Indonesia',
  };

  constructor(
    private injector: Injector,
    private dialog: Dialog,
    private bookmarkService: BookmarkService
  ) {
    this.locale = this.injector.get(LOCALE_ID);
  }

  ngOnInit(): void {
    const viewportWidth = this.width;
    if (viewportWidth > 760) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }

    this.isBookmarked = true;
  }

  /**
   * ウィンドウのリサイズでViewportScrollerのオフセットを変更する
   */
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const viewportWidth = this.width;
    if (viewportWidth > 760) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  /**
   *
   * @param event
   */
  onClickBookmark(event: any, item: Bookmark) {
    if (this.isBookmarked) {
      const dialogRef = this.dialog.open<string>(ConfimationDialogComponent, {
        width: '250px',
        data: {
          title: 'Confirm',
          message: 'Are you sure want to delete the bookmark from list?',
        },
        autoFocus: '__non_existing_element__',
      });

      dialogRef.closed.subscribe((result) => {
        if (result) {
          this.bookmarkService.remove(item.locale, item.doc_no, item.doc_type);
        }
      });
    } else {
      var now = new Date();
      this.bookmarkService.add({
        locale: this.locale,
        doc_type: item.doc_type.toUpperCase(),
        doc_no: item.doc_no,
        content_type: item.content_type,
        bookmarked_date: now.toISOString().split('T')[0],
        pdf_path: item.pdf_path,
        name: item.name,
      });
    }
  }

  /**
   * PDFの表示でPageViewをカウントする
   * @param locale
   * @param docType
   * @param docNo
   * @param pdfPath
   */
  sendPageView(
    locale: string,
    docType: string,
    docNo: string,
    pdfPath: string
  ): void {}

  /**
   * ドキュメントタイプの背景色の設定
   * @param docType
   * @returns
   */
  setDocTypeColor(docType: string): string {
    let color = '';
    switch (docType) {
      case 'DM':
        color = '#bd362f';
        break;
      case 'UM':
        color = '#51a351';
        break;
      case 'SI':
        color = '#005686';
        break;
      case 'EV':
        color = '#FD8C34';
        break;
      case 'QM':
        color = '#4aacc5';
        break;
      case 'SM':
        color = '#E6B8B7';
        break;
    }
    return color;
  }
  public get width() {
    return window.innerWidth;
  }
}
