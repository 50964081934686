import { Component } from '@angular/core';

import { BaseOutlineIconComponent } from '../base-outline-icon.component';

@Component({
  selector: 'app-lineup-chart-outline-icon',
  template: `<svg
    [attr.style]="style"
    [attr.class]="svgClass"
    fill="none"
    viewBox="-851 683 113 113"
    stroke="currentColor"
  >
    <defs>
      <style>
        .cls-lc-1 {
          clip-path: url(#clip-lc);
        }

        .cls-lc-2 {
          fill: #3db8ed;
        }

        .cls-lc-3,
        .cls-lc-5,
        .cls-lc-7 {
          fill: none;
        }

        .cls-lc-3 {
          stroke: #3db8ed;
        }

        .cls-lc-4 {
          opacity: 0.8;
        }

        .cls-lc-5 {
          stroke: #fff;
        }

        .cls-lc-6 {
          stroke: none;
        }

        .cls-lc-8 {
          fill: #fff;
          fill-opacity: 0;
        }
      </style>
      <clipPath id="clip-lc">
        <rect x="-851" y="683" width="113" height="113" />
      </clipPath>
    </defs>
    <g class="cls-lc-1">
      <rect class="cls-lc-8" x="-851" y="683" width="113" height="113" />
      <g transform="translate(-1409 333)">
        <ellipse
          class="cls-lc-2"
          cx="48"
          cy="47.874"
          rx="48"
          ry="47.874"
          transform="translate(567 359)"
        />
        <g class="cls-lc-3" transform="translate(562 354)">
          <circle class="cls-6" cx="53" cy="53" r="53" />
          <circle class="cls-7" cx="53" cy="53" r="52.5" />
        </g>
        <g class="cls-lc-4">
          <g class="cls-lc-5" transform="translate(586 402)">
            <rect class="cls-6" width="15.069" height="10.959" />
            <rect
              class="cls-lc-7"
              x="0.5"
              y="0.5"
              width="14.069"
              height="9.959"
            />
          </g>
          <line
            class="cls-lc-5"
            x2="19.589"
            transform="translate(600.815 407.85)"
          />
          <g class="cls-lc-5" transform="translate(619.651 386)">
            <rect class="cls-6" width="15.069" height="10.959" />
            <rect
              class="cls-lc-7"
              x="0.5"
              y="0.5"
              width="14.069"
              height="9.959"
            />
          </g>
          <g class="cls-lc-5" transform="translate(619.651 402)">
            <rect class="cls-lc-6" width="15.069" height="10.959" />
            <rect
              class="cls-lc-7"
              x="0.5"
              y="0.5"
              width="14.069"
              height="9.959"
            />
          </g>
          <g class="cls-lc-5" transform="translate(619.651 419.151)">
            <rect class="cls-lc-6" width="15.069" height="10.959" />
            <rect
              class="cls-lc-7"
              x="0.5"
              y="0.5"
              width="14.069"
              height="9.959"
            />
          </g>
          <line
            class="cls-lc-5"
            y2="34"
            transform="translate(611.363 390.837)"
          />
          <line
            class="cls-lc-5"
            x2="9.041"
            transform="translate(611.363 391.274)"
          />
          <line
            class="cls-lc-5"
            x2="9.041"
            transform="translate(611.363 424.425)"
          />
        </g>
      </g>
    </g>
  </svg>`,
})
export class LineupChartOutlineIconComponent extends BaseOutlineIconComponent {}
