<button
  (click)="onClick(bikeType)"
  type="button"
  class="relative inline-flex items-center px-4 border-gray-300 uppercase tracking-wider text-sm font-normal focus:outline-none focus:ring-0 transition duration-300 ease-in-out"
  [ngClass]="{
    'bg-bluemine-500 text-white bg-opacity-100': isSelected,
    'bg-white text-gray-500 hover:bg-gray-50 bg-opacity-0': !isSelected
  }"
>
  {{ bikeType.name }}
</button>
